import React, { RefObject } from 'react';
import { Box, Button, ButtonProps, Typography } from '@mui/material';
import { colors } from '../../theme/theme';
import {
  ButtonIconPositionEnum,
  ButtonTypeEnum,
} from '../../enums/button-type-enum';

interface CustomButtonProps extends ButtonProps {
  children: string;
  buttonWidth?: string | number;
  icon?: React.ReactNode;
  variantType?: ButtonTypeEnum;
  iconPosition?: ButtonIconPositionEnum;
  btnRef?: RefObject<HTMLButtonElement>;
  fontSize?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  buttonWidth,
  icon,
  variantType = ButtonTypeEnum.PRIMARY,
  iconPosition = ButtonIconPositionEnum.RIGHT,
  btnRef = null,
  fontSize = 'inherit',
  sx,
  ...props
}) => {
  const buttonStyles = {
    [ButtonTypeEnum.PRIMARY]: {
      backgroundColor: 'button.primary',
      color: 'button.primaryText',
      '&.Mui-disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
        color: 'button.primaryText',
      },
      '&:hover': {
        backgroundColor: 'button.primaryHover',
      },
    },
    [ButtonTypeEnum.SECONDARY]: {
      backgroundColor: 'transparent',
      color: 'text.primary',
      border: '1px solid',
      borderColor: 'background.darker',
      '&.Mui-disabled': {
        color: 'background.darker',
        borderColor: 'background.darker',
      },
      '&.MuiButton-root:hover': {
        backgroundColor: 'background.lightDark',
        borderColor: 'background.lightDark',
      },
    },
    [ButtonTypeEnum.TERTIARY]: {
      backgroundColor: 'transparent',
      color: 'primary.main',
      borderStyle: `solid`,
      borderWidth: '1px',
      borderColor: 'background.darker',
      '&.Mui-disabled': {
        color: 'background.darker',
        borderColor: 'background.darker',
      },
    },
    [ButtonTypeEnum.MENU]: {
      backgroundColor: 'transparent',
      color: 'text.primary',
      height: 'auto',
      width: 'auto',
      minWidth: 'unset',
      '&.Mui-disabled': {},
    },
  };

  const iconBoxStyles = {
    [ButtonTypeEnum.PRIMARY]: {
      backgroundColor: 'button.primaryIconBox',
      color: 'button.primaryText',
      '&.Mui-disabled': {},
    },
    [ButtonTypeEnum.SECONDARY]: {
      backgroundColor: 'transparent',
      borderRight:
        iconPosition === ButtonIconPositionEnum.LEFT ? '1px solid' : 'none',
      borderLeft:
        iconPosition === ButtonIconPositionEnum.RIGHT ? '1px solid' : 'none',
      borderColor: 'background.darker',
      '&.Mui-disabled': {},
    },
    [ButtonTypeEnum.TERTIARY]: {
      backgroundColor: 'transparent',
      color: colors.bgDarkOrange,
      border: `1px solid ${colors.bgDark}`,
      '&.Mui-disabled': {},
    },
    [ButtonTypeEnum.MENU]: {},
  };

  const textStylesVariants = {
    [ButtonTypeEnum.PRIMARY]: 'badge2',
    [ButtonTypeEnum.SECONDARY]: 'badge2',
    [ButtonTypeEnum.TERTIARY]: 'badge2',
    [ButtonTypeEnum.MENU]: 'body3',
  };

  const additionalSettings = {
    [ButtonTypeEnum.PRIMARY]: {},
    [ButtonTypeEnum.SECONDARY]: {},
    [ButtonTypeEnum.TERTIARY]: {},
    [ButtonTypeEnum.MENU]: {
      color: 'text.primary',
      fontWeight: 700,
      textTransform: 'none',
    },
  };

  return (
    <Button
      fullWidth
      ref={btnRef}
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection:
          iconPosition === ButtonIconPositionEnum.RIGHT ? 'row' : 'row-reverse',
        alignItems: 'center',
        borderRadius: '1px',
        padding: '0',
        height: '50px',
        width: props.fullWidth ? 1 : buttonWidth || 'max-content',
        ...buttonStyles[variantType],
        ...sx,
      }}
      {...props}
    >
      <Typography
        sx={{
          flexGrow: 1,
          textWrap: 'nowrap',
          textAlign: 'center',
          fontSize: `${fontSize} !important`,
          ...additionalSettings[variantType],
        }}
        typography={textStylesVariants[variantType]}
      >
        {children}
      </Typography>
      {icon && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50px',
            height: '100%',
            borderRadius:
              iconPosition === ButtonIconPositionEnum.LEFT
                ? '1px 0 0 1px'
                : iconPosition === ButtonIconPositionEnum.RIGHT
                  ? '0 1px 1px 0'
                  : '0',
            ...iconBoxStyles[variantType],
          }}
        >
          {icon}
        </Box>
      )}
    </Button>
  );
};

export default CustomButton;
