import {
  Box,
  CircularProgress,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedTextFieldProps,
  SxProps,
  TextField,
  Typography,
} from '@mui/material';
import React, { ReactNode, useMemo } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import ArrowDownIcon from '../Icons/ArrowDownIcon';

interface SelectItems {
  value: string | number;
  label: string;
}

interface InputProps extends OutlinedTextFieldProps {
  sxBox?: SxProps;
  caption: string;
  register?: UseFormRegisterReturn;
  error?: boolean;
  helperText?: string;
  containerFlex?: string | number;
  height?: string | number;
  width?: string | number;
  loading?: boolean;
  selectItems?: SelectItems[];
  children?: ReactNode | ReactNode[] | null;
  onSelectFieldChange?: (value: unknown) => void;
}

const Input = ({
  sxBox,
  caption,
  register,
  error,
  helperText,
  containerFlex,
  height,
  width,
  loading,
  onSelectFieldChange,
  selectItems = [],
  children = null,
  ...textFieldProps
}: InputProps) => {
  const childArray = useMemo(
    () => React.Children.toArray(children),
    [children]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="12px"
      width={width}
      flex={containerFlex}
      sx={sxBox}
    >
      <Typography
        variant="body4"
        sx={{
          padding: '0 2px',
          opacity: textFieldProps.disabled ? 0.4 : 1,
        }}
      >
        {caption}
      </Typography>

      {/* PLAIN INPUT */}
      {!textFieldProps.select && (
        <TextField
          {...textFieldProps}
          {...register}
          error={error}
          helperText={helperText}
        />
      )}

      {/* SELECT INPUT */}
      {textFieldProps.select && (
        <>
          <FormControl>
            <TextField
              {...textFieldProps}
              {...register}
              error={error}
              helperText={helperText}
              defaultValue={''}
              inputProps={{
                style: {
                  height: '50px',
                  padding: '20px 16px',
                },
              }}
              InputProps={{
                sx: {
                  p: '6px',
                  '& .MuiSelect-select': {
                    height: `${height || 50}px !important`,
                    display: 'flex',
                    alignItems: 'center',
                  },
                },
              }}
              SelectProps={{
                onChange: event => {
                  const value = event.target?.value;

                  if (typeof value === 'string' || typeof value === 'number') {
                    const selecteditem =
                      selectItems.find(item => item.value === value)?.label ||
                      childArray[value as number];
                    onSelectFieldChange?.(selecteditem);
                  }
                },
                displayEmpty: true,
                renderValue: (value: unknown) => {
                  if (loading) {
                    return (
                      <InputAdornment position="start">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    );
                  }
                  if (typeof value === 'string' || typeof value === 'number') {
                    // Return a string representation of the selected value
                    return (
                      selectItems.find(item => item.value === value)?.label ||
                      childArray[value as number] ||
                      textFieldProps.placeholder ||
                      ''
                    );
                  }
                  return textFieldProps.placeholder || ''; // Return the placeholder or an empty string
                },

                IconComponent: props => (
                  <ArrowDownIcon
                    {...props}
                    sx={{
                      mr: '10px',
                      backgroundColor: 'transparent !important', // Ensure no background color
                    }}
                  />
                ),
              }}
            >
              {!children &&
                selectItems?.map(item => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    sx={{ fontSize: '14px', fontWeight: 400 }}
                  >
                    {item.label}
                  </MenuItem>
                ))}

              {childArray.length > 0 &&
                childArray.map((item, index) => (
                  <MenuItem key={index} value={index}>
                    {item}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>
        </>
      )}
    </Box>
  );
};

export default Input;
