import { Box, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import Input from '../../../../components/MUIComponents/Input';
import { LiveAgentProfileSettingsFormInputs } from '../../../../interfaces/profile.interface';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface LiveAgentProfileSettingsFormProps {
  control: Control<LiveAgentProfileSettingsFormInputs, any>;
  register: UseFormRegister<LiveAgentProfileSettingsFormInputs>;
  errors: FieldErrors<LiveAgentProfileSettingsFormInputs>;
  setValue: UseFormSetValue<LiveAgentProfileSettingsFormInputs>;
  watch: UseFormWatch<LiveAgentProfileSettingsFormInputs>;
}

const LiveAgentProfileSettingsForm: FC<LiveAgentProfileSettingsFormProps> = ({
  register,
  errors,
}) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      mt="48px"
      display="flex"
      flexDirection="column"
      gap={mobileView ? '28px' : '36px'}
    >
      <Box
        display="flex"
        flexDirection={mobileView ? 'column' : 'row'}
        gap={mobileView ? '28px' : '22px'}
      >
        <Input
          caption="First Name:"
          containerFlex="1"
          variant="outlined"
          fullWidth
          placeholder="First Name"
          register={register('firstName')}
          error={!!errors.firstName}
          helperText={errors.firstName ? errors.firstName.message : ''}
        />
        <Input
          caption="Last Name:"
          containerFlex="1"
          variant="outlined"
          fullWidth
          placeholder="Last Name"
          register={register('lastName')}
          error={!!errors.lastName}
          helperText={errors.lastName ? errors.lastName.message : ''}
        />
      </Box>
      <Box display="flex">
        <Input
          caption="Bio:"
          containerFlex="1"
          variant="outlined"
          fullWidth
          multiline
          rows={5}
          margin="none"
          placeholder="Few words about yourself"
          register={register('bio')}
          error={!!errors.bio}
          helperText={errors.bio ? errors.bio.message : ''}
          inputProps={{ p: 0 }}
        />
      </Box>
    </Box>
  );
};

export default LiveAgentProfileSettingsForm;
