import React from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { ISport } from '../../../../../interfaces/sport.interface';
import { errorHelper } from '../../../../../utils/helper/error-helper';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import FilterLayout from '../../../../../layouts/FilterLayout';
import SkeletonList from '../../../../../components/MUIComponents/SkeletonList';

interface ICandidateSportFilterProps {
  sports: ISport[] | undefined;
  isLoading: boolean;
  isError: boolean;
  sportsFilterState: string;
  setSportsFilterState: (value: string) => void;
  label: string;
  error: FetchBaseQueryError | SerializedError | undefined;
  onShowMore: () => void;
  totalSportsCount: number;
}

const CandidateSportFilter = (props: ICandidateSportFilterProps) => {
  const {
    sports,
    isLoading,
    isError,
    sportsFilterState,
    setSportsFilterState,
    label,
    error,
    onShowMore,
    totalSportsCount,
  } = props;

  const handleSportChange = (sport: string) => {
    const formattedSport = sport.replace(/\s/g, '_');
    const updatedState = sportsFilterState.includes(formattedSport)
      ? sportsFilterState
          .split(',')
          .filter(s => s !== formattedSport)
          .join(',')
      : [...sportsFilterState.split(','), formattedSport].join(',');

    setSportsFilterState(updatedState);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          p: '22px 16px 11px 16px',
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <SkeletonList />
      </Box>
    );
  }

  if (isError) {
    return (
      <Typography
        sx={{
          p: '22px 16px 11px 16px',
        }}
        color="error"
        variant="h4"
      >
        {errorHelper(error)}
      </Typography>
    );
  }

  return (
    <FilterLayout name={label}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.375rem',
          padding: '0 1rem 1.375rem',
        }}
      >
        <FormGroup>
          {sports?.map(sport => (
            <FormControlLabel
              key={sport.id}
              control={
                <Checkbox
                  checked={sportsFilterState.includes(
                    sport.name.replace(/\s/g, '_')
                  )}
                  onChange={() => handleSportChange(sport.name)}
                />
              }
              label={
                <Typography variant="h4" component="h4" color={'text.primary'}>
                  {sport.name}
                </Typography>
              }
            />
          ))}
        </FormGroup>
        {sports && sports.length < totalSportsCount && (
          <Typography
            onClick={onShowMore}
            variant="h4"
            component="h4"
            color={'text.accent'}
            sx={{ marginTop: '10px', cursor: 'pointer' }}
          >
            + Show more
          </Typography>
        )}
      </Box>
    </FilterLayout>
  );
};

export default CandidateSportFilter;
