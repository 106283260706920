import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import { IUserFileDB } from '../../../../interfaces/file.interface';
import UploadDocumentImageFileItem from '../UploadDocumentImageFileItem/UploadDocumentImageFileItem';

interface IProps {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  uploadedFiles: IUserFileDB[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<IUserFileDB[]>>;
  isLoading: boolean;
  handleDelete: (file: File, uploadedFile?: IUserFileDB) => Promise<void>;
  uploadProgress: number[];
}

const UploadDocumentFileList: React.FC<IProps> = ({
  files,
  setFiles,
  uploadedFiles,
  setUploadedFiles,
  isLoading,
  handleDelete,
  uploadProgress,
}) => {
  const renderFilePreview = (file: File, index: number) => {
    const uploadedFile = files.find(
      uploadedFile => uploadedFile.name === file.name
    );

    return (
      <UploadDocumentImageFileItem
        key={index}
        file={file}
        setFiles={setFiles}
        uploadedFile={uploadedFile}
        setUploadedFiles={setUploadedFiles}
        isLoading={isLoading}
        isUploaded={!!uploadedFile}
        handleDelete={handleDelete}
      />
    );
  };

  return (
    <Box display="flex" flexDirection={'column'} gap="8px">
      {files.map((file, index) => (
        <Box
          key={index}
          width="100%"
          display="flex"
          flexDirection="column"
          border={`1px solid`}
          borderColor={'background.dark'}
          borderRadius="1px"
          height={'78px'}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            width="100%"
            height={'100%'}
            padding={'0 22px'}
            alignItems="center"
            alignContent={'center'}
            justifyContent={'center'}
          >
            {renderFilePreview(file, index)}{' '}
          </Box>
          {uploadProgress[index] !== 0 && (
            <LinearProgress
              variant="determinate"
              value={uploadProgress[index]}
              sx={{
                width: '100%',
                backgroundColor: 'button.main',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: 'button.main',
                },
              }}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default UploadDocumentFileList;
