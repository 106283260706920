import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Box,
  debounce,
  FormGroup,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { ILeague, ITeam } from '../../../../../interfaces/career.interface';
import SearchIcon from '@mui/icons-material/Search';
import LeagueTeamItemLabel from './LeagueTeamItemLabel';

interface LeagueTeamFilterProps {
  isActive?: boolean;
  leagues?: ILeague[];
  leagueFilterState?: string;
  setLeagueFilterState?: (value: string) => void;
  teams?: ITeam[];
  teamFilterState?: string;
  setTeamFilterState?: (value: string) => void;
}

const ITEMS_PER_PAGE = 5;

const LeagueTeamFilter: FC<LeagueTeamFilterProps> = ({
  leagues,
  leagueFilterState,
  setTeamFilterState,
  teams,
  teamFilterState,
  setLeagueFilterState,
  isActive = false,
}) => {
  const [search, setSearch] = useState<string>('');
  const [visibleItems, setVisibleItems] = useState<number>(ITEMS_PER_PAGE);

  const handleItemChange = useCallback((item: string, allItems: string) => {
    const formattedItem = item.replace(/\s/g, '_');
    const itemsArray = allItems.split(',').filter(Boolean);

    if (itemsArray.includes(formattedItem)) {
      return itemsArray.filter(s => s !== formattedItem).join(',');
    }

    return [...itemsArray, formattedItem].join(',');
  }, []);

  const filteredLeagues = useMemo(
    () =>
      leagues?.filter(league =>
        league.strLeague.toLowerCase().includes(search.toLowerCase())
      ) ?? [],
    [leagues, search]
  );

  const paginatedLeagues = useMemo(
    () => filteredLeagues.slice(0, visibleItems),
    [filteredLeagues, visibleItems]
  );

  const filteredTeams = useMemo(
    () =>
      teams?.filter(team =>
        team.strTeam.toLowerCase().includes(search.toLowerCase())
      ) ?? [],
    [teams, search]
  );

  const paginatedTeams = useMemo(
    () => filteredTeams.slice(0, visibleItems),
    [filteredTeams, visibleItems]
  );

  const handleShowMore = () => {
    setVisibleItems(prev => prev + ITEMS_PER_PAGE);
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => setSearch(value), 300),
    []
  );

  if (!isActive) {
    return (
      <Box
        sx={{
          padding: '0 1rem 1.375rem',
        }}
      >
        <Typography variant={'body3'}>
          {leagues && `Select a Sport First`}
          {teams && `Select a League First`}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.375rem',
        padding: '0 1rem 1.375rem',
      }}
    >
      <TextField
        variant="outlined"
        placeholder="Search..."
        onChange={e => handleSearchChange(e.target.value.trim())}
        sx={searchFieldStyles}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ p: 0 }}>
              <SearchIcon sx={iconSizes} />
            </InputAdornment>
          ),
        }}
      />

      <FormGroup>
        {setLeagueFilterState &&
          paginatedLeagues?.map(league => (
            <LeagueTeamItemLabel
              key={league.idLeague}
              name={league.strLeague}
              isChecked={
                leagueFilterState?.includes(
                  league.strLeague.replace(/\s/g, '_')
                ) ?? false
              }
              onChange={() =>
                setLeagueFilterState(
                  handleItemChange(league.strLeague, leagueFilterState ?? '')
                )
              }
            />
          ))}
        {setTeamFilterState &&
          paginatedTeams?.map(team => (
            <LeagueTeamItemLabel
              key={team.idTeam}
              name={team.strTeam}
              isChecked={
                teamFilterState?.includes(team.strTeam.replace(/\s/g, '_')) ??
                false
              }
              onChange={() =>
                setTeamFilterState(
                  handleItemChange(team.strTeam, teamFilterState ?? '')
                )
              }
            />
          ))}
      </FormGroup>
      {(visibleItems < filteredLeagues.length ||
        visibleItems < filteredTeams.length) && (
        <Typography
          variant="h4"
          onClick={handleShowMore}
          sx={{
            color: 'text.accent',
            cursor: 'pointer',
          }}
        >
          + Show more
        </Typography>
      )}
    </Box>
  );
};

export default LeagueTeamFilter;

const searchFieldStyles = {
  width: '100%',
  maxWidth: {
    md: '16rem',
    lg: '20.125rem',
  },
  '& .MuiInputBase-root': {
    height: '3.125rem',
    padding: '0.75rem 1rem',
  },
  '& .MuiOutlinedInput-input': {
    height: '3.125rem !important',
    padding: 0,
  },
  '& .MuiSvgIcon-root': {
    backgroundColor: 'transparent',
  },
};

const iconSizes = {
  width: '1.25rem',
  height: '1.25rem',
};
