import React from 'react';
import BaseModal from '../../../../../components/MUIComponents/BaseModal';
import WelcomeToPlatform from '../../../../../components/Auth/VerifyAccount/WelcomeToPlatform/WelcomeToPlatform';
import AttentionIcon from '../../../../../components/Icons/AttentionIcon';

interface EndAssistantCompleteModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

const EndAssistantCompleteModal = ({
  onClose,
  isOpen = false,
}: EndAssistantCompleteModalProps) => {
  return (
    <BaseModal isOpen={isOpen} toggle={onClose} disableDefaultFooter={true}>
      <WelcomeToPlatform
        title={
          <>
            Your career development plan <br /> has been stopped
          </>
        }
        text={
          <>
            Don’t worry. You can start a new career
            <br /> development plan at any time.
          </>
        }
        textLastLine={''}
        buttonText="got it"
        showEscapeIcon={false}
        onProceed={onClose}
        onClose={onClose}
        sx={{ padding: 0, bgcolor: 'none' }}
        icon={<AttentionIcon />}
      />
    </BaseModal>
  );
};

export default EndAssistantCompleteModal;
