import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import { getCurrentUserId } from '../../../utils/helper/getCurrentUserId';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';

export interface IUser {
  [id: string]: string;

  firstName: string;
  lastName: string;
  thumbnail: string;
}

export interface IChat {
  id: string;
  userId: string;
  withUserId: string;
  name: string;
  type: string;
  externalId: string;
  createdAt: Date;
  updatedAt: Date;
  isNewMessage: boolean;
  lastMessageFrom: string | null;
  lastMessageText: string | null;
  user: IUser;
  withUser: IUser;
}

export interface IMessage {
  id: string;
  attributes: string | JSON;
  to: string;
  createdAt: Date;
  updatedAt: Date;
  from: string;
  body: string;
  index: number;
  type: string;
  media: Array<IMediaFile>;
  url: string;
}

export interface IMediaFile {
  contentType: string;
  filename: string;
  file: Blob;
  size?: number;
}

export interface IGetAllMessagesResponse {
  messages: IMessage[];
  nextPage: string;
  prevPage: string;
}

const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: baseQueryWithInterceptor(config.BASE_CHAT_MICROSERVICE_API_URL),
  tagTypes: ['chats', 'token', 'messages'],

  endpoints: builder => ({
    getAllMyChats: builder.query<
      IChat[],
      { page?: number; limit?: number } | void
    >({
      query: (args = { page: 1, limit: 100 }) => {
        const userId = getCurrentUserId();
        const { page, limit } = args || {};
        return `/user/${userId}?page=${page}&limit=${limit}`;
      },
      transformResponse: (response: IChat[]) => {
        const userId = getCurrentUserId();
        response.forEach(chat => {
          if (chat.withUserId === userId) {
            const copy = { ...chat.withUser };
            chat.withUser = { ...chat.user };
            chat.user = copy;

            const idCopy = chat.withUserId;
            chat.withUserId = chat.userId;
            chat.userId = idCopy;
          }
        });
        return response;
      },
      providesTags: result => {
        if (!result) return [{ type: 'chats', id: 'LIST' }];
        return [
          ...result.map(chat => ({ type: 'chats' as const, id: chat.id })),
          { type: 'chats', id: 'LIST' },
        ];
      },
    }),

    getChatById: builder.query<IChat, { chatId?: string }>({
      query: ({ chatId }) => {
        return `/${chatId}`;
      },
      transformResponse: (response: IChat) => {
        const userId = getCurrentUserId();
        if (response.withUserId === userId) {
          const copy = { ...response.withUser };
          response.withUser = { ...response.user };
          response.user = copy;

          const idCopy = response.withUserId;
          response.withUserId = response.userId;
          response.userId = idCopy;
        }
        return response;
      },
    }),

    createChat: builder.mutation<IChat, string>({
      query: withUserId => {
        const userId = getCurrentUserId();
        return { url: '/', method: 'POST', body: { userId, withUserId } };
      },
      invalidatesTags: [{ type: 'chats', id: 'LIST' }],
    }),

    deleteChat: builder.mutation<void, string>({
      query: chatId => {
        return { url: `/${chatId}`, method: 'DELETE' };
      },
      invalidatesTags: [{ type: 'chats', id: 'LIST' }],
    }),

    updateChatMessage: builder.mutation<
      IMessage,
      { chatId: string; messageId: string; attributes: string }
    >({
      query: ({ chatId, messageId, attributes }) => {
        return {
          url: `${chatId}/messages/${messageId}`,
          method: 'PUT',
          body: { attributes },
        };
      },

      onQueryStarted: async (
        { messageId, chatId, attributes },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          chatApi.util.updateQueryData(
            'getAllChatMessages',
            { chatId },
            draft => {
              const message = draft.messages.find(msg => msg.id === messageId)!;
              message.attributes = JSON.stringify({
                ...(typeof message.attributes === 'string'
                  ? JSON.parse(message.attributes as string)
                  : message.attributes),
                ...JSON.parse(attributes),
              });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    setLastChatMessageRead: builder.mutation<void, string>({
      query: chatId => {
        return {
          url: `/${chatId}/last-message-read`,
          method: 'POST',
          body: { lastMessageRead: true },
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: 'chats', id: arg }],
    }),

    getAllChatMessages: builder.query<
      IGetAllMessagesResponse,
      { chatId: string; page?: string; limit?: number }
    >({
      query: ({ chatId, page = '', limit = 10 }) => {
        return `/${chatId}/messages?page=${page}&limit=${limit?.toString()}`;
      },
      serializeQueryArgs: ({ endpointName, queryArgs: { chatId } }) => {
        return `${endpointName}-${chatId}`;
      },
      forceRefetch({ previousArg, currentArg }) {
        return (
          previousArg?.page !== currentArg?.page || currentArg?.page === ''
        );
      },
      merge: (currentCache, newItems, args) => {
        if (args.arg.page === '') {
          currentCache.messages = newItems.messages;
        } else {
          currentCache.messages.push(...newItems.messages);
        }
        currentCache.nextPage = newItems.nextPage;
        currentCache.prevPage = newItems.prevPage;
      },
      providesTags: (result, error, arg) => [
        { type: 'messages', id: arg.chatId },
      ],
    }),

    sendMessage: builder.mutation<
      IMessage,
      { chatId: string; formData: FormData }
    >({
      query: ({ chatId, formData }) => {
        return {
          url: `${chatId}/messages`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'messages', id: arg.chatId },
        { type: 'chats', id: arg.chatId },
      ],
    }),

    getChatToken: builder.query<string, { chatId: string; userId: string }>({
      query: ({ chatId, userId }) => {
        return {
          url: `/${chatId}/token?tokenUser=${userId}`,
          responseHandler: (response: { text: () => any }) => response.text(),
        };
      },

      providesTags: (result, error, arg) => [{ type: 'token', id: arg.chatId }],
    }),

    deleteChatToken: builder.mutation<void, { chatId: string; userId: string }>(
      {
        query: ({ chatId, userId }) => {
          return {
            url: `/${chatId}/token?tokenUser=${userId}`,
            method: 'DELETE',
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'token', id: arg.chatId },
        ],
      }
    ),

    sendMessageWithRepost: builder.mutation<
      IMessage,
      {
        postId: string;
        toUserId: string;
        fromUserId: string;
        description: string;
      }
    >({
      query: ({ postId, toUserId, fromUserId, description }) => {
        const attributes = JSON.stringify({ type: 'post', postId });
        const body = { attributes, from: fromUserId, body: description };
        return {
          url: `/messages/${toUserId}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['messages', 'chats'],
    }),
  }),
});

export const {
  useGetAllMyChatsQuery,
  useGetChatByIdQuery,
  useCreateChatMutation,
  useLazyGetChatTokenQuery,
  useGetAllChatMessagesQuery,
  useSendMessageMutation,
  useLazyGetAllChatMessagesQuery,
  useDeleteChatTokenMutation,
  useGetChatTokenQuery,
  useDeleteChatMutation,
  useSendMessageWithRepostMutation,
  useSetLastChatMessageReadMutation,
  useUpdateChatMessageMutation,
} = chatApi;

export default chatApi;
