import React, { FC, useState } from 'react';
import { Box, Collapse, Stack, Typography } from '@mui/material';
import ArrowDownIcon from '../components/Icons/ArrowDownIcon';
import { useThemeContext } from '../theme/ThemeContextProvider';

interface FilterLayoutProps {
  name: string;
  children: React.ReactNode;
}

const FilterLayout: FC<FilterLayoutProps> = ({ name, children }) => {
  const { theme } = useThemeContext();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const chevronStyles = {
    width: '1.125rem',
    height: '1.125rem',
    transform: isExpanded ? 'rotate(180deg)' : '',
  };

  return (
    <Stack borderBottom={`1px solid ${theme.palette.background.lightDark}`}>
      <Box
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1.375rem 1rem',
          cursor: 'pointer',
        }}
      >
        <Typography variant="badge2" sx={{ textTransform: 'uppercase' }}>
          {name}
        </Typography>

        <ArrowDownIcon sx={chevronStyles} />
      </Box>

      <Collapse in={isExpanded}>{children}</Collapse>
    </Stack>
  );
};

export default FilterLayout;
