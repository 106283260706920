import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useFieldArray, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '../../../components/Icons/ArrowLeftIcon';
import CustomButton from '../../../components/MUIComponents/CustomButton';
import Input from '../../../components/MUIComponents/Input';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import { setCareerDevelopmentSchema } from '../../../validation/career-development-validation';
import { useState } from 'react';
import SnackbarCustom from '../../../components/MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { errorHelper } from '../../../utils/helper/error-helper';
import DeleteFileRedIcon from '../../../components/Icons/DeleteFileRedIcon';
import { useCreateIndividualStageMutation } from '../../../store/apiSlice/individualStageApi';
import { getOrdinalMilestone } from '../../../utils/helper/milestone-text-helper';

interface Milestone {
  stepTitle: string;
  objective: string;
  outcome: string;
}
interface CareerDevelopmentFormInputs {
  milestones: Milestone[];
}

const emptyMilestone = {
  stepTitle: '',
  objective: '',
  outcome: '',
};

const SetCareerDevelopmentMapPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const [isLoading, setIsloading] = useState<boolean>(false);

  const [createIndividualStage] = useCreateIndividualStageMutation();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CareerDevelopmentFormInputs>({
    criteriaMode: 'all',
    defaultValues: {
      milestones: [emptyMilestone],
    },
    resolver: yupResolver(setCareerDevelopmentSchema, { abortEarly: false }),
    mode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'milestones',
  });

  const onSubmit = async (data: CareerDevelopmentFormInputs) => {
    if (!state?.userInfo) {
      return;
    }

    try {
      setIsloading(true);
      let itemWithError = null;

      const entries = Array.from(data.milestones.entries());

      for (let i = 0; i < entries.length; i++) {
        const [index, m] = entries[i];

        try {
          const res = await createIndividualStage({
            userId: state?.userInfo.id,
            title: m.stepTitle,
            objective: m.objective,
            outcome: m.outcome,
          });

          if (res.error) {
            itemWithError = res;
            break;
          }
        } catch (error) {
          itemWithError = error;
          break;
        }
      }

      if (itemWithError) {
        throw new Error(
          (itemWithError as any)?.error?.data?.message ??
            'Oops! Something went wrong.'
        );
      }

      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarMessage('Career development map successfully created.');
      setSnackbarOpen(true);
      setTimeout(() => navigate(-1), 2000);
    } catch (error) {
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarMessage(errorHelper(error));
      setSnackbarOpen(true);
      console.error('Failed to create career development map:', error);
    } finally {
      setIsloading(false);
    }
  };

  const handleBackBtn = () => {
    return navigate(-1);
  };

  const addMilestone = () => append(emptyMilestone);

  const removeMilestone = (fieldIndex: number) => () => remove(fieldIndex);

  return (
    <Container
      disableGutters
      className="set-career-development-map-page__container"
      maxWidth={false}
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <Box bgcolor="background.white" width={1} maxWidth={872}>
        <Box
          component="header"
          p="22px 36px"
          sx={theme => ({
            borderBottom: `1px solid ${theme.palette.background.lightDark}`,
          })}
        >
          <Link
            to="#"
            onClick={handleBackBtn}
            style={{ color: '#000', textDecoration: 'none' }}
          >
            <Stack direction="row" alignItems="center" gap="16px">
              <ArrowLeftIcon sx={{ width: 17, height: 12 }} />
              <Typography variant="body1">
                Set career development map
              </Typography>
            </Stack>
          </Link>
        </Box>

        {!state?.userInfo && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width={1}
            height={500}
          >
            <Typography variant="h2">No data. Bad request.</Typography>
          </Box>
        )}

        {state?.userInfo && (
          <>
            <Box
              component="main"
              p="36px"
              display="flex"
              flexDirection="column"
              rowGap="36px"
            >
              {fields.map((field, fieldIndex) => (
                <Box
                  key={field.id}
                  className={'milestone-' + (fieldIndex ?? '')}
                  display="flex"
                  flexDirection="column"
                  gap="36px"
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h2">
                      {getOrdinalMilestone(fieldIndex + 1)}
                    </Typography>
                    {fields.length > 1 && (
                      <IconButton onClick={removeMilestone(fieldIndex)}>
                        <DeleteFileRedIcon />
                      </IconButton>
                    )}
                  </Stack>
                  <Grid
                    container
                    gap="36px"
                    flexDirection="column"
                    sx={{ '& .MuiGrid-item': { gap: '12px' } }}
                  >
                    <Grid item>
                      <Input
                        caption="Step title:"
                        variant="outlined"
                        fullWidth
                        placeholder="Create a title for this milestone."
                        defaultValue={''}
                        register={register(
                          `milestones.${fieldIndex}.stepTitle`
                        )}
                        error={!!errors.milestones?.[fieldIndex]?.stepTitle}
                        helperText={
                          errors.milestones?.[fieldIndex]?.stepTitle?.message ??
                          ''
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        caption="Objective:"
                        variant="outlined"
                        fullWidth
                        placeholder="Describe the reason for this milestone."
                        defaultValue={''}
                        register={register(
                          `milestones.${fieldIndex}.objective`
                        )}
                        error={!!errors.milestones?.[fieldIndex]?.objective}
                        helperText={
                          errors.milestones?.[fieldIndex]?.objective?.message ??
                          ''
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Input
                        caption="Outcome:"
                        variant="outlined"
                        fullWidth
                        placeholder="Describe the end goal of completing this milestone."
                        defaultValue={''}
                        register={register(`milestones.${fieldIndex}.outcome`)}
                        error={!!errors.milestones?.[fieldIndex]?.outcome}
                        helperText={
                          errors.milestones?.[fieldIndex]?.outcome?.message ??
                          ''
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <CustomButton
                variantType={ButtonTypeEnum.SECONDARY}
                fullWidth
                onClick={addMilestone}
              >
                Add one more milestone
              </CustomButton>
            </Box>

            <Box
              component="footer"
              p="36px"
              sx={theme => ({
                borderTop: `1px solid ${theme.palette.background.lightDark}`,
              })}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  '& .MuiButtonBase-root': {
                    p: '19px 16px',
                  },
                }}
              >
                <CustomButton
                  disabled={isLoading}
                  variantType={ButtonTypeEnum.PRIMARY}
                  onClick={handleSubmit(onSubmit)}
                >
                  apply the career map
                </CustomButton>
                <CustomButton variantType={ButtonTypeEnum.SECONDARY}>
                  cancel
                </CustomButton>
              </Stack>
            </Box>
          </>
        )}
      </Box>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Container>
  );
};

export default SetCareerDevelopmentMapPage;
