import {
  Avatar,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import LikeIcon from '../../../../components/Icons/LikeIcon';
import RepostIcon from '../../../../components/Icons/RepostIcon';
import SendIcon from '../../../../components/Icons/SendIcon';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import QuiltedImageList from '../../../../components/MUIComponents/QuiltedImageList';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { ICommentResponse } from '../../../../interfaces/comment.interface';
import { IPost } from '../../../../interfaces/post.interface';
import { IUser } from '../../../../interfaces/user.interface';
import { PostType } from '../posts/NewPublicationTrigger';
import { MyPostModals, PostButtons } from '../posts/ProfilePost';
import ProfilePostComments from '../posts/ProfilePostComments';
import RepostedPost from '../posts/RepostedPost';
import { selectAuthData } from '../../../../store/selectors/authSelector';
import { colors } from '../../../../theme/theme';
import PostHeader from '../posts/PostHeader';
import PostBodyText from '../posts/PostBodyText';
import PostData from '../posts/PostData';

interface PostCommentsModalProps {
  selectedPostType: PostType;
  isOpen?: boolean;
  onClose: () => void;
  post: IPost;
  userData: IUser;
  isLiking: boolean;
  isLiked: boolean;
  likesCount: number;
  isDisliking: boolean;
  isCommenting: boolean;
  handleLike: (postId: string) => Promise<void>;
  handleDislike: (postId: string) => Promise<void>;
  handleComment: (postId: string) => Promise<void>;
  commentsCount: number;
  postCommentsPage: number;
  setPostCommentsPage: React.Dispatch<React.SetStateAction<number>>;
  setSelectedPostType: React.Dispatch<React.SetStateAction<PostType>>;
  toggleModal: (modalName: string) => void;
  commentMsg: string;
  setCommentMsg: React.Dispatch<React.SetStateAction<string>>;
  postCommentsData: ICommentResponse;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<SnackbarSeverityEnum>
  >;
}

const PostCommentsModal = ({
  selectedPostType,
  onClose,
  isOpen = false,
  post,
  userData,
  likesCount,
  commentsCount,
  isLiking,
  isLiked,
  isDisliking,
  isCommenting,
  handleLike,
  handleDislike,
  handleComment,
  setSelectedPostType,
  toggleModal,
  commentMsg,
  setCommentMsg,
  postCommentsPage,
  setPostCommentsPage,
  postCommentsData,
}: PostCommentsModalProps) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const { user: myData } = useSelector(selectAuthData);

  const filesArray = post.postFiles.map((file, idx) => {
    const fileObj = { id: file.id, img: file.fileUrl, title: file.fileName };
    if (idx === 4 || idx === 5 || idx === 11) return { ...fileObj, cols: 2 };
    if (idx === 8 || idx === 5) return { ...fileObj, rows: 2, cols: 2 };
    return fileObj;
  });
  return (
    <BaseModal
      isOpen={isOpen}
      toggle={onClose}
      disableDefaultFooter
      disableContentMargin
    >
      <Container disableGutters>
        <PostHeader
          post={post}
          userData={userData}
          sx={{
            paddingBottom: '1rem',
          }}
        />

        <Box component="article">
          <PostBodyText
            post={post}
            sx={{
              padding: '1rem 0',
            }}
          />

          {!post.repostedPostId && <QuiltedImageList itemData={filesArray} />}
          {post.repostedPostId && (
            <RepostedPost repostedPostId={post.repostedPostId} />
          )}

          <PostData
            likesCount={likesCount}
            commentsCount={commentsCount}
            repostsCount={post.repostsCount}
          />
        </Box>

        <Box component="footer">
          <Grid
            container
            justifyContent="space-between"
            sx={{ gridColumnGap: '12px', padding: '22px 0' }}
          >
            <Grid item flex={1}>
              <PostButtons
                onClick={() => {
                  isLiked ? handleDislike(post.id) : handleLike(post.id);
                }}
                disabled={isLiking || isDisliking}
              >
                <LikeIcon fillNone={!isLiked} isRed={isLiked} />
                <Typography
                  variant={'badge3'}
                  sx={{
                    color: isLiked ? colors.bgRed : 'text.secondary',
                  }}
                >
                  {` Like${isLiked ? 'd' : ''}`}
                </Typography>
              </PostButtons>
            </Grid>
            <Grid item flex={1}>
              <PostButtons
                onClick={() => {
                  setSelectedPostType(PostType.NONE);
                  toggleModal(MyPostModals.REPOST_MODAL);
                }}
              >
                <RepostIcon />
                Repost
              </PostButtons>
            </Grid>
            <Grid item flex={1}>
              <PostButtons
                onClick={() => {
                  setSelectedPostType(PostType.NONE);
                  toggleModal(MyPostModals.SHARE_MODAL);
                }}
              >
                <SendIcon />
                Send
              </PostButtons>
            </Grid>
          </Grid>
          <ProfilePostComments
            postCommentsPage={postCommentsPage}
            setPostCommentsPage={setPostCommentsPage}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarSeverity={setSnackbarSeverity}
            postCommentsData={postCommentsData}
          />

          <Divider sx={{ width: '100%', marginBottom: '1rem' }} />

          <Grid container columnGap={2} mt={'22px'}>
            <Grid
              container
              columnGap={2}
              width={'100%'}
              ml={'auto'}
              alignItems={'center'}
            >
              <Grid item height="fit-content">
                <Avatar
                  alt="Profile"
                  src={
                    myData && myData?.optimizedUserAvatarBaseUrl
                      ? `data:image/jpeg;base64,${myData.optimizedUserAvatarBaseUrl}`
                      : ''
                  }
                  sx={{ width: 40, height: 40 }}
                />
              </Grid>
              <Grid item flex={1} component={'form'}>
                <TextField
                  multiline={false}
                  style={{ padding: '0px 0px' }}
                  value={commentMsg}
                  onChange={e => {
                    setCommentMsg(e.target.value);
                  }}
                  id="input-with-icon-textfield"
                  fullWidth
                  sx={{
                    padding: 0,
                    '& .MuiInputBase-root': {
                      backgroundColor: 'background.light',
                      borderRadius: '4px',
                      padding: '0px',
                    },
                    '& .MuiInputBase-input': {
                      height: '2.5rem !important',
                      backgroundColor: 'background.light',
                      borderRadius: '4px',
                    },
                    '& .MuiSvgIcon-root': {
                      backgroundColor: 'unset',
                    },
                    '& .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiInputBase-inputAdornedEnd':
                      {
                        padding: '0px !important',
                      },
                  }}
                  placeholder="Comment..."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type="submit"
                          aria-label="send reply button"
                          sx={{
                            p: '0px',
                            width: 25,
                            minWidth: '25px',
                          }}
                          disabled={isCommenting}
                          onClick={e => {
                            e.preventDefault();
                            handleComment(post.id);
                          }}
                        >
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <SnackbarCustom
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Container>
    </BaseModal>
  );
};

export default PostCommentsModal;
