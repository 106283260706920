import { FormHelperText, SxProps, Theme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MuiRadioGroup, {
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material/RadioGroup';
import * as React from 'react';

interface RadioGroupProps extends MuiRadioGroupProps {
  label?: string;
  register?: any;
  error?: boolean;
  helperText?: string;
  children?: React.ReactNode;
  sx?: SxProps;
}

const RadioGroup = ({
  label,
  name,
  register,
  error,
  helperText,
  sx,
  children,
  ...rest
}: RadioGroupProps) => {
  return (
    <FormControl error={!!error}>
      <FormLabel
        id={`${name}-radio-buttons-group-label`}
        focused={false}
        error={false}
        sx={labelStyles}
      >
        {label ?? ''}
      </FormLabel>
      <MuiRadioGroup
        aria-labelledby={`${name}-radio-buttons-group-label`}
        {...rest}
        sx={theme => ({
          ...radioGroupStyles,
          ...(typeof sx === 'function' ? sx(theme) : sx),
        })}
      >
        {children}
      </MuiRadioGroup>
      <FormHelperText id="helper-text">{helperText}</FormHelperText>
    </FormControl>
  );
};

export default RadioGroup;

const labelStyles: SxProps<Theme> = theme => ({
  color: theme.palette.text.primary,
  fontFamily: 'ES Klarheit Kurrent TRIAL',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '800',
  lineHeight: '12px',
  letterSpacing: '0.48px',
  textTransform: 'uppercase',
  pb: '10px',
});

const radioGroupStyles: SxProps = {
  '& .MuiFormControlLabel-root': {
    textTransform: 'capitalize',
    marginRight: '14px',

    '& .MuiFormControlLabel-label': {
      color: '#142237',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '140%',
    },
  },
};
