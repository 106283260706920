import React from 'react';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import Chat from '../ChatNavigation/Chat';
import { IChat } from '../../../../store/apiSlice/chat/chatApi';
import { IGroupChat } from '../../../../store/apiSlice/chat/groupApi';
import { useSelector } from 'react-redux';
import { getSelectedChat } from '../../../../store/apiSlice/chat/chatSlice';

interface ChatNavigationSectionProps {
  name: string;
  chats: (IChat | IGroupChat)[];
  handleSelectedChat: (chat: IChat | IGroupChat) => void;
  isHighlightSelectedChat: boolean;
  isShowLastMessage: boolean;
  sx?: SxProps;
}

const ChatNavigationSection = ({
  name,
  chats,
  handleSelectedChat,
  isHighlightSelectedChat,
  isShowLastMessage,
  sx,
}: ChatNavigationSectionProps) => {
  const selectedChat = useSelector(getSelectedChat);

  if (chats.length === 0) return null;

  return (
    <Stack
      sx={{
        width: '100%',
        gap: '1rem',
        ...sx,
      }}
    >
      <Typography
        typography={'badge2'}
        sx={{
          textTransform: 'uppercase',
          color: 'text.primary',
        }}
      >
        {name}
      </Typography>
      <Stack gap={'0.375rem'}>
        {chats.map(chat => (
          <Box
            key={chat.id}
            onClick={() => handleSelectedChat(chat)}
            sx={{
              width: '100%',
              bgcolor:
                isHighlightSelectedChat && selectedChat?.id === chat.id
                  ? 'background.light'
                  : '',
            }}
          >
            <Chat chat={chat} isShowLastMessage={isShowLastMessage} />
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default ChatNavigationSection;
