import { yupResolver } from '@hookform/resolvers/yup';
import {
  Avatar,
  Box,
  BoxProps,
  Divider,
  IconButton,
  Input,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import CameraIcon from '../../../../components/Icons/CameraIcon';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { LiveAgentProfileSettingsFormInputs } from '../../../../interfaces/profile.interface';
import ArticleLayout from '../../../../layouts/ArticleLayout';
import {
  useGetMyProfileQuery,
  useUpdateMyProfileMutation,
} from '../../../../store/apiSlice/userApi';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { liveAgentProfileSettingsSchema } from '../../../../validation/user-validation';
import LiveAgentProfileSettingsForm from './LiveAgentProfileSettingsForm';
import { IUser } from '../../../../interfaces/user.interface';

interface ProfileSettingsMainContentProps extends BoxProps {}

const ProfileSettingsMainContent = ({
  ...rest
}: ProfileSettingsMainContentProps) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { data: profileInfo, isLoading: isProfileInfoLoading } =
    useGetMyProfileQuery();

  const [updateMyProfile, { isLoading: isUpdatingMyProfile }] =
    useUpdateMyProfileMutation();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<LiveAgentProfileSettingsFormInputs>({
    resolver: yupResolver(liveAgentProfileSettingsSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      bio: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    if (!isProfileInfoLoading && profileInfo) {
      reset(getInitialFieldValues(profileInfo));
    }
  }, [isProfileInfoLoading, profileInfo, reset]);

  const onSubmit: SubmitHandler<
    LiveAgentProfileSettingsFormInputs
  > = async data => {
    const profileData = data;
    const personalInfoFormData = new FormData();

    if (selectedImage) {
      personalInfoFormData.append('file', selectedImage);
    }

    if (profileData.firstName) {
      personalInfoFormData.append('firstName', profileData.firstName);
    }

    if (profileData.lastName) {
      personalInfoFormData.append('lastName', profileData.lastName);
    }

    personalInfoFormData.append('bio', profileData.bio || '');

    try {
      await updateMyProfile(personalInfoFormData).unwrap();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const onReset: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    profileInfo && reset(getInitialFieldValues(profileInfo));
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  return (
    <Box {...rest}>
      <ArticleLayout>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          onReset={onReset}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems={mobileView ? 'flex-start' : 'center'}
            flexDirection={mobileView ? 'column-reverse' : 'row'}
            gap={mobileView ? '12px' : ''}
          >
            <Typography component="h2" variant="h2">
              Profile Settings
            </Typography>
            {/* <Label
              labelType={
                profileInfo?.verified
                  ? LabelTypeEnum.VERIFIED
                  : LabelTypeEnum.UNDER_VERIFICATION
              }
              sx={{ fontSize: '0.625rem' }}
            >
              {profileInfo?.verified
                ? LabelTypeEnum.VERIFIED
                : LabelTypeEnum.UNDER_VERIFICATION}
            </Label> */}
          </Box>
          <Box mt="40px" display="flex" flexDirection="column">
            <Box
              position="relative"
              display="inline-block"
              width="fit-content"
              sx={{ '&:hover .avatar-overlay': { opacity: 1 } }}
            >
              <Avatar
                alt="Profile"
                src={previewUrl || profileInfo?.avatar?.fileUrl}
                sx={{ width: 140, height: 140 }}
              />
              <Box
                className="avatar-overlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  bgcolor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                  opacity: 0,
                  transition: 'opacity 0.3s',
                }}
              >
                <IconButton
                  component="label"
                  sx={{
                    width: 60,
                    height: 60,
                    color: 'white',
                    bgcolor: 'text.accent',
                  }}
                >
                  <CameraIcon />
                  <Input
                    type="file"
                    sx={{ display: 'none' }}
                    inputProps={{ accept: 'image/*' }}
                    onChange={handleImageChange}
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <LiveAgentProfileSettingsForm
            control={control}
            register={register}
            errors={errors}
            setValue={setValue}
            watch={watch}
          />
          <Divider sx={{ m: '48px 0' }} />
          <Box
            display="flex"
            flexDirection={mobileView ? 'column' : 'row'}
            justifyContent="space-between"
            gap="22px"
          >
            <CustomButton
              buttonWidth={mobileView ? '100%' : '220px'}
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              disabled={isProfileInfoLoading || isUpdatingMyProfile}
            >
              save
            </CustomButton>
            <CustomButton
              buttonWidth={mobileView ? '100%' : '220px'}
              variantType={ButtonTypeEnum.SECONDARY}
              disabled={isProfileInfoLoading || isUpdatingMyProfile}
              type="reset"
            >
              cancel
            </CustomButton>
          </Box>
        </Box>
      </ArticleLayout>
    </Box>
  );
};

export default ProfileSettingsMainContent;

const getInitialFieldValues = (
  profileInfo: IUser
): LiveAgentProfileSettingsFormInputs => {
  return {
    firstName: profileInfo?.firstName || null,
    lastName: profileInfo?.lastName || null,
    bio: profileInfo?.bio || null,
  };
};
