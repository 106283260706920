import { Box } from '@mui/material';

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGetMyJobpostsAppliedQuery } from '../../../../store/apiSlice/jobpost/jobUserApplyApi';
import SectionLayout from '../../../../layouts/SectionLayout';
import JobPosts from '../../../JobPostsPage/components/JobPosts';
import { IAppliedUser } from '../../../../interfaces/jobpost.interface';
import NoContent from '../../../../components/shared/no-content/NoContent';

const sortMenuItems = [
  { label: 'Newest First', value: 'desc' },
  { label: 'Oldest', value: 'asc' },
];

const LIMIT = 10;

const MyJobApplicationsTab = () => {
  const [jobPostsArray, setJobPostsArray] = useState<
    { appliedUser: IAppliedUser }[]
  >([]);
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [totalPosts, setTotalPosts] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get('query') ?? '';
  const page = parseInt(searchParams.get('page') || '1', 10);
  const sort = searchParams.get('sortBy') ?? 'desc';
  const industry = searchParams.get('industry') ?? '';
  const position = searchParams.get('position') ?? '';
  const location = searchParams.get('location') ?? '';

  const queryParams = {
    page,
    search: query,
    limit: LIMIT,
    sort,
    industries: industry,
    position,
    location,
  };

  const {
    data: jobPostsData,
    error,
    isLoading,
  } = useGetMyJobpostsAppliedQuery(queryParams, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (jobPostsData && jobPostsData?.data) {
      if (page === 1) setJobPostsArray(jobPostsData.data);
      else setJobPostsArray(prev => [...prev, ...jobPostsData.data]);

      setTotalPosts(jobPostsData.total);

      const hasNext =
        jobPostsData.limit * (jobPostsData.page - 1) + jobPostsData.limit <
        jobPostsData.total;

      setIsLoadMore(hasNext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPostsData]);

  useEffect(() => {
    setSearchParams(prev => {
      prev.set('page', '1');
      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, sort]);

  if (!isLoading && !jobPostsData?.data.length) {
    return <NoContent />;
  }

  return (
    <Box bgcolor={'background.white'}>
      <Box display="flex" flexDirection="column" flexGrow="1">
        <SectionLayout
          height={'100%'}
          sx={{
            bgcolor: 'background.white',
            p: { xs: '36px 16px', md: '24px', lg: '16px 36px 48px 36px' },
          }}
        >
          <JobPosts
            listToolBarSubtitle="My Job Applications"
            sortMenuItems={sortMenuItems}
            jobPosts={jobPostsArray}
            jobPostTitleClickPath="me/job-application"
            isLoadMore={isLoadMore}
            isLoading={isLoading}
          />
        </SectionLayout>
      </Box>
    </Box>
  );
};

export default MyJobApplicationsTab;
