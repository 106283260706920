import * as React from 'react';
import ImageList, { ImageListProps } from '@mui/material/ImageList';
import ImageListItem, { ImageListItemProps } from '@mui/material/ImageListItem';
// import CollapsedContent from '../CollapsedContent/CollapsedContent';
import { MyPostModals } from '../../pages/home/shared-components/posts/ProfilePost';
import PostVideo from '../../pages/home/shared-components/posts/PostVideo/PostVideo';
import { Box } from '@mui/material';

const srcset = (image: string, size: number, rows = 1, cols = 1) => {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
};

type ItemData = {
  id: string;
  img: string;
  title: string;
  rows?: number;
  cols?: number;
};

interface QuiltedImageListProps {
  itemData: ItemData[];
  toggleModal?: (modalName: string) => void;
  imageListProps?: Omit<ImageListProps, 'children'>; // Omit 'children' to avoid conflict
  imageListItemProps?: Omit<ImageListItemProps, 'children'>; // Omit 'children' to avoid conflict
}

const QuiltedImageList = ({
  itemData,
  toggleModal,
  imageListProps = {},
  imageListItemProps = {},
}: QuiltedImageListProps) => {
  const isVideoFile = (fileName: string) => {
    const videoExtensions = [
      '.mp4',
      '.avi',
      '.mkv',
      '.mov',
      '.wmv',
      '.flv',
      '.webm',
      '.m4v',
      '.mpeg',
    ];

    const fileExtension = fileName
      .slice(fileName.lastIndexOf('.'))
      .toLowerCase();

    return videoExtensions.includes(fileExtension);
  };

  return (
    // <CollapsedContent collapsedSize={480}>
    <ImageList
      {...imageListProps}
      variant="quilted"
      cols={itemData.length < 4 ? itemData.length : 4}
    >
      {itemData.map(item => (
        <ImageListItem
          onClick={() =>
            toggleModal && toggleModal(MyPostModals.POST_WITH_COMMENTS_MODAL)
          }
          sx={{
            overflow: 'hidden',
            cursor: toggleModal ? 'pointer' : 'default',
          }}
          key={item.img}
          {...imageListItemProps}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          {!isVideoFile(item.img) ? (
            <Box
              sx={theme => ({
                width: '100%',
                height: '0',
                paddingBottom: '100%',
                position: 'relative',
                backgroundColor: theme.palette.background.lightDark,
              })}
            >
              <Box
                component="img"
                {...srcset(item.img, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            </Box>
          ) : (
            <Box onClick={e => e.stopPropagation()}>
              <PostVideo
                file={{
                  id: item.id,
                  fileUrl: item.img,
                  fileName: item.title,
                }}
              />
            </Box>
          )}
        </ImageListItem>
      ))}
    </ImageList>
    // </CollapsedContent>
  );
};

export default QuiltedImageList;
