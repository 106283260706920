import React, { FC } from 'react';
import { IPost } from '../../../../interfaces/post.interface';
import { Box, SxProps, Typography } from '@mui/material';
import { removeHashtagWords } from '../../../../utils/helper/removeHashtagWords';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface PostBodyTextProps {
  post: IPost;
  sx?: SxProps;
}

const PostBodyText: FC<PostBodyTextProps> = ({ post, sx }) => {
  const { theme } = useThemeContext();

  return (
    <Box
      component="header"
      sx={{
        minHeight: post.repostedPostId ? '5.75rem' : 'auto',
        ...sx,
      }}
    >
      <Typography
        variant="body3"
        sx={{
          color: theme.palette.secondary.main,
          whiteSpace: 'pre-wrap',
          textAlign: 'justify',
        }}
      >
        {removeHashtagWords(post.content)}
      </Typography>

      {post.tags.length > 0 && (
        <Typography
          variant="body3"
          sx={{
            mt: 2,
            color: theme.palette.primary.main,
            fontWeight: 700,
          }}
        >
          {post.tags.map(tag => `#${tag} `)}
        </Typography>
      )}
    </Box>
  );
};

export default PostBodyText;
