import {
  Avatar,
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import Rating from '../../../../../components/MUIComponents/Rating';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import { ChatStateEnum } from '../../../../../enums/chat-enum';
import { UserRoleEnum } from '../../../../../enums/user-data-field-enum';
import { useModalManager } from '../../../../../hooks/useModalManager';
import { GetMyAgentsRes } from '../../../../../interfaces/user.interface';
import {
  useCreateChatMutation,
  useGetAllMyChatsQuery,
} from '../../../../../store/apiSlice/chat/chatApi';
import {
  setChatState,
  setSelectedChat,
} from '../../../../../store/apiSlice/chat/chatSlice';
import { useGetAgentReviewsCountByAgentIdQuery } from '../../../../../store/apiSlice/reviewApi';
import { selectAuthData } from '../../../../../store/selectors/authSelector';
import EndAssistantCompleteModal from '../modals/EndAssistantCompleteModal';
import EndAssistantModal from '../modals/EndAssistantModal';
import LeaveReviewModal from '../modals/LeaveReviewModal';
import ReviewModal from '../modals/ReviewModal';

enum LiveAgentCardModals {
  REVIEW_MODAL = 'REVIEW_MODAL',
  LEAVE_REVIEW_MODAL = 'LEAVE_REVIEW_MODAL',
  END_ASSISTANT_MODAL = 'END_ASSISTANT_MODAL',
  END_ASSISTANT_COMPLETE_MODAL = 'END_ASSISTANT_COMPLETE_MODAL',
}

interface LiveAgentCardProps {
  agentData: GetMyAgentsRes | undefined;
  isShowLeaveReview?: boolean;
  isAgentLoading: boolean;
  refetchAgent: () => void;
}

const LiveAgentCard = ({
  agentData,
  isAgentLoading,
  isShowLeaveReview = false,
  refetchAgent,
}: LiveAgentCardProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const authData = useSelector(selectAuthData);
  const isShowAgentRating = useMemo(
    (): boolean =>
      authData?.user?.role ? authData?.user?.role !== UserRoleEnum.USER : false,
    [authData]
  );

  const { data: myChats } = useGetAllMyChatsQuery();
  const [createChat, { isLoading: isCreatingChat }] = useCreateChatMutation();
  const dispatch = useDispatch();

  const { currentModal, toggleModal } = useModalManager();

  const {
    data: agentReviews,
    isLoading: isAgentReviewsLoading,
    refetch: refetchAgentReviews,
  } = useGetAgentReviewsCountByAgentIdQuery(agentData?.id || '', {
    skip: !agentData?.id,
  });

  const openReviewModal = () => toggleModal(LiveAgentCardModals.REVIEW_MODAL);

  const openLeaveReviewModal = () =>
    toggleModal(LiveAgentCardModals.LEAVE_REVIEW_MODAL);

  const openEndAssistantModal = () =>
    toggleModal(LiveAgentCardModals.END_ASSISTANT_MODAL);

  const openEndAssistantCompleteModal = () =>
    toggleModal(LiveAgentCardModals.END_ASSISTANT_COMPLETE_MODAL);

  const handleMessage = useCallback(
    (toUserId: string | undefined) => {
      if (!toUserId) {
        return;
      }

      return () => {
        dispatch(setChatState(ChatStateEnum.CHAT));

        if (myChats)
          for (const chat of myChats)
            if (chat.withUserId === toUserId) {
              dispatch(setSelectedChat(chat));
              return navigate('/chat');
            }

        createChat(toUserId)
          .unwrap()
          .then(chat => {
            dispatch(setSelectedChat(chat));
            navigate('/chat');
          })
          .catch(console.log);
      };
    },
    [myChats, createChat, dispatch, navigate]
  );

  const handleCloseEndAssistanceComplete = () => {
    toggleModal(LiveAgentCardModals.END_ASSISTANT_COMPLETE_MODAL);
    navigate('/');
  };

  return (
    <>
      {agentData?.id && (
        <>
          <ReviewModal
            isOpen={currentModal === LiveAgentCardModals.REVIEW_MODAL}
            agentId={agentData.id}
            onClose={() => {
              toggleModal(LiveAgentCardModals.REVIEW_MODAL);
            }}
          />
          <LeaveReviewModal
            agentId={agentData.id}
            isOpen={currentModal === LiveAgentCardModals.LEAVE_REVIEW_MODAL}
            onClose={() => {
              toggleModal(LiveAgentCardModals.LEAVE_REVIEW_MODAL);
            }}
            refetchReviews={refetchAgentReviews}
            refetchMyAgents={refetchAgent}
          />
          <EndAssistantModal
            agentId={agentData.id}
            isOpen={currentModal === LiveAgentCardModals.END_ASSISTANT_MODAL}
            onClose={() => {
              toggleModal(LiveAgentCardModals.END_ASSISTANT_MODAL);
            }}
            openEndAssistantCompleteModal={openEndAssistantCompleteModal}
          />
        </>
      )}
      <EndAssistantCompleteModal
        isOpen={
          currentModal === LiveAgentCardModals.END_ASSISTANT_COMPLETE_MODAL
        }
        onClose={() => handleCloseEndAssistanceComplete()}
      />

      <Box
        className="main-content__live-agent-card"
        rowGap="28px"
        sx={{
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          alignItems: { md: 'center' },
          padding: { xs: '36px 16px  16px', lg: '36px 16px 16px' },
          border: `solid 1px ${theme.palette.background.lightDark}`,
        }}
      >
        <Typography
          display={{ md: 'none' }}
          variant="badge2"
          textTransform="uppercase"
        >
          your live agent
        </Typography>

        {isAgentLoading ? (
          skeletonUserCard
        ) : (
          <>
            <Box
              display="flex"
              flexDirection={{ xs: 'row', md: 'column' }}
              alignItems={'center'}
              rowGap="28px"
              columnGap="16px"
            >
              <Typography
                display={{ xs: 'none', md: 'block' }}
                variant="badge2"
                textTransform="uppercase"
              >
                your live agent
              </Typography>

              <Avatar
                alt="Profile"
                // src={agentData?.avatar?.fileUrl || ''}
                src={
                  agentData?.optimizedUserAvatarBaseUrl
                    ? `data:image/jpeg;base64,${agentData.optimizedUserAvatarBaseUrl}`
                    : ''
                }
                sx={{
                  [theme.breakpoints.up('xs')]: { width: 100, height: 100 },
                  [theme.breakpoints.up('lg')]: { width: 140, height: 140 },
                  border: `2px solid ${theme.palette.background.white}`,
                }}
              />

              <Box
                className="profile-name-review-container"
                // m={mobileView ? '35px 0 0' : '28px 0'}
                display="flex"
                flexDirection="column"
                alignItems="center"
                rowGap="12px"
              >
                <Typography
                  variant="h3"
                  color={theme.palette.text.primary}
                  textAlign={{ xs: 'left', lg: 'center' }}
                >
                  {`${agentData?.firstName || ''} ${agentData?.lastName || ''}`}
                </Typography>
                {isShowAgentRating && (
                  <>
                    <Rating
                      readOnly
                      value={agentData?.rating || 0}
                      sx={{ fontSize: 20 }}
                    />
                    <Link to="" onClick={openReviewModal}>
                      <Typography variant="body3">
                        {agentReviews?.data ?? 0} reviews
                      </Typography>
                    </Link>
                  </>
                )}
              </Box>
            </Box>

            <Box
              className="profile-actions"
              width="100%"
              display="flex"
              flexDirection="column"
              rowGap="12px"
            >
              <CustomButton
                variantType={ButtonTypeEnum.PRIMARY}
                fullWidth
                disabled={isCreatingChat}
                onClick={handleMessage(agentData?.id)}
              >
                send message
              </CustomButton>
              {isShowLeaveReview && (
                <CustomButton
                  variantType={ButtonTypeEnum.SECONDARY}
                  fullWidth
                  onClick={openLeaveReviewModal}
                >
                  leave review
                </CustomButton>
              )}
              <CustomButton
                sx={{
                  display: { xs: 'none', md: 'block' },
                }}
                variantType={ButtonTypeEnum.SECONDARY}
                fullWidth
                onClick={openEndAssistantModal}
              >
                end assistance
              </CustomButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default LiveAgentCard;

const skeletonUserCard = (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2rem',
      p: { xs: '22px 16px', lg: '0' },
    }}
  >
    <Skeleton
      variant="circular"
      sx={{
        width: '60%',
        height: 'auto',
        aspectRatio: '1',
      }}
    />
    <Skeleton
      variant="rectangular"
      sx={{
        width: '70%',
        height: '1.4rem',
      }}
    />
  </Box>
);
