import formatEnumValue from './formatEnumValue';

function createArrayFromEnum<T extends object, K extends string>(
  enumObj: T,
  keyName: K
): Array<{ id: string } & Record<K, string>> {
  return Object.entries(enumObj).map(([key, val]) => {
    const formattedValue = formatEnumValue(val);

    return {
      id: key,
      [keyName]: formattedValue,
    } as { id: string } & Record<K, string>;
  });
}

export default createArrayFromEnum;
