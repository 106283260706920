import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  GetAllApplicantsRes,
  GetMyAgentsRes,
  IAvatar,
  IPaginatedCandidatesResponse,
  IUser,
  IUserAboutResponse,
  IUserAgentInfoResponse,
  IUserInformationResponse,
  IUserResponse,
  IUserScore,
} from '../../interfaces/user.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';
import { IUserCompanyProfile } from '../../interfaces/user-company-info.interface';

const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_USER_MICROSERVICE_API_URL}/user`
  ),
  tagTypes: ['user'],

  endpoints: builder => ({
    verifyUserProfile: builder.mutation<IUserResponse, any>({
      query: (data: FormData) => ({
        url: `/verify-profile`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['user'],
    }),
    getMyProfile: builder.query<IUser, void>({
      query: () => '/my-profile',
      providesTags: ['user'],
    }),
    updateMyProfile: builder.mutation<IUser, FormData>({
      query: (data: FormData) => ({
        url: `/update-profile`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['user'],
    }),
    getUserInformation: builder.query<IUserInformationResponse, string>({
      query: userId => `/user-information/${userId}`,
      providesTags: ['user'],
    }),
    getUserAgentInfoById: builder.query<IUserAgentInfoResponse, string>({
      query: agentId => `/agent-information/${agentId}`,
      providesTags: ['user'],
    }),
    getMyProfileScore: builder.query<IUserScore, void>({
      query: () => '/my-profile-score',
      providesTags: ['user'],
    }),
    getUserInfo: builder.query<IUser, string>({
      query: userId => `/user-basic-info/${userId}`,
      providesTags: ['user'],
    }),
    getUserAbout: builder.query<IUserAboutResponse, string>({
      query: userId => '/user-about/' + userId,
      providesTags: ['user'],
    }),
    getAllCandidates: builder.query<
      IPaginatedCandidatesResponse,
      {
        page?: number;
        limit?: number;
        search?: string;
        sort?: string;
        country?: string;
        city?: string;
        sports?: string;
        from?: number;
        to?: number;
      } | void
    >({
      query: (
        args = {
          page: 1,
          limit: 10,
          search: '',
          sort: 'desc',
          country: '',
          city: '',
          sports: '',
          from: 0,
          to: 0,
        }
      ) => {
        const {
          page = 1,
          limit = 10,
          search = '',
          sort = 'desc',
          country = '',
          city = '',
          sports = '',
          from = 0,
          to = 0,
        } = args || {};
        return `candidates?page=${page}&limit=${limit}&search=${search}&sort=${sort}&country=${country}&city=${city}&sports=${sports}&from=${from}&to=${to}`;
      },
      providesTags: ['user'],
    }),
    getUserCompanyProfileInfoById: builder.query<IUserCompanyProfile, string>({
      query: companyId => '/company-profile-information/' + companyId,
      providesTags: ['user'],
    }),
    getUserCompanyInfo: builder.query<any, void>({
      // fix typing
      query: () => '/my-company-profile',
      providesTags: ['user'],
    }),
    updateCompanyAvatar: builder.mutation<File, FormData>({
      query: (data: FormData) => ({
        url: 'update-company-avatar',
        method: 'PUT',
        body: data,
      }),
    }),
    getBannerById: builder.query<IAvatar, string>({
      query: id => `/get-banner-by-id/${id}`,
      providesTags: ['user'],
    }),
    updateBanner: builder.mutation<File, FormData>({
      query: (data: FormData) => ({
        url: 'update-banner',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['user'],
    }),
    deleteMyBanner: builder.mutation<IUser, void>({
      query: () => ({
        url: 'delete-banner',
        method: 'DELETE',
      }),
      invalidatesTags: ['user'],
    }),

    // Live Agent
    getAllApplicants: builder.query<
      GetAllApplicantsRes,
      {
        page?: number;
        limit?: number;
        search?: string;
        sort?: string;
        sortType?: string;
      } | void
    >({
      query: (
        args = {
          page: 1,
          limit: 10,
          search: '',
          sort: 'desc',
          sortType: 'country',
        }
      ) => {
        const {
          page = 1,
          limit = 10,
          search = '',
          sort = 'desc',
          sortType = '',
        } = args || {};
        return `/applicants?page=${page}&limit=${limit}&search=${search}&sort=${sort}&sortType=${sortType}`;
      },
      providesTags: ['user'],
    }),

    // Live Agent, part for User
    getMyAgent: builder.query<GetMyAgentsRes, void>({
      query: () => `my-agent`,
      providesTags: ['user'],
    }),
  }),
});

export const {
  useVerifyUserProfileMutation,
  useGetMyProfileQuery,
  useUpdateMyProfileMutation,
  useGetMyProfileScoreQuery,
  useGetUserInfoQuery,
  useGetUserAboutQuery,
  useGetUserCompanyProfileInfoByIdQuery,
  useGetUserCompanyInfoQuery,
  useUpdateCompanyAvatarMutation,
  useGetAllCandidatesQuery,
  useGetUserInformationQuery,
  useGetUserAgentInfoByIdQuery,
  useGetBannerByIdQuery,
  useUpdateBannerMutation,
  useDeleteMyBannerMutation,

  // Live Agent
  useGetAllApplicantsQuery,

  // Live Agent, part for User
  useGetMyAgentQuery,
} = userApi;

export default userApi;
