import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { CareerDevelopmentStatus } from '../../../../../../interfaces/individual-stage.interface';
import { GetAllPartnershipsRes } from '../../../../../../interfaces/partnership.interface';
import { UserItemStatus } from '../../../components/user-list/UserListItem';
import RosterListItem from './RosterListItem';
import SkeletonList from '../../../../../../components/MUIComponents/SkeletonList';
import SkeletonTabContent from '../../SkeletonTabContent';

interface RosterListProps {
  partnershipsData?: GetAllPartnershipsRes;
  isLoading?: boolean;
}

const RosterList = ({ partnershipsData, isLoading }: RosterListProps) => {
  const formatedPartnerships = useMemo(
    () =>
      partnershipsData?.partnerships.map(p => {
        const totalSteps = p.statuses.length;
        const completedSteps = p.statuses.filter(
          s => s.status === CareerDevelopmentStatus.COMPLETED
        ).length;
        const userItemStatus = p.statuses.length
          ? UserItemStatus.ACTIVE
          : UserItemStatus.INACTIVE;
        const userInfo = {
          id: p.userId,
          avatarSrc: p.avatarUrl ?? '',
          firstName: p.firstName ?? '',
          lastName: p.lastName ?? '',
          sportNames: p.sports.map(s => s.name),
        };

        return { userInfo, totalSteps, completedSteps, userItemStatus };
      }),
    [partnershipsData]
  );

  return (
    <Box component="ul" className="roster-user-list">
      {isLoading && <SkeletonTabContent />}
      {!isLoading &&
        !!formatedPartnerships?.length &&
        formatedPartnerships.map((p, index) => (
          <RosterListItem
            key={index}
            userInfo={p.userInfo}
            totalSteps={p.totalSteps}
            completedSteps={p.completedSteps}
            userItemStatus={p.userItemStatus}
          />
        ))}
    </Box>
  );
};

export default RosterList;
