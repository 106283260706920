import { PixelCrop } from 'react-image-crop';

const setCanvasPreview = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop
): void => {
  const ctx = canvas.getContext('2d');
  if (!ctx) throw new Error('No 2D context');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio || 1;

  // Only resize canvas if crop dimensions are different or the image changes
  const newCanvasWidth = Math.floor(crop.width * scaleX * pixelRatio);
  const newCanvasHeight = Math.floor(crop.height * scaleY * pixelRatio);

  if (canvas.width !== newCanvasWidth || canvas.height !== newCanvasHeight) {
    canvas.width = newCanvasWidth;
    canvas.height = newCanvasHeight;
  }

  ctx.scale(pixelRatio, pixelRatio); // Apply device pixel ratio scaling for sharp rendering
  ctx.imageSmoothingQuality = 'high'; // Enable high-quality image smoothing

  // Only update crop if it changes
  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  ctx.save();
  ctx.translate(-cropX, -cropY); // Apply crop translation

  // Draw the image at the correct position
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
};

export default setCanvasPreview;
