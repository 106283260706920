import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
} from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';

interface TextFieldCustomProps {
  height?: string | number;
  width?: string | number;
  maxLength?: number;
  register?: UseFormRegisterReturn;
  error?: boolean;
  helperText?: string;
}

type TextFieldProps = TextFieldCustomProps & MUITextFieldProps;

const TextField = (props: TextFieldProps) => {
  return (
    <MUITextField
      // id="input-textfield"
      {...props.register}
      helperText={props.helperText}
      error={props.error}
      variant="outlined"
      fullWidth
      sx={{
        border: 'none',
        '& .MuiInputBase-input': {
          // height: '400px !important',
          backgroundColor: '#F6F7F8 !important',
          borderRadius: '4px',
          '&::placeholder': {
            color: 'text.secondary',
            opacity: '100',
          },
        },
        '& .MuiSvgIcon-root': {
          backgroundColor: 'unset',
        },
      }}
      // label="TextField"
      multiline
      select={false}
      inputProps={{
        style: {
          height: props.height || '100px',
          padding: '20px 16px',
        },
        maxLength: props.maxLength || 500,
      }}
      InputProps={{
        style: {
          padding: 0,
        },
      }}
      {...props}
    />
  );
};

export default TextField;
