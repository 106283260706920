import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import FilterLayout from '../../../../../layouts/FilterLayout';
import FilterActionButtons from '../../../../CandidatesPage/components/CandidatesSidebar/CandidatesFilters/FilterActionButtons';
import DateFilter from '../PeopleFilters/DateFilter';
import { useSearchParams } from 'react-router-dom';

interface PostsFiltersProps {}

const PostsFilters: FC<PostsFiltersProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [fromDate, setFromDate] = useState<string>(
    searchParams.get('from') ?? ''
  );
  const [toDate, setToDate] = useState<string>(searchParams.get('to') ?? '');

  const handleFilterReset = () => {
    setFromDate('');
    setToDate('');

    setSearchParams(prevParams => {
      prevParams.set('from', '');
      prevParams.set('to', '');

      return prevParams;
    });
  };

  const handleFilterApply = () => {
    setSearchParams(prevParams => {
      prevParams.set('from', fromDate);
      prevParams.set('to', toDate);

      return prevParams;
    });
  };

  return (
    <Stack direction={'column'}>
      <FilterLayout name={'Date Posted'}>
        <DateFilter
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
      </FilterLayout>

      <FilterActionButtons
        handleFilterReset={handleFilterReset}
        handleFilterApply={handleFilterApply}
      />
    </Stack>
  );
};

export default PostsFilters;
