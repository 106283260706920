import { Box, Typography } from '@mui/material';
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Path,
} from 'react-hook-form';
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import DateRangeIcon from '../Icons/DateRangeIcon';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

interface DateInputProps<T extends FieldValues> {
  control: Control<T, any>;
  name: Path<T>;
  label?: string;
  error?: FieldError;
  helperText?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  datePickerProps?: DatePickerProps<Dayjs>;
}

const DateInput = <T extends FieldValues>({
  control,
  name,
  label,
  error,
  helperText,
  placeholder = 'DD.MM.YYYY',
  onChange,
  disabled,
  datePickerProps,
}: DateInputProps<T>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" flexDirection="column" gap="12px" width="100%">
        <Typography variant="caption" padding={'0 4px'} color={'text.primary'}>
          {label}
        </Typography>
        <Box
          display="flex"
          width="100%"
          sx={{
            position: 'relative',
          }}
        >
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <DatePicker
                sx={{ width: '100%' }}
                format="DD.MM.YYYY"
                value={field.value ? dayjs(field.value) : null}
                inputRef={field.ref}
                onChange={date => {
                  field.onChange(date);
                  if (onChange) {
                    onChange(date?.toString() || '');
                  }
                }}
                views={['year', 'month', 'day']}
                openTo={'year'}
                slots={{
                  openPickerIcon: DateRangeIcon,
                }}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    helperText: helperText || '',
                    error: !!error,
                    placeholder: placeholder,
                    disabled: disabled,
                    InputLabelProps: {
                      shrink: true,
                    },
                    onInput: (e: React.FormEvent<HTMLInputElement>) => {
                      const value = (e.currentTarget as HTMLInputElement).value;
                      if (value && /\d{2}\.\d{2}\.\d{4}/.test(value)) {
                      }
                    },
                  },
                }}
                disabled={disabled}
                {...datePickerProps}
              />
            )}
          />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default DateInput;
