import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import DateRangeIcon from '../../../../../components/Icons/DateRangeIcon';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface DateFilterProps {
  fromDate: string;
  setFromDate: (value: string) => void;
  toDate: string;
  setToDate: (value: string) => void;
}

const DateFilter: FC<DateFilterProps> = ({
  fromDate,
  setFromDate,
  setToDate,
  toDate,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1.375rem',
          padding: '0 1rem 1.375rem',
        }}
      >
        <Stack direction={'column'} spacing={'0.5rem'}>
          <Typography variant={'body3'}>{'From: '}</Typography>
          <DatePicker
            sx={{ width: '100%' }}
            format="DD.MM.YYYY"
            value={fromDate ? dayjs(fromDate, 'DD.MM.YYYY') : null}
            onChange={(date: Dayjs | null) => {
              if (date) setFromDate(date.format('YYYY-MM-DD'));
            }}
            views={['year', 'month', 'day']}
            openTo={'year'}
            slots={{
              openPickerIcon: DateRangeIcon,
            }}
            slotProps={{
              textField: {
                variant: 'outlined',
                InputLabelProps: {
                  shrink: true,
                },
                onInput: (e: React.FormEvent<HTMLInputElement>) => {
                  const value = (e.currentTarget as HTMLInputElement).value;
                  if (value && /\d{2}\.\d{2}\.\d{4}/.test(value)) {
                  }
                },
              },
            }}
          />
        </Stack>
        <Stack direction={'column'} spacing={'0.5rem'}>
          <Typography variant={'body3'}>{'To: '}</Typography>
          <DatePicker
            sx={{ width: '100%' }}
            format="DD.MM.YYYY"
            value={toDate ? dayjs(toDate, 'DD.MM.YYYY') : null}
            onChange={(date: Dayjs | null) => {
              if (date) setToDate(date.format('YYYY-MM-DD'));
            }}
            views={['year', 'month', 'day']}
            openTo={'year'}
            slots={{
              openPickerIcon: DateRangeIcon,
            }}
            slotProps={{
              textField: {
                variant: 'outlined',
                InputLabelProps: {
                  shrink: true,
                },
                onInput: (e: React.FormEvent<HTMLInputElement>) => {
                  const value = (e.currentTarget as HTMLInputElement).value;
                  if (value && /\d{2}\.\d{2}\.\d{4}/.test(value)) {
                  }
                },
              },
            }}
          />
        </Stack>
      </Box>
    </LocalizationProvider>
  );
};

export default DateFilter;
