import {
  Autocomplete,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldErrors,
  UseFormSetValue,
} from 'react-hook-form';
import { ITeamsByLeagueNameResponse } from '../../../../../interfaces/career.interface';
import ArrowDownIcon from '../../../../Icons/ArrowDownIcon';
import { TeamFormInputs } from '../SportBackgroundItem/SportBackgroundItem';
import AutocompleteOption from '../../../../shared/AutocompleteOption/AutocompleteOption';

interface TeamsProps {
  teams: ITeamsByLeagueNameResponse[];
  isLoading: boolean;
  isErrors: boolean;
  control: Control<TeamFormInputs, any>;
  setFormValue: UseFormSetValue<TeamFormInputs>;
  formStateErrors: FieldErrors<TeamFormInputs>;
  disabled: boolean;
  handleFieldChange: (field: keyof TeamFormInputs, value: any) => void;
}

const Teams = ({
  teams,
  isLoading,
  isErrors,
  control,
  setFormValue,
  formStateErrors,
  disabled,
  handleFieldChange,
}: TeamsProps) => {
  const teamValue = useCallback(
    (field: ControllerRenderProps<TeamFormInputs, 'teamId'>) => {
      return teams.find(el => el.idTeam === field.value) || null;
    },
    [teams]
  );

  return (
    <Controller
      name="teamId"
      control={control}
      render={({ field }) => {
        return (
          <FormControl
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
            variant="outlined"
            fullWidth
            error={!!formStateErrors.teamId}
          >
            <Typography
              variant="caption"
              sx={{
                padding: '0 2px',
                mb: '12px',
                color: field.disabled ? 'text.disabled' : 'text.primary',
              }}
            >
              Team:
            </Typography>
            {teams && (
              <Autocomplete
                value={teamValue(field)}
                loading={isLoading}
                options={teams ?? []}
                getOptionLabel={option => option.strTeam || ''}
                isOptionEqualToValue={(option, value) => {
                  return option.idTeam === value?.idTeam;
                }}
                defaultValue={null}
                onChange={(event, newValue) => {
                  field.onChange(newValue ? newValue.idTeam : null);
                  handleFieldChange('teamId', newValue?.idTeam);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Select a team"
                    error={!!formStateErrors.teamId}
                    helperText={
                      formStateErrors.teamId
                        ? formStateErrors.teamId.message
                        : ''
                    }
                    disabled={isLoading || disabled}
                  />
                )}
                renderOption={(props, option) => (
                  <AutocompleteOption text={option.strTeam} props={props} />
                )}
                disabled={isLoading || disabled}
                sx={{
                  '.MuiInputBase-root': {
                    height: '50px',
                  },
                  '.MuiSvgIcon-root': {
                    bgcolor: 'transparent !important',
                  },
                }}
                popupIcon={<ArrowDownIcon />}
              />
            )}
          </FormControl>
        );
      }}
    />
  );
};

export default Teams;
