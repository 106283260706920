import React from 'react';
import { Box, BoxProps, SxProps } from '@mui/material';

const calculateStepWidth = (
  totalSteps: number,
  availableContainerWidth: number = 600,
  gap: number = 6,
  maxWidth: number = 30,
  minWidth: number = 3
) => {
  // Calculate the total space occupied by gaps between steps
  const totalGapWidth = (totalSteps - 1) * gap;

  // Calculate available space for steps
  const availableWidth = availableContainerWidth - totalGapWidth;

  // Calculate width for each step
  let stepWidth = availableWidth / totalSteps;

  // Clamp the width to ensure it's not too large or too small
  stepWidth = Math.max(Math.min(stepWidth, maxWidth), minWidth);

  return stepWidth;
};

interface StepProgressBarProps extends BoxProps {
  totalSteps: number;
  completedSteps: number;
  stepSx?: SxProps;
}

const StepProgressBar: React.FC<StepProgressBarProps> = ({
  width = 1,
  height = 6,
  columnGap = '6px',
  totalSteps,
  completedSteps,
  stepSx,
  ...rest
}) => {
  const stepWidth = calculateStepWidth(totalSteps);

  return (
    <Box
      display="flex"
      width={width}
      height={height}
      overflow="hidden"
      columnGap={columnGap}
      sx={{
        width: '100%',
      }}
      {...rest}
    >
      {Array.from({ length: totalSteps }, (_, index) => (
        <Box
          key={index}
          // flex={1}
          bgcolor={
            index < completedSteps ? 'success.main' : 'background.lightDark'
          }
          borderRight={index < totalSteps - 1 ? '1px solid white' : 'none'}
          sx={{
            transition: 'background-color 0.3s ease',
            width: `${stepWidth}px`,
            ...stepSx,
          }}
        />
      ))}
    </Box>
  );
};

export default StepProgressBar;
