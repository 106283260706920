import React, { FC } from 'react';
import { Box, Grid, SxProps, Typography } from '@mui/material';
import LikeIcon from '../../../../components/Icons/LikeIcon';

interface PostDataProps {
  likesCount: number;
  commentsCount: number;
  repostsCount: number;
  sx?: SxProps;
}

const PostData: FC<PostDataProps> = ({
  likesCount,
  commentsCount,
  repostsCount,
  sx,
}) => {
  return (
    <Box
      component="figcaption"
      sx={{
        padding: '1rem',
        ...sx,
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item display="flex" alignItems="center" columnSpacing="0.375rem">
          <LikeIcon />
          <Typography
            variant="caption"
            ml="0.375rem"
            color={'text.secondary'}
            sx={{ cursor: 'pointer' }}
          >
            {likesCount} Likes
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            color={'text.secondary'}
            sx={{
              marginRight: '0.75rem',
              cursor: 'pointer',
            }}
          >
            {commentsCount} Comments
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            sx={{ cursor: 'pointer' }}
          >
            {repostsCount} Reposts
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PostData;
