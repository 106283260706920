export enum UserDataFiledEnum {
  FRIEND = 'friend',
  USER = 'user',
}

export enum UserRoleEnum {
  COMPANY = 'COMPANY',
  USER = 'USER',
  AGENT = 'AGENT',
}
