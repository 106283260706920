import {
  Avatar,
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Rating from '../../MUIComponents/Rating';
import { useGetUserAgentInfoByIdQuery } from '../../../store/apiSlice/userApi';
import { useCurrentPageUserId } from '../../../hooks/useCurrentPageUserId';

interface LiveAgentCardProps {}

const LiveAgentCard = (props: LiveAgentCardProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const { userId } = useCurrentPageUserId();

  const { data: agentData, isLoading: isAgentDataLoading } =
    useGetUserAgentInfoByIdQuery(userId || '', { skip: !userId });

  return (
    <Box
      className="live-agent-card"
      rowGap="28px"
      sx={{
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {isAgentDataLoading && skeletonUserCard}
      {!isAgentDataLoading && (
        <Box
          display="flex"
          flexDirection={mobileView ? 'row' : 'column'}
          alignItems="center"
          rowGap="28px"
        >
          <Avatar
            alt="Profile"
            src={agentData?.avatar?.fileUrl || ''}
            sx={{
              [theme.breakpoints.up('xs')]: { width: 100, height: 100 },
              [theme.breakpoints.up('lg')]: { width: 140, height: 140 },
              border: `2px solid ${theme.palette.background.white}`,
            }}
          />

          <Box
            className="profile-name-review-container"
            // m={mobileView ? '35px 0 0' : '28px 0'}
            display="flex"
            flexDirection="column"
            alignItems="center"
            rowGap="12px"
          >
            <Typography
              variant="h3"
              color={theme.palette.text.primary}
              textAlign={{ xs: 'left', lg: 'center' }}
            >
              {`${agentData?.firstName || ''} ${agentData?.lastName || ''}`}
            </Typography>
            <Typography variant="body3" textAlign="center">
              {`${agentData?.bio || ''}`}
            </Typography>
            <Rating
              readOnly
              value={agentData?.rating || 0}
              sx={{ fontSize: 20 }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LiveAgentCard;

const skeletonUserCard = (
  <Box
    sx={{
      width: '60%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2rem',
      p: { xs: '22px 16px', lg: '0' },
    }}
  >
    <Skeleton
      variant="circular"
      sx={{
        width: '100%',
        height: 'auto',
        aspectRatio: '1',
      }}
    />
    <Skeleton
      variant="rectangular"
      sx={{
        width: '100%',
        height: '1.4rem',
      }}
    />

    <Skeleton
      variant="rectangular"
      sx={{
        width: '70%',
        height: '0.5rem',
      }}
    />
  </Box>
);
