import { Avatar, Box, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import AddImgsIcon from '../../../../components/Icons/AddImgsIcon';
import AddVideosIcon from '../../../../components/Icons/AddVideosIcon';
import { useModalManager } from '../../../../hooks/useModalManager';
import CreatePostModal from '../modals/CreatePostModal';
import { selectAuthData } from '../../../../store/selectors/authSelector';

enum MyPostModals {
  CREATE_POST_MODAL = 'CREATE_POST_MODAL',
}

export enum PostType {
  NONE = 'NONE',
  PHOTO = 'PHOTO',
  VIDEO = 'VIDEO',
}

interface NewPublicationTriggerProps {
  refetchPosts: () => void;
}

const NewPublicationTrigger = ({
  refetchPosts,
}: NewPublicationTriggerProps) => {
  const { user: myData } = useSelector(selectAuthData);

  const { currentModal, toggleModal } = useModalManager();
  const [selectedPostType, setSelectedPostType] = useState<PostType>(
    PostType.NONE
  );

  return (
    <>
      {currentModal === MyPostModals.CREATE_POST_MODAL && (
        <CreatePostModal
          refetchPosts={refetchPosts}
          isOpen
          onClose={() => {
            toggleModal(MyPostModals.CREATE_POST_MODAL);
          }}
          selectedPostType={selectedPostType}
        />
      )}
      <Box
        className="new-publication"
        sx={{
          display: 'flex',
          gap: '1rem',
          padding: '0.75rem 1rem',
          backgroundColor: '#FFFFFF',
          height: '3.75rem',
        }}
      >
        <Box display="flex" alignItems="center">
          <Avatar
            alt="Profile"
            src={
              myData && myData?.optimizedUserAvatarBaseUrl
                ? `data:image/jpeg;base64,${myData.optimizedUserAvatarBaseUrl}`
                : ''
            }
            sx={{ width: '2rem', height: '2rem' }}
          />
        </Box>
        <Box
          className="new-post-entry-box"
          display="flex"
          alignItems="center"
          flex="1"
        >
          <Box className="new-post-entry" flex="1">
            <TextField
              onClick={() => {
                setSelectedPostType(PostType.NONE);
                toggleModal(MyPostModals.CREATE_POST_MODAL);
              }}
              fullWidth
              placeholder="New Post..."
              sx={{
                padding: '0',
                '& .MuiInputBase-root': {
                  backgroundColor: '#FFFFFF',
                  height: '1.25rem',
                  '& .MuiInputBase-input': {
                    padding: '0',
                    backgroundColor: 'unset',
                  },
                },
              }}
              inputProps={{
                style: {
                  fontSize: 14,
                  fontWeight: 400,
                  lineHeight: 'normal',
                },
              }}
              rows={1}
              maxRows={1}
            />
          </Box>
          <Box
            className="new-post-actions"
            display="flex"
            alignItems="center"
            gap="18px"
            sx={{
              '& > button': {
                minHeight: '25px',
                minWidth: '25px',
                height: '35px',
                width: '35px',
              },
            }}
          >
            <IconButton
              onClick={() => {
                setSelectedPostType(PostType.PHOTO);
                toggleModal(MyPostModals.CREATE_POST_MODAL);
              }}
            >
              <AddImgsIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setSelectedPostType(PostType.VIDEO);
                toggleModal(MyPostModals.CREATE_POST_MODAL);
              }}
            >
              <AddVideosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default NewPublicationTrigger;
