import { Box, Typography } from '@mui/material';
import ArticleLayout from '../../../layouts/ArticleLayout';

const NoContent = () => {
  return (
    <ArticleLayout
      display="flex"
      flexDirection="column"
      rowGap={{ xs: '16px', xl: '22px' }}
      height={633}
    >
      <Box
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h2">There is no content here yet.</Typography>
      </Box>
    </ArticleLayout>
  );
};

export default NoContent;
