import {
  Box,
  BoxProps,
  SxProps,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ListSort, { IListSortPops } from './ListSort';
import ListSearch from './ListSearch';
import { useThemeContext } from '../../theme/ThemeContextProvider';
import DefaultSortIcon from './DefaultSortIcon';

interface IListToolBarProps extends IListSortPops, BoxProps {
  subTitle: string;
  isSortShown?: boolean;
  isSearchShown?: boolean;
  needDefaultSortIcon?: boolean;
  listToolBarActionsSx?: SxProps;
}

const ListToolBar: React.FC<IListToolBarProps> = ({
  subTitle,
  isSortShown = true,
  isSearchShown = true,
  sortMenuItems,
  sortMenuLabel,
  needDefaultSortIcon = false,
  listToolBarActionsSx,
  ...props
}) => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Box
      display={'flex'}
      flexDirection={matches ? 'row' : 'column'}
      alignItems={matches ? 'center' : 'start'}
      justifyContent={'space-between'}
      flexWrap={'wrap'}
      gap={!matches ? '22px' : 0}
      {...props}
    >
      <Typography
        variant="h3"
        color={'text.primary'}
        sx={{ fontSize: '20px', fontWeight: 600 }}
        mr={'10px'}
      >
        {subTitle}
      </Typography>
      <Box
        className="list-toolbar-actions"
        display={'flex'}
        sx={{
          gap: { xs: '8px', md: '16px', lg: '36px' },
          width: '100%',
          ...listToolBarActionsSx,
        }}
        alignItems={'center'}
        flexWrap={'wrap'}
        flexDirection={matches ? 'row' : 'row-reverse'}
        maxWidth={matches ? '530px' : '100%'}
      >
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          {isSortShown && (
            <ListSort
              sortMenuItems={sortMenuItems}
              sortMenuLabel={sortMenuLabel}
              queryParam={props?.queryParam}
            />
          )}

          {isSortShown && needDefaultSortIcon && <DefaultSortIcon />}
        </Box>
        {isSearchShown && <ListSearch />}
      </Box>
    </Box>
  );
};

export default ListToolBar;
