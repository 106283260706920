import { SvgIcon, SvgIconProps } from '@mui/material';

interface SendIconProps extends SvgIconProps {
  stroke?: string;
}

const SendIcon = ({ sx, stroke, ...rest }: SendIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 16, height: 16, fill: 'none', ...sx }}
      viewBox="0 0 18 18"
      {...rest}
    >
      <path
        d="M3.90503 3.57141L17.8336 10L3.90503 16.4286L5.75026 10L3.90503 3.57141Z"
        stroke={stroke ?? '#727F93'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
      <path
        d="M5.75024 10H17.8336"
        stroke={stroke ?? '#727F93'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default SendIcon;
