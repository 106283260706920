import { Box, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import StatLabel from '../StatLabel';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface IAthleteDistributionProps {
  data: { value: string; candidates: number; percentage: string }[];
  totalCandidates: number;
}

const colorPalette = [
  '#28BA2E',
  '#28BA91',
  '#289ABA',
  '#73A4D2',
  '#7386D2',
  '#9673D2',
  '#B473D2',
  '#DDE1E8',
];

const AthleteDistribution: React.FC<IAthleteDistributionProps> = ({
  data,
  totalCandidates,
}) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      display="flex"
      flexDirection="column"
      padding={isSmallScreen ? '10px 16px 16px 16px' : '22px 36px'}
      gap={isSmallScreen ? '22px' : '28px'}
      width={'100%'}
    >
      <Typography sx={{ fontWeight: 600, fontSize: '18px', color: '#142237' }}>
        {totalCandidates} Candidates
      </Typography>

      <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
        {data.map((item, index) => {
          const widthPercentage = (item.candidates / totalCandidates) * 100;
          const color = colorPalette[index % colorPalette.length];
          return (
            <Box
              key={index}
              height="12px"
              width={`${widthPercentage}%`}
              bgcolor={color}
            />
          );
        })}
      </Box>

      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={isSmallScreen ? '16px' : '22px'}
        flexWrap="wrap"
      >
        {data.map((item, index) => {
          const color = colorPalette[index % colorPalette.length];
          return (
            <StatLabel
              key={index}
              text={`${item.value} (${item.percentage})`}
              color={color}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default AthleteDistribution;
