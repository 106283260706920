import {
  Avatar,
  Box,
  BoxProps,
  Grid,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { ComponentType, ReactNode } from 'react';

export enum UserItemStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

interface ActionsProps {
  userInfo: UserInfo;
  userItemStatus: UserItemStatus;
}

export interface UserInfo {
  id: string;
  avatarSrc: string;
  firstName: string;
  lastName: string;
  sportNames: string[];
}

interface UserListItemProps extends BoxProps {
  userInfo: UserInfo;
  userItemStatus?: UserItemStatus;
  extraData?: ReactNode | null;
  Actions?: ComponentType<ActionsProps>;
}

const UserListItem = ({
  userInfo,
  Actions,
  extraData = null,
  userItemStatus = UserItemStatus.ACTIVE,
  ...rest
}: UserListItemProps) => {
  const theme = useTheme();
  const cssVars = createVars(theme, userItemStatus);

  return (
    <ListItemBox
      className="user-list-item"
      component="li"
      style={cssVars}
      {...rest}
    >
      <Grid
        className="user-list-item__layout-container"
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item container width="unset" alignItems="center" columnGap="16px">
          <Grid className="layout-container__user-avatar" item>
            <Avatar
              alt={'alt'}
              src={userInfo.avatarSrc}
              sx={{ width: '64px', height: '64px' }}
            />
          </Grid>
          <Grid className="layout-container__user-info" item>
            <Typography variant="body1">
              {userInfo.firstName} {userInfo.lastName}
            </Typography>
            <Typography variant="body3" marginTop="8px" display="inline-flex">
              {userInfo.sportNames.join(', ')}
            </Typography>
            {extraData}
          </Grid>
        </Grid>
        <Grid
          className="layout-container__actions"
          item
          alignItems="center"
          display="flex"
          columnGap="16px"
        >
          {Actions && (
            <Actions userInfo={userInfo} userItemStatus={userItemStatus} />
          )}
        </Grid>
      </Grid>
    </ListItemBox>
  );
};
export default UserListItem;

const ListItemBox = styled(Box)<BoxProps>(({ theme }) => ({
  padding: '22px 36px',
  borderTop: `1px solid ${theme.palette.background.lightDark}`,
  backgroundColor: 'var(--bgcolor)',
}));

const createVars = (theme: any, type: UserItemStatus) => {
  switch (type) {
    case UserItemStatus.ACTIVE:
      return {
        '--bgcolor': 'unset',
      } as React.CSSProperties;
    case UserItemStatus.INACTIVE:
      return {
        '--bgcolor': theme.palette.background.light,
      } as React.CSSProperties;
    default:
      return {
        '--bgcolor': 'unset',
      } as React.CSSProperties;
  }
};
