import {
  endOfMonth,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfMonth,
} from 'date-fns';

export const formatDate = (date: Date) => {
  const optionsDate = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  } as const;
  const optionsTime = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  } as const;

  const formatterDate = new Intl.DateTimeFormat('en-US', optionsDate);
  const formatterTime = new Intl.DateTimeFormat('en-US', optionsTime);

  const dateString = formatterDate.format(date);
  const timeString = formatterTime.format(date);

  // Combine them with a space
  return `${dateString}\u00A0\u00A0\u00A0${timeString}`;
};

export function formatDateWithOffset(date: Date) {
  const offset = '+03:00';
  return new Date(`${date.toISOString().slice(0, -1)}${offset}`);
}

export const startDateFormatted = formatDateWithOffset(
  setMilliseconds(
    setSeconds(setMinutes(setHours(startOfMonth(new Date()), 11), 29), 0),
    0
  )
);

export const endDateFormatted = formatDateWithOffset(
  setMilliseconds(
    setSeconds(setMinutes(setHours(endOfMonth(new Date()), 23), 59), 59),
    0
  )
);

export const getFullMonthNameAndDay = (date: Date | string) => {
  try {
    if (date instanceof Date) {
      if (isNaN(date as unknown as number))
        throw new Error('Invalid Date object');
    } else if (typeof date === 'string') {
      const parsedDate = new Date(date);
      if (isNaN(parsedDate as unknown as number)) {
        throw new Error('Invalid date string');
      }
      date = parsedDate;
    } else {
      throw new Error('Input must be a Date object or a valid date string');
    }

    const options = { month: 'long', day: 'numeric' } as const;
    const formattedDate = date.toLocaleDateString('en-US', options);

    const day = date.getDate();
    let suffix = 'th';
    if (day % 10 === 1 && day !== 11) suffix = 'st';
    else if (day % 10 === 2 && day !== 12) suffix = 'nd';
    else if (day % 10 === 3 && day !== 13) suffix = 'rd';

    return formattedDate.replace(/\d+/, `${day}${suffix}`);
  } catch (e) {
    console.log(e);

    return 'No information';
  }
};
