import React, { FC } from 'react';
import { Avatar, Box, SxProps, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../interfaces/user.interface';

interface SearchBarItemProps {
  user?: IUser;
  onClose: () => void;
  sx?: SxProps;
}

const SearchBarItem: FC<SearchBarItemProps> = ({ user, onClose, sx }) => {
  const navigate = useNavigate();

  const navigateToSearchPage = () => {
    onClose();
    navigate(`/${user?.id}`);
  };

  const avatarUrl = user?.optimizedUserAvatarBaseUrl
    ? `data:image/jpeg;base64,${user.optimizedUserAvatarBaseUrl}`
    : '';

  return (
    <Box
      onClick={navigateToSearchPage}
      sx={{
        ...searchBarItemStyles,
        ...sx,
      }}
    >
      <Avatar
        alt="Profile avatar"
        src={avatarUrl}
        sx={{ width: '2rem', height: '2rem' }}
      />
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography variant={'body3'} className={'name'}>
          {`${user?.firstName} ${user?.lastName}`}
        </Typography>
        <Typography
          variant={'body2'}
          sx={{
            fontSize: '0.625rem',
            lineHeight: '140%',
          }}
        >
          {user?.sports[0]?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default SearchBarItem;

const searchBarItemStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
  padding: '0.375rem 0.75rem',
  cursor: 'pointer',
  '&:hover .name': {
    color: 'primary.main',
  },
};
