import { Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../../../components/MUIComponents/CustomButton';
import SnackbarCustom from '../../../../../../components/MUIComponents/SnackbarCustom';
import { ButtonTypeEnum } from '../../../../../../enums/button-type-enum';
import { SnackbarSeverityEnum } from '../../../../../../enums/snackbar-severity-enum';
import { useCreatePartnershipMutation } from '../../../../../../store/apiSlice/partnershipApi';
import { errorHelper } from '../../../../../../utils/helper/error-helper';
import BaseActions from '../../../../shared-components/user-list/actions/BaseActions';
import {
  UserInfo,
  UserItemStatus,
} from '../../../components/user-list/UserListItem';

interface ActionButtonsProps {
  userInfo: UserInfo;
  userItemStatus: UserItemStatus;
}

const ActionButtons = ({ userInfo }: ActionButtonsProps) => {
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const [createPartnership, { isLoading }] = useCreatePartnershipMutation();

  const handleAcceptBtn = (userId: string) => async () => {
    try {
      const res = await createPartnership({ athleteId: userId });

      if (res.error) {
        throw new Error('Oops! Something went wrong...');
      }

      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarMessage('Applicant successfully accepted.');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarMessage(errorHelper(error));
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Stack direction="row" columnGap="16px">
        <CustomButton
          onClick={handleAcceptBtn(userInfo.id)}
          variantType={ButtonTypeEnum.PRIMARY}
          sx={{ p: '12px 16px' }}
          disabled={isLoading}
        >
          accept
        </CustomButton>
        <CustomButton
          onClick={() => navigate('/quiz-results', { state: { userInfo } })}
          variantType={ButtonTypeEnum.SECONDARY}
          sx={{ p: '12px 16px' }}
        >
          quiz results
        </CustomButton>
      </Stack>
      <BaseActions userInfo={userInfo} />
      <SnackbarCustom
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default ActionButtons;
