import * as yup from 'yup';
import { CancelIntroductionReasonEnum } from '../enums/cancel-introduction-reason-enum';

export const requestIntroductionSchema = yup.object().shape({
  reasonToDeny: yup
    .mixed<CancelIntroductionReasonEnum>()
    .oneOf(
      Object.values(CancelIntroductionReasonEnum),
      'Please select a valid reason'
    )
    .optional(),
});
