import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { ElementIdEnum } from '../../../enums/element-id-enum';
import {
  isSupportedIdConfirmationFileHelper,
  isSupportedIdConfirmationVideoFileHelper,
} from '../../../utils/helper/file-validation-helper';
import UploadedFileIconElement from '../../Auth/Elements/UploadedFileIconElement';
import TrashIcon from '../../Icons/TrashIcon';
import DragAndDropBox from '../../MUIComponents/DragAndDropBox/DragAndDropBox';
import IconRoundBackground from '../../MUIComponents/IconRoundBackground';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';

interface LoadedItem {
  title: string;
  content: string;
}

interface Media {
  index: number;
  data: LoadedItem | null;
  isLoading: boolean;
}

interface AddMediaProps {
  fileInputId: ElementIdEnum;
  text: string;
  icon: React.ReactNode;
  isVideoInput?: boolean;
  setCentralFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<SnackbarSeverityEnum>
  >;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddMedia = ({
  fileInputId,
  text,
  icon,
  isVideoInput = false,
  setCentralFiles,
  setSnackbarMessage,
  setSnackbarSeverity,
  setSnackbarOpen,
}: AddMediaProps) => {
  const [images, setImages] = useState<Media[]>([]);
  const [videos, setVideos] = useState<Media[]>([]);
  const [dragOver, setDragOver] = useState(false);

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const newFiles = Array.from(event.target.files);

        processValidFiles(
          newFiles,
          isVideoInput,
          setVideos,
          setImages,
          setCentralFiles,
          setSnackbarMessage,
          setSnackbarSeverity,
          setSnackbarOpen
        );
      }
    },
    [isVideoInput, setCentralFiles]
  );

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);

    if (event.dataTransfer.files) {
      const newFiles = Array.from(event.dataTransfer.files);

      processValidFiles(
        newFiles,
        isVideoInput,
        setVideos,
        setImages,
        setCentralFiles,
        setSnackbarMessage,
        setSnackbarSeverity,
        setSnackbarOpen
      );
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById(
      fileInputId
    ) as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleRemove = useCallback(
    (item: LoadedItem, isVideo = false) =>
      () => {
        if (isVideo) {
          setVideos(prev =>
            prev.filter(video => video.data?.title !== item.title)
          );
        } else {
          setImages(prev => prev.filter(img => img.data?.title !== item.title));
        }
        setCentralFiles &&
          setCentralFiles(prev =>
            prev.filter(file => file.name !== item.title)
          );
      },
    [setCentralFiles]
  );

  return (
    <>
      <DragAndDropBox
        handleDragOver={handleDragOver}
        handleDragLeave={handleDragLeave}
        handleDrop={handleDrop}
        handleFileChange={handleFileChange}
        triggerFileInput={triggerFileInput}
        fileInputId={fileInputId}
        text={text}
        icon={<UploadedFileIconElement>{icon}</UploadedFileIconElement>}
        isVideoInput={isVideoInput}
      />
      {!!images.length && (
        <Box sx={{ textAlign: 'center' }} mt="36px">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              flexWrap: 'wrap',
              gap: '0.5rem',
            }}
          >
            {images.map((img, index) =>
              img.data ? (
                <Box
                  key={img.data?.title + img.index}
                  className="img-container"
                  sx={{
                    position: 'relative',
                    maxWidth: 'calc(50% - 0.5rem)',
                  }}
                >
                  <DeleteButton handleItemRemove={handleRemove(img.data)} />
                  <img
                    src={img.data?.content}
                    alt="Preview"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </Box>
              ) : img.isLoading ? (
                <Box
                  className="img-container"
                  position="relative"
                  key={index}
                  sx={{ flex: '0 0 50%' }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Typography variant="body1" key={img.index}>
                  Error loading media
                </Typography>
              )
            )}
          </Box>
        </Box>
      )}
      {!!videos.length && (
        <Box sx={{ textAlign: 'center' }} mt="36px">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              flexWrap: 'wrap',
              gap: '0.5rem',
            }}
          >
            {videos.map((video, index) =>
              video.data ? (
                <Box
                  key={video.data.title + video.index}
                  className="video-container"
                  sx={{
                    position: 'relative',
                    maxWidth: 'calc(50% - 0.5rem)',
                  }}
                >
                  <DeleteButton
                    handleItemRemove={handleRemove(video.data, true)}
                  />
                  <video
                    autoPlay
                    muted
                    style={{ maxWidth: '100%', height: 'auto' }}
                  >
                    <source src={video.data.content} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </Box>
              ) : video.isLoading ? (
                <Box
                  className="video-container"
                  position="relative"
                  key={index}
                  sx={{
                    maxWidth: 'calc(50% - 0.5rem)',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Typography variant="body1" key={video.index}>
                  Error loading media
                </Typography>
              )
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default AddMedia;

// to do: move AddMedia to src/components/shared/
// move functions and interface below to separate files

interface DeleteButtonProps {
  handleItemRemove: () => void;
}

const DeleteButton = ({ handleItemRemove }: DeleteButtonProps) => (
  <IconButton
    onClick={handleItemRemove}
    sx={{
      width: 'fit-content',
      height: 'fit-content',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 1,
    }}
  >
    <IconRoundBackground
      width={50}
      height={50}
      backgroundColor="secondary.main"
    >
      <TrashIcon sx={{ mb: '4px' }} />
    </IconRoundBackground>
  </IconButton>
);

const getFileType = (input: string): string | null => {
  const match = input.match(/data:(.*?)[/]/);
  return match ? match[1] : null;
};

const isDuplicate = (file: File, existingFiles: File[]) =>
  existingFiles.some(f => f.name === file.name && f.size === file.size);

const processValidFiles = (
  files: File[],
  isVideoInput: boolean,
  setVideos: React.Dispatch<React.SetStateAction<Media[]>>,
  setImages: React.Dispatch<React.SetStateAction<Media[]>>,
  setCentralFiles: React.Dispatch<React.SetStateAction<File[]>> | undefined,
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>,
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<SnackbarSeverityEnum>
  >,
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const validFiles = files.filter(file =>
    !isVideoInput
      ? isSupportedIdConfirmationFileHelper(file)
      : isSupportedIdConfirmationVideoFileHelper(file)
  );
  const invalidFiles = files.filter(file =>
    !isVideoInput
      ? !isSupportedIdConfirmationFileHelper(file)
      : !isSupportedIdConfirmationVideoFileHelper(file)
  );

  if (validFiles.length + (setCentralFiles?.length || 0) > 10) {
    setSnackbarMessage('You can only upload up to 10 files.');
    setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    setSnackbarOpen(true);
    return;
  }

  if (invalidFiles.length > 0) {
    setSnackbarMessage(
      'Invalid file type. Only images (JPEG, PNG, WebP or JPG) are accepted.'
    );
    setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    setSnackbarOpen(true);
    return;
  }

  setCentralFiles &&
    setCentralFiles(prev => {
      const uniqueFiles = validFiles.filter(file => !isDuplicate(file, prev));
      return [...prev, ...uniqueFiles];
    });

  files.forEach(file => {
    const reader = new FileReader();
    let imgIndex = 0;
    let vidIndex = 0;
    reader.onloadstart = () => {
      const updateMediaState = (isVideo: boolean, index: number) => {
        const mediaSetter = isVideo ? setVideos : setImages;

        mediaSetter(prev => {
          const tempArr = [...prev];
          vidIndex = tempArr.length ? tempArr.length : 0;
          imgIndex = tempArr.length ? tempArr.length : 0;
          tempArr.push({
            index,
            data: null,
            isLoading: true,
          });

          return tempArr;
        });
      };
      updateMediaState(isVideoInput, isVideoInput ? vidIndex : imgIndex);
    };
    reader.onload = () => {
      if (typeof reader?.result === 'string') {
        const updateMediaState = (
          mediaType: string,
          file: File,
          result: string,
          index: number
        ) => {
          let mediaSetter: React.Dispatch<
            React.SetStateAction<Media[]>
          > | null = null;

          if (mediaType === 'image') {
            mediaSetter = setImages;
          }
          if (mediaType === 'video') {
            mediaSetter = setVideos;
          }

          if (!mediaSetter) {
            return;
          }

          mediaSetter(prev => {
            const tempArr = [...prev];
            tempArr[index] = {
              index,
              data: {
                title: file.name,
                content: result,
              },
              isLoading: false,
            };
            return tempArr;
          });
        };

        const fileType = getFileType(reader.result);
        fileType &&
          updateMediaState(
            fileType,
            file,
            reader.result,
            isVideoInput ? vidIndex : imgIndex
          );
      }
    };
    reader.readAsDataURL(file);
  });
};
