import { Box, BoxProps, Grid, useMediaQuery, useTheme } from '@mui/material';

interface CareerDevelopmentProfileProps extends BoxProps {
  cardData: JSX.Element;
}

const ProfileCardContainer = ({
  cardData,
  ...rest
}: CareerDevelopmentProfileProps) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box position="relative" width="100%" {...rest}>
      <Box
        sx={
          !mobileView
            ? {
                color: theme.palette.text.primary,
                width: { lg: 236, xl: 314 },
                height: 'fit-content',
                padding: { xl: '36px 16px' },
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'white',
              }
            : {}
        }
      >
        <Grid
          container
          display="flex"
          flexDirection="column"
          rowGap={{ lg: '16px', xl: '22px' }}
          sx={{
            backgroundColor: '#F6F7F8',
            '& .MuiGrid-item': {
              width: '100%',
            },
          }}
        >
          <Grid item sx={{ bgcolor: theme.palette.background.white }}>
            {cardData}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ProfileCardContainer;
