import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useNavigate, useOutlet } from 'react-router-dom';
import ProfileLeftBar from './components/leftbar/ProfileLeftBar';
import ProfileBackground from '../shared-components/ProfileBackground';
import ProfileTabs from './components/ProfileTabs';
import { createPortal } from 'react-dom';
import {
  useCheckAccessAndBlockedQuery,
  useCheckHasCommonFriendQuery,
} from '../../../store/apiSlice/friendApi';
import { useCurrentPageUserId } from '../../../hooks/useCurrentPageUserId';

interface PersonalInfoPageProps {
  children?: ReactNode;
}

const PersonalInfoPage = ({ children }: PersonalInfoPageProps) => {
  const { userId, isOwnPage } = useCurrentPageUserId();
  const outlet = useOutlet();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const navigate = useNavigate();

  const profileBanner = document.getElementById('profile-banner');

  const {
    data: isHaveAccessAndBlockedData,
    isLoading: isHaveAccessAndBlockedDataLoading,
  } = useCheckAccessAndBlockedQuery(userId || '', { skip: !userId });

  const {
    data: checkHasCommonFriendData,
    isLoading: isCheckHasCommonFriendDataLoading,
  } = useCheckHasCommonFriendQuery(userId || '', {
    skip: !userId || isOwnPage,
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="fit-content"
      sx={{
        width: '100%',
        marginTop: {
          xs: '-20px',
          md: '-24px',
          lg: '-28px',
          xl: '-48px',
        },
        position: 'static',
      }}
    >
      {profileBanner && createPortal(<ProfileBackground />, profileBanner)}
      <Box
        display="flex"
        color="#DDE1E8"
        maxWidth="1440px"
        width="100%"
        alignSelf="center"
        flexDirection={mobileView ? 'column' : 'row'}
      >
        <ProfileLeftBar
          navigatePhotos={() => navigate(`?tab=${4}`)}
          navigateVideos={() => navigate(`?tab=${5}`)}
          hasAccess={isHaveAccessAndBlockedData?.data?.hasAccess}
          isBlocked={isHaveAccessAndBlockedData?.data?.isBlocked}
          hasMutualFriends={checkHasCommonFriendData?.data}
        />
        <Box
          className="profile-info-page-main-content"
          flex={2}
          display="flex"
          flexDirection="column"
          minWidth={0}
        >
          {outlet || (
            <ProfileTabs
              hasAccess={isHaveAccessAndBlockedData?.data?.hasAccess}
              isBlocked={isHaveAccessAndBlockedData?.data?.isBlocked}
              isAccessLoading={isHaveAccessAndBlockedDataLoading}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PersonalInfoPage;
