import { Divider, Grid, Stack, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { useGetMutualFriendsQuery } from '../../../../store/apiSlice/friendApi';
import { useCreateIntroductionMutation } from '../../../../store/apiSlice/friendIntroductionApi';
import SearchContacts from '../../../ChatPage/components/NewPersonalChat/SearchContacts';
import Box from '@mui/material/Box';
import SkeletonList from '../../../../components/MUIComponents/SkeletonList';
import InviteFriendCard from '../../../ChatPage/components/GroupInfo/InviteFriendCard';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { IInviteFriendData } from '../../../../interfaces/friend.interface';
import { ICreateIntroductionRequest } from '../../../../interfaces/friend-introduction.interface';

const LIMIT = 10;

interface RequestIntroductionModalProps {
  isOpen?: boolean;
  onClose: () => void;
  userFirstName?: string | null;
  userLastName?: string | null;
}

const RequestIntroductionModal = ({
  onClose,
  isOpen = true,
  userFirstName,
  userLastName,
}: RequestIntroductionModalProps) => {
  const { id: userToWhomRepresentedId } = useParams();
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [page, setPage] = useState(1);
  const [friends, setFriends] = useState<IInviteFriendData[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedFriend, setSelectedFriend] =
    useState<IInviteFriendData | null>(null);
  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const { data: mutualFriendData, isFetching } = useGetMutualFriendsQuery(
    {
      representedFriendId: userToWhomRepresentedId || '',
      filters: { page, limit: LIMIT, search: debouncedSearch },
    },
    { skip: !userToWhomRepresentedId }
  );

  const [createIntroduction, { isLoading: isCreateIntroductionLoading }] =
    useCreateIntroductionMutation();

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchValue);
      setPage(1);
    }, 500);
    return () => clearTimeout(handler);
  }, [searchValue, userToWhomRepresentedId]);

  useEffect(() => {
    if (mutualFriendData && mutualFriendData.friends) {
      const newFriends: IInviteFriendData[] = mutualFriendData.friends.map(
        friend => ({
          id: friend.userId,
          firstName: friend.firstName ?? '',
          lastName: friend.lastName ?? '',
          thumbnail: friend.avatarUrl || '',
          sports: friend?.sports ?? [],
        })
      );

      setFriends(prevFriends => {
        if (page === 1) {
          return Array.from(
            new Map(newFriends.map(friend => [friend.id, friend])).values()
          );
        }

        return Array.from(
          new Map(
            [...prevFriends, ...newFriends].map(friend => [friend.id, friend])
          ).values()
        );
      });

      if (mutualFriendData.pagination) {
        setHasMore(page < mutualFriendData.pagination.totalPages);
      }
    }
  }, [mutualFriendData, page]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const loadMoreFriends = () => {
    if (!isFetching && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = async () => {
    try {
      if (!selectedFriend) {
        setSnackbarOpen(true);
        setSnackbarMessage('Please select a user who will represent you.');
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        return;
      }

      if (!userToWhomRepresentedId) {
        setSnackbarOpen(true);
        setSnackbarMessage(
          'Please select a user to whom you will be introduced.'
        );
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        return;
      }

      const requestIntroduction: ICreateIntroductionRequest = {
        commonFriendId: selectedFriend.id,
        userToWhomRepresentedId: userToWhomRepresentedId,
      };

      await createIntroduction(requestIntroduction).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Introduction successfully sent!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    }
  };

  const handleSelectFriend = (friend: IInviteFriendData) => () => {
    setSelectedFriend(friend);
  };

  const modalTitle = useMemo(() => {
    return (
      <>
        <Typography variant="h3" textAlign="left" mb={'22px'}>
          Get introduced to {userFirstName} {userLastName}
        </Typography>
        <Divider sx={{ m: { xs: '0 -12px', lg: '0 -36px' } }} />
      </>
    );
  }, []);

  const modalFooter = useMemo(
    () => (
      <Box mt={3}>
        <Grid
          container
          justifyContent="space-between"
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          gap={{ xs: '10px', lg: '0px' }}
          width={'100%'}
        >
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="outlined"
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              onClick={onSubmit}
              disabled={isCreateIntroductionLoading || !selectedFriend}
            >
              send request
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    ),
    [onSubmit, isCreateIntroductionLoading, onClose, selectedFriend]
  );

  return (
    <BaseModal
      header={{ component: modalTitle }}
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: modalFooter }}
      styleEscapeBtnStylesBelow={{ top: 26 }}
      disableEscapeButton={true}
    >
      <Stack>
        <SearchContacts onSearch={handleSearchChange} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1.75rem 1rem 0 1rem',
          }}
        >
          <Typography variant="caption" sx={{ textTransform: 'uppercase' }}>
            Mutual connections
          </Typography>
        </Box>

        {isFetching ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.75rem',
              padding: '1.75rem 1rem',
            }}
          >
            <SkeletonList
              count={3}
              sx={{
                minHeight: '60px',
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.75rem',
              padding: '1.75rem 1rem',
            }}
          >
            <Box gap={'0.625rem'} display={'flex'} flexDirection={'column'}>
              {friends.length > 0
                ? friends.map((friend, index) => (
                    <InviteFriendCard
                      key={index}
                      friend={friend}
                      selectedFriends={selectedFriend ? [selectedFriend] : []}
                      handleSelectFriend={handleSelectFriend(friend)}
                    />
                  ))
                : !isFetching && (
                    <Typography textAlign="center" color="text.secondary">
                      Oops, unfortunately, you have no mutual friends.
                    </Typography>
                  )}
            </Box>
            {hasMore && friends.length > 0 && (
              <CustomButton
                sx={{ width: '100%', mt: 2 }}
                fullWidth
                variantType={ButtonTypeEnum.SECONDARY}
                onClick={loadMoreFriends}
                disabled={isFetching}
              >
                Load More
              </CustomButton>
            )}
          </Box>
        )}
      </Stack>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default RequestIntroductionModal;
