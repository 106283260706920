export enum CanceledReasonEnum {
  NO_LONGER_INTERESTED = 'NO_LONGER_INTERESTED',
  ACCEPTED_ANOTHER_JOB_OFFER = 'ACCEPTED_ANOTHER_JOB_OFFER',
  DECIDED_THE_ROLE_ISNT_A_GOOD_FIT = 'DECIDED_THE_ROLE_ISNT_A_GOOD_FIT',
  REALIZED_I_DONT_MEET_THE_JOB_REQUIREMENTS = 'REALIZED_I_DONT_MEET_THE_JOB_REQUIREMENTS',
  FOUND_ERRORS_IN_MY_APPLICATION = 'FOUND_ERRORS_IN_MY_APPLICATION',
  OTHER = 'OTHER',
}

export enum EndAssistanceEnum {
  NO_LONGER_INTERESTED = 'No longer interested in finding a job through FreeAgent (please tell us why)',
  FOUND_JOB_ELSEWHERE = 'Found a job elsewhere (please tell us where)',
  OTHER = 'Other: provide written reason',
}
