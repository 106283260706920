import {
  Button,
  ButtonProps,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

interface ButtonWithIconProps extends ButtonProps {
  width?: number | string;
  height?: number | string;
  text?: string;
  icon?: React.ReactNode;
  selected?: boolean;
  children?: React.ReactNode;
}

const ButtonWithIcon = ({
  sx,
  height,
  width,
  text,
  icon,
  selected,
  children,
  ...rest
}: ButtonWithIconProps) => {
  const theme = useTheme();
  const color = selected
    ? `${theme.palette.button.primaryText} !important`
    : theme.palette.text.secondary;

  return (
    <Button
      variant="contained"
      color="secondary2"
      sx={{
        padding: '0.75rem 1rem',
        borderRadius: 0,
        bgcolor: selected ? `${theme.palette.secondary.main} !important` : '',
        ...sx,
      }}
      {...rest}
    >
      <Stack
        height={height}
        width={width}
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing="6px"
      >
        {icon}
        <Typography variant="badge2" color={color}>
          {text || children}
        </Typography>
      </Stack>
    </Button>
  );
};

export default ButtonWithIcon;
