import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import BaseModal from '../../../../../components/MUIComponents/BaseModal';
import Box from '@mui/material/Box';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import SnackbarCustom from '../../../../../components/MUIComponents/SnackbarCustom';
import { errorHelper } from '../../../../../utils/helper/error-helper';
import TextField from '../../../../../components/MUIComponents/TextField';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EndAssistanceEnum } from '../../../../../enums/cancel-reason-type-enum';
import { EndAssistantSchema } from '../../../../../validation/agent-validation';
import { useEndAssistanceMutation } from '../../../../../store/apiSlice/partnershipApi';
import { EndPartnershipAssistanceReq } from '../../../../../interfaces/partnership.interface';
import Input from '../../../../../components/MUIComponents/Input';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';

interface EndAssistantFormInputs {
  description?: string | undefined;
  reason?: undefined | EndAssistanceEnum;
}
interface EndAssistantModalProps {
  agentId: string;
  isOpen?: boolean;
  onClose: () => void;
  openEndAssistantCompleteModal: () => void;
}

const EndAssistantModal = ({
  agentId,
  onClose,
  isOpen = false,
  openEndAssistantCompleteModal,
}: EndAssistantModalProps) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [endAssistance, { isLoading: isEndAssistanceLoading }] =
    useEndAssistanceMutation();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<EndAssistantFormInputs>({
    resolver: yupResolver(EndAssistantSchema),
  });

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  useEffect(() => {
    reset();
  }, [isOpen]);
  const onSubmit = async (data: EndAssistantFormInputs) => {
    try {
      if (!data.reason) {
        setSnackbarOpen(true);
        setSnackbarMessage('Please select a reason');
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        return;
      }

      const cancelData: EndPartnershipAssistanceReq = {
        reason: data.reason as EndAssistanceEnum,
      };

      if (data?.description) {
        cancelData.description = data.description;
      }

      await endAssistance({ agentId, data: cancelData }).unwrap();

      reset();
      openEndAssistantCompleteModal();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseModal = () => {
    reset();
    onClose();
  };

  const modalTitle = useMemo(() => {
    return (
      <>
        <Typography variant="h3" textAlign="left" mb={'22px'}>
          Time to pass the torch?
        </Typography>
        <Divider sx={{ m: { xs: '0 -12px', lg: '0 -36px' } }} />
      </>
    );
  }, []);

  const modalFooter = useMemo(
    () => (
      <Box mt={3}>
        <Grid
          container
          justifyContent="space-between"
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          gap={{ xs: '10px', lg: '0px' }}
          width={'100%'}
        >
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="outlined"
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={handleCloseModal}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isEndAssistanceLoading}
            >
              send request
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    ),
    [onSubmit]
  );

  return (
    <BaseModal
      header={{ component: modalTitle }}
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: modalFooter }}
      disableEscapeButton={true}
      styleEscapeBtnStylesAbove={{ width: { lg: '600px' } }}
      styleEscapeBtnStylesBelow={{ width: { lg: '600px' }, top: 26 }}
    >
      <Stack spacing="36px">
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={'1.75rem'}
          maxWidth={mobileView ? '100%' : '80%'}
        >
          <Typography variant="h4" color={'text.secondary'} fontWeight={400}>
            No problem. We understand that situations change. Please, help us
            learn from your experience by telling us why you’re cutting ties.
          </Typography>
        </Box>
        <Box className="add-media-container" position="relative">
          <Input
            select
            selectItems={Object.values(EndAssistanceEnum).map(val => ({
              label: val,
              value: val,
            }))}
            caption="Reason"
            variant="outlined"
            fullWidth
            placeholder="Choose one"
            register={register('reason')}
            error={!!errors?.reason}
            helperText={errors?.reason?.message || ''}
          />
        </Box>

        <Box>
          <Typography
            component={'p'}
            fontSize={'12px'}
            fontWeight={700}
            mb={'16px'}
          >
            Description
          </Typography>
          <TextField
            placeholder="Please tell us where"
            register={register('description')}
            error={!!errors?.description}
            helperText={errors?.description ? errors?.description?.message : ''}
          />
        </Box>
      </Stack>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default EndAssistantModal;
