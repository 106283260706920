import { Box, Input, Typography, useTheme } from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import PhotosIcon from '../../../../components/Icons/PhotosIcon';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import IconRoundBackground from '../../../../components/MUIComponents/IconRoundBackground';
import SquareIconButton from '../../../../components/MUIComponents/SquareIconButton';
import StandardImageList from '../../../../components/MUIComponents/StandardImageList';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import {
  useAddUserPhotoMutation,
  useGetAllUserPhotosByUserIdQuery,
  useGetAllUserPhotosQuery,
} from '../../../../store/apiSlice/profileMediaApi';
import { colors } from '../../../../theme/theme';
import { ElementIdEnum } from '../../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { isSupportedIdConfirmationFileHelper } from '../../../../utils/helper/file-validation-helper';
import { useModal } from '../../../../hooks/useModal';
import AddPhotosModal from '../../../../components/Media/Modals/AddPhotosModal';
import GridSkeleton from '../../../../components/Media/GridSkeleton';
import ButtonsOverlay from '../../../../components/Media/Layouts/ButtonsOverlay';
import MediaPreviewModal from '../../../../components/Media/Modals/MediaPreviewModal';

interface PhotosProps {
  navigatePhotos: () => void;
  setSnackbarOpen: Dispatch<SetStateAction<boolean>>;
  setSnackbarMessage: Dispatch<SetStateAction<string>>;
  setSnackbarSeverity: Dispatch<SetStateAction<SnackbarSeverityEnum>>;
}

const Photos = ({
  navigatePhotos,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}: PhotosProps) => {
  const { id } = useParams();
  const theme = useTheme();
  const { isOpen, openModal, closeModal } = useModal();
  const { isOpen: isOpenMediaModal, toggleModal: toggleMediaModal } =
    useModal();
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const getAllUserPhotosQuery = useGetAllUserPhotosQuery(
    {
      page: 1,
      limit: 6,
    },
    {
      skip: !!id,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const getAllPhotosByUserIdQueryResult = useGetAllUserPhotosByUserIdQuery(
    {
      id: id || '',
      page: 1,
      limit: 6,
    },
    {
      skip: !id,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: photoData, isLoading } = id
    ? getAllPhotosByUserIdQueryResult
    : getAllUserPhotosQuery;

  const photos = useMemo(
    () =>
      photoData?.data.map(item => ({
        src: `${item.fileUrl}?w=164&h=164&fit=crop&auto=format`,
        srcSet: `${item.fileUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`,
        title: item.fileName || '',
      })),
    [photoData]
  );

  const photosForPreview = useMemo(
    () =>
      photoData?.data.map(item => ({
        id: item.id,
        fileName: item.fileName ?? '',
        fileUrl: item.fileUrl ?? '',
      })),
    [photoData]
  );

  const [addUserPhoto, { isLoading: isUpdatingFiles }] =
    useAddUserPhotoMutation();

  const addFile = async (files: FileList) => {
    const newFiles = Array.from(files);
    const validFiles = newFiles.filter(file =>
      isSupportedIdConfirmationFileHelper(file)
    );
    const invalidFiles = newFiles.filter(
      file => !isSupportedIdConfirmationFileHelper(file)
    );

    if (validFiles.length > 5) {
      setSnackbarMessage('You can upload up to 5 files at once');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (invalidFiles.length > 0) {
      setSnackbarMessage('Invalid file type. Only images (JPEG, PNG, WebP)');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }
    try {
      await Promise.all(
        validFiles.map(async file => {
          const formData = new FormData();
          formData.append('file', file);
          await addUserPhoto(formData).unwrap();
        })
      );
      setSnackbarMessage('All files uploaded successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(`Error uploading files: ${error}`);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      console.error('Error uploading files:', error);
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFile(event.target.files);
      event.target.value = '';
    }
  };

  const handleOpenMediaPreview = (index: number) => {
    setCurrentIndex(index);
    toggleMediaModal();
  };

  const handleNext = () => {
    if (!photosForPreview) return;
    setCurrentIndex(prevIndex =>
      prevIndex < photosForPreview.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    if (!photosForPreview) return;
    setCurrentIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : photosForPreview.length - 1
    );
  };

  const mainContent = (
    <>
      {!!photos?.length && (
        <Box className="pfofile-photos" marginTop="22px">
          <StandardImageList
            itemData={photos || []}
            imageListProps={{
              cols: 3,
              sx: {
                width: '100%',
                height: { lg: 134, xl: 185 },
                overflow: 'hidden',
                gap: { lg: '6px !important' },
              },
            }}
            imageListItemProps={{
              sx: {
                [theme.breakpoints.up('lg')]: {
                  width: 64,
                  height: '64px !important',
                },
                [theme.breakpoints.up('xl')]: {
                  width: 90,
                  height: '90px !important',
                },
              },
            }}
            overlayButtons={
              <ButtonsOverlay
                nameOfClass={'photo-buttons-overlay'}
                openHandler={() => handleOpenMediaPreview(currentIndex)}
                removeHandler={() => {}}
                hasRemove={false}
              />
            }
          />
          <MediaPreviewModal
            files={photosForPreview!}
            currentIndex={currentIndex}
            isOpen={isOpenMediaModal}
            onClose={() => toggleMediaModal()}
            clickNext={handleNext}
            clickPrev={handlePrev}
            deleteFile={() => {}}
          />
        </Box>
      )}
      {!photos?.length && (
        <Box
          className="upload_photos"
          width="100%"
          height="100%"
          minHeight={186}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          rowGap="18px"
          marginTop="22px"
          sx={theme => ({ bgcolor: theme.palette.secondary2.main })}
        >
          <IconRoundBackground
            backgroundColor={theme.palette.background.lightDark}
          >
            <PhotosIcon />
          </IconRoundBackground>

          {id && (
            <Typography
              variant="body3"
              color="black"
              sx={{ fontWeight: '500 !important' }}
            >
              no photos
            </Typography>
          )}

          {!id && (
            <>
              <Typography
                variant="body3"
                color="black"
                sx={{ fontWeight: '500 !important' }}
              >
                Upload photo
              </Typography>
              <CustomButton
                variant="outlined"
                variantType={ButtonTypeEnum.PRIMARY}
                onClick={openModal}
                disabled={isLoading || isUpdatingFiles}
                fontSize={'0.625rem'}
                sx={{ p: '0.8125rem 1rem', height: 40 }}
              >
                choose files
              </CustomButton>
            </>
          )}
        </Box>
      )}
    </>
  );

  return (
    <Box
      className="profile-photos-box"
      width={150}
      sx={{
        height: 'fit-content',
        width: '100%',
      }}
    >
      <Box
        className="profile-photos-title"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography
            variant="subtitle1"
            fontWeight={800}
            color={colors.fontDark}
          >
            PHOTOS
          </Typography>
        </Box>
        <Box>
          <SquareIconButton
            onClick={navigatePhotos}
            sx={{ p: 0, minWidth: 32, minHeight: 32, width: 30, height: 30 }}
          >
            <PhotosIcon />
          </SquareIconButton>
        </Box>
      </Box>
      {isLoading || isUpdatingFiles ? (
        <Box sx={{ marginTop: '22px' }}>
          <GridSkeleton lg={4} xl={4} itemCount={6} />
        </Box>
      ) : (
        mainContent
      )}
      <Input
        id={ElementIdEnum.ADD_PHOTOS_SIDEBAR_BUTTON}
        type="file"
        sx={{ display: 'none' }}
        inputProps={{ multiple: true, accept: 'image/*' }}
        onChange={handleFileChange}
      />
      <AddPhotosModal
        isOpen={isOpen}
        onClose={closeModal}
        setSnackbarOpen={setSnackbarOpen}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbarSeverity={setSnackbarSeverity}
      />
    </Box>
  );
};

export default Photos;
