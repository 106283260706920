import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

interface LikeIconProps extends SvgIconProps {
  fillNone?: boolean;
  isRed?: boolean;
}

const LikeIcon = ({
  fillNone = false,
  isRed = false,
  ...svgProps
}: LikeIconProps) => {
  return (
    <SvgIcon
      sx={{ width: 16, height: 16 }}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <path
        d="M13 8.38136L8 13.3334L3 8.38136C2.6702 8.06044 2.41043 7.6747 2.23703 7.24845C2.06363 6.8222 1.98037 6.36466 1.99249 5.90465C2.00461 5.44464 2.11184 4.99212 2.30744 4.57558C2.50303 4.15905 2.78275 3.78753 3.12899 3.48441C3.47522 3.1813 3.88047 2.95315 4.3192 2.81435C4.75794 2.67554 5.22067 2.62908 5.67824 2.67789C6.13582 2.72671 6.57833 2.86973 6.97791 3.09796C7.3775 3.3262 7.7255 3.63469 8 4.00403C8.27569 3.63737 8.62409 3.33157 9.0234 3.10576C9.42271 2.87995 9.86433 2.73899 10.3206 2.69171C10.7769 2.64442 11.2381 2.69182 11.6752 2.83095C12.1123 2.97008 12.516 3.19794 12.861 3.50027C13.206 3.8026 13.4849 4.17288 13.6803 4.58795C13.8756 5.00303 13.9832 5.45395 13.9962 5.9125C14.0092 6.37105 13.9275 6.82735 13.7561 7.25286C13.5847 7.67836 13.3273 8.06391 13 8.38536"
        fill={fillNone ? 'none' : isRed ? '#E92726' : '#727F93'}
      />
      <path
        fill="none"
        d="M13 8.38136L8 13.3334L3 8.38136C2.6702 8.06044 2.41043 7.6747 2.23703 7.24845C2.06363 6.8222 1.98037 6.36466 1.99249 5.90465C2.00461 5.44464 2.11184 4.99212 2.30744 4.57558C2.50303 4.15905 2.78275 3.78753 3.12899 3.48441C3.47522 3.1813 3.88047 2.95315 4.3192 2.81435C4.75794 2.67554 5.22067 2.62908 5.67824 2.67789C6.13582 2.72671 6.57833 2.86973 6.97791 3.09796C7.3775 3.3262 7.7255 3.63469 8 4.00403C8.27569 3.63737 8.62409 3.33157 9.0234 3.10576C9.42271 2.87995 9.86433 2.73899 10.3206 2.69171C10.7769 2.64442 11.2381 2.69182 11.6752 2.83095C12.1123 2.97008 12.516 3.19794 12.861 3.50027C13.206 3.8026 13.4849 4.17288 13.6803 4.58795C13.8756 5.00303 13.9832 5.45395 13.9962 5.9125C14.0092 6.37105 13.9275 6.82736 13.7561 7.25286C13.5847 7.67836 13.3273 8.06391 13 8.38536"
        stroke={isRed ? '#E92726' : '#727F93'}
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </SvgIcon>
  );
};

export default LikeIcon;
