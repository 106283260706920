import * as yup from 'yup';
import { EndAssistanceEnum } from '../enums/cancel-reason-type-enum';

export const EndAssistantSchema = yup.object().shape({
  description: yup
    .string()
    .optional()
    .max(500, 'Description cannot exceed 500 characters'),
  reason: yup
    .mixed<EndAssistanceEnum>()
    .oneOf(Object.values(EndAssistanceEnum), 'Invalid reason')
    .optional(),
});
