import React, { FC } from 'react';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import PeopleFilters from './PeopleFilters/PeopleFilters';
import PostsFilters from './PostsFilter/PostsFilters';

export type SEARCH_TYPE = 'PEOPLE' | 'POSTS';

const searchTypes = [
  {
    value: 'PEOPLE',
    label: 'People',
  },
  {
    value: 'POSTS',
    label: 'Posts',
  },
];

interface GlobalSearchSidebarProps {
  radioValue: SEARCH_TYPE;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const GlobalSearchSidebar: FC<GlobalSearchSidebarProps> = ({
  radioValue,
  handleChange,
}) => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.375rem',
        marginTop: {
          xs: '-1.25rem',
          lg: 0,
        },
      }}
    >
      <Stack
        sx={{
          flexDirection: 'column',
          minWidth: 227,
          width: !matches ? '100%' : 314,
          borderRadius: '1px',
          bgcolor: 'background.white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '4.125rem',
            alignItems: 'center',
            p: '1rem',
          }}
        >
          <Typography variant={'body1'}>{'Result for'}</Typography>
        </Box>

        <FormControl
          sx={{
            padding: '1.375rem 1rem',
          }}
        >
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={radioValue}
            onChange={handleChange}
            sx={{
              gap: '0.75rem',
              '& .MuiTypography-root': { fontSize: '14px', fontWeight: 400 },
            }}
          >
            {searchTypes.map(type => (
              <FormControlLabel
                key={type.value}
                value={type.value}
                control={<Radio sx={{ color: 'background.dark' }} />}
                label={type.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Stack>

      <Stack
        sx={{
          flexDirection: 'column',
          minWidth: 227,
          width: !matches ? '100%' : 314,
          borderRadius: '1px',
          bgcolor: 'background.white',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            height: '4.125rem',
            alignItems: 'center',
            p: '1rem',
          }}
        >
          <Typography variant={'body1'}>{'Filters'}</Typography>
        </Box>

        {radioValue === 'PEOPLE' && <PeopleFilters />}
        {radioValue === 'POSTS' && <PostsFilters />}
      </Stack>
    </Box>
  );
};

export default GlobalSearchSidebar;
