export enum PositionEnum {
  FULL_TIME = 'FULL-TIME',
  PART_TIME = 'PART-TIME',
  CONTRACT = 'CONTRACT',
  INTERN = 'INTERN',
}

export enum LocationEnum {
  REMOTE = 'REMOTE',
  ONSITE = 'ONSITE',
  HYBRID = 'HYBRID',
}

export enum SalaryPeriodEnum {
  PER_MONTH = 'PER_MONTH',
  PER_YEAR = 'PER_YEAR',
}
