import {
  Box,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material';
import DeleteChat from './DeleteChat';
import BaseMenuItem from './BaseMenuItem';
import InfoIcon from '../../../../../components/Icons/InfoIcon';
import SmallTrashIcon from '../../../../../components/Icons/SmallTrashIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatType,
  getSelectedChat,
  setChatState,
} from '../../../../../store/apiSlice/chat/chatSlice';
import { ChatStateEnum, ChatTypeEnum } from '../../../../../enums/chat-enum';
import Edit from './Edit';
import EditChatIcon from '../../../../../components/Icons/EditChatIcon';
import LeaveChat from './LeaveChat';
import LeaveChatIcon from '../../../../../components/Icons/LeaveChatIcon';
import { getCurrentUserId } from '../../../../../utils/helper/getCurrentUserId';
import { useMemo } from 'react';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';

interface IChatActionsMenuProps {
  onClose: () => void;
}

const ChatActionsMenu = ({ onClose }: IChatActionsMenuProps) => {
  const { theme } = useThemeContext();
  const chatType = useSelector(getChatType);
  const selectedChat = useSelector(getSelectedChat);
  const dispatch = useDispatch();

  const chatActionsMenuItemSx = useMemo(
    () => ({
      p: '0.75rem',
      gap: '0.375rem',
      '&:hover': {
        '*': {
          color: theme.palette.button.primary,
          stroke: theme.palette.button.primary,
        },
        backgroundColor: 'inherit',
      },
    }),
    [theme.palette.button.primary]
  );

  const openInfoWindow = () => {
    onClose();
    dispatch(setChatState(ChatStateEnum.GROUP_INFO));
  };

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Box
        sx={{
          bgcolor: 'background.white',
          boxShadow: '0px 6px 30px 0px #111D3733',
        }}
      >
        <MenuList autoFocusItem={false} sx={{ p: 0 }}>
          {chatType === ChatTypeEnum.GROUP && (
            <MenuItem
              divider
              sx={chatActionsMenuItemSx}
              onClick={() => openInfoWindow()}
            >
              {<InfoIcon />}
              <Typography variant="body4" color={'text.primary'}>
                {'Info'}
              </Typography>
            </MenuItem>
          )}

          {chatType === ChatTypeEnum.GROUP &&
            selectedChat &&
            selectedChat.userId === getCurrentUserId() && (
              <BaseMenuItem
                onSelected={onClose}
                title={'Edit'}
                icon={<EditChatIcon />}
                sx={{
                  alignItems: 'flex-start',
                  mx: 'none',
                  maxWidth: 'inherit',
                  textAlign: 'inherit',
                }}
                modalSx={{ p: '2.25rem', width: 'fit-content' }}
              >
                <Edit onClose={onClose} />
              </BaseMenuItem>
            )}

          {chatType === ChatTypeEnum.GROUP &&
            getCurrentUserId() !== selectedChat?.userId && (
              <BaseMenuItem
                onSelected={onClose}
                title={'Leave chat'}
                icon={<LeaveChatIcon />}
              >
                <LeaveChat onClose={onClose} />
              </BaseMenuItem>
            )}

          {((chatType === ChatTypeEnum.GROUP &&
            selectedChat &&
            selectedChat.userId === getCurrentUserId()) ||
            chatType === ChatTypeEnum.PERSONAL) && (
            <BaseMenuItem
              onSelected={onClose}
              title={'Delete chat'}
              icon={<SmallTrashIcon />}
            >
              <DeleteChat onClose={onClose} />
            </BaseMenuItem>
          )}
        </MenuList>
      </Box>
    </ClickAwayListener>
  );
};

export default ChatActionsMenu;
