import React from 'react';
import { IUserSportBackgroundResponse } from '../../../../../interfaces/user-sport-background.interface';
import { Box, Divider, Grid, SxProps, Theme, Typography } from '@mui/material';
import { foramtCareerDate } from '../../../../../utils/profile-page/format-career-date';

interface IExperiencesList {
  sportBackgrounds: IUserSportBackgroundResponse[];
}
const ExperiencesList = (props: IExperiencesList) => {
  const { sportBackgrounds } = props;
  return (
    <>
      {sportBackgrounds.map((sportBg, index) => (
        <Box display={'flex'} flexDirection={'column'} key={sportBg.id}>
          {index !== 0 && <Divider sx={{ m: '2.25rem 0' }} />}

          <Grid
            container
            rowGap="28px"
            marginTop={index === 0 ? '2.25rem' : 0}
            sx={gridContainerStyles}
          >
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Sport:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {`${sportBg?.sport.name ?? ''}`}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Career timeline:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {foramtCareerDate(sportBg.startCareerDate)} -{' '}
                {foramtCareerDate(sportBg.endCareerDate)}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Description:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {sportBg.description ?? ''}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default ExperiencesList;

const gridContainerStyles: SxProps<Theme> = theme => ({
  '& .label': {
    width: 165,
    flexShrink: 0,
  },
  '& .plain-text': {
    fontWeight: 400,
    lineHeight: '140%',
    color: theme.palette.text.primary,

    textAlign: 'left',
    flexGrow: '1',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    minWidth: 0,
  },
  '& .MuiGrid-item': {
    width: '100%',
  },
});
