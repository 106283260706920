import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { memo, useCallback, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import DateInput from '../../../../components/MUIComponents/DateInput';
import RadioGroup from '../../../../components/MUIComponents/RadioGroup';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { postFilterSchema } from '../../../../validation/post-validation';
import { ISetFilter } from '../posts/PostsToolbar';

export enum PostDate {
  ALL = 'ALL',
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  CUSTOM = 'CUSTOM',
}

interface PostFiltersFormInputs {
  postDate: PostDate;
  from?: Dayjs | null;
  to?: Dayjs | null;
}

interface PostFiltersModalProps {
  isOpen?: boolean;
  onClose: () => void;
  setFilter: ISetFilter;
}

const PostFiltersModal = ({
  onClose,
  isOpen = false,
  setFilter,
}: PostFiltersModalProps) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<PostFiltersFormInputs>({
    resolver: yupResolver(postFilterSchema),
    defaultValues: {
      postDate: PostDate.TODAY,
      from: null,
      to: null,
    },
  });

  const onSubmit = useCallback(
    async (data: PostFiltersFormInputs) => {
      try {
        type DateRange = {
          dateFrom: string | null;
          dateTo: string | null;
        };

        let dateRange: DateRange;
        const DATE_FORMAT = 'YYYY-MM-DD';

        type RangeType = 'day' | 'week' | 'month' | 'year';
        const getDateRange = (rangeType: RangeType) => ({
          dateFrom: dayjs().startOf(rangeType).format(DATE_FORMAT),
          dateTo: dayjs().endOf(rangeType).format(DATE_FORMAT),
        });

        switch (data.postDate) {
          case PostDate.ALL: {
            dateRange = {
              dateFrom: null,
              dateTo: null,
            };
            break;
          }
          case PostDate.TODAY: {
            dateRange = getDateRange('day');
            break;
          }
          case PostDate.THIS_WEEK: {
            dateRange = getDateRange('week');
            break;
          }
          case PostDate.THIS_MONTH: {
            dateRange = getDateRange('month');
            break;
          }
          case PostDate.THIS_YEAR: {
            dateRange = getDateRange('year');
            break;
          }
          case PostDate.CUSTOM: {
            if (!data.from || !data.to) {
              throw new Error("date 'from' and date 'to' are required");
            }
            dateRange = {
              dateFrom: data.from.format(DATE_FORMAT),
              dateTo: data.to.format(DATE_FORMAT),
            };
            break;
          }
          default: {
            throw new Error(
              'Oops! Something went wrong, no valid filter selected.'
            );
          }
        }

        setFilter({ from: dateRange.dateFrom, to: dateRange.dateTo });
        onClose();
      } catch (error) {
        setSnackbarMessage(errorHelper(error));
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        console.error('Create post failed:', error);
      }
    },
    [setFilter, onClose]
  );

  const handleCancelClick = () => {
    reset();
    onClose();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const isCustomOption = watch('postDate') === PostDate.CUSTOM;
  return (
    <BaseModal
      header={{ text: 'Filter', styles: titleStyles }}
      isOpen={isOpen}
      onAccept={handleSubmit(onSubmit)}
      onDecline={handleCancelClick}
      disableEscapeButton
      content={{ styles: { p: '36px 0', m: 0 } }}
      toggle={onClose}
      footer={{ declineText: 'cancel', okText: 'apply', styles: footerStyles }}
      boxSx={{ width: { xs: '100%', lg: '648px' }, p: '22px 28px' }}
    >
      <Box mt="-24px">
        <Divider sx={{ m: '0 -28px 36px -28px' }} />
        <RadioGroup
          row
          label="POST DATE"
          name="postDate"
          error={!!errors.postDate}
          helperText={errors.postDate ? errors.postDate.message : ''}
          value={watch('postDate')}
          onChange={event => {
            if (!event.target.value) {
              return;
            }
            setValue('postDate', event.target.value as PostDate);
          }} // Manually setting the value in form state
        >
          <FormControlLabel
            value={PostDate.ALL}
            control={<Radio />}
            label="all"
          />
          <FormControlLabel
            value={PostDate.TODAY}
            control={<Radio />}
            label="today"
          />
          <FormControlLabel
            value={PostDate.THIS_WEEK}
            control={<Radio />}
            label="this week"
          />
          <FormControlLabel
            value={PostDate.THIS_MONTH}
            control={<Radio />}
            label="this month"
          />
          <FormControlLabel
            value={PostDate.THIS_YEAR}
            control={<Radio />}
            label="this year"
          />
          <FormControlLabel
            value={PostDate.CUSTOM}
            control={<Radio />}
            label="custom"
          />
        </RadioGroup>

        {isCustomOption && (
          <Grid container mt="36px">
            <Grid item>
              <Typography sx={labelStyles}>custom date range</Typography>
            </Grid>
            <Grid item container columnGap="22px">
              <Grid item width={184}>
                <DateInput<PostFiltersFormInputs>
                  control={control}
                  name="from"
                  error={errors.from as FieldError}
                  helperText={errors.from?.message || ''}
                  placeholder="From"
                />
              </Grid>
              <Grid item width={184}>
                <DateInput<PostFiltersFormInputs>
                  control={control}
                  name="to"
                  error={errors.to as FieldError}
                  helperText={errors.to?.message || ''}
                  placeholder="To"
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Divider sx={{ m: '36px -28px 0 -28px' }} />
      </Box>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default memo(PostFiltersModal);

const titleStyles: SxProps = {
  justifyContent: 'flex-start',
  fontSize: '20px',
  fontStyle: 'normal',
  lineHeight: '125%',
};

const footerStyles: SxProps = {
  justifyContent: 'flex-end',
  columnGap: '22px',

  '& .MuiButtonBase-root': {
    width: 140,
    height: 40,
    minWidth: 100,
  },
};

const labelStyles: SxProps<Theme> = theme => ({
  color: theme.palette.text.primary,
  fontFamily: 'ES Klarheit Kurrent TRIAL',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '800',
  lineHeight: '12px',
  letterSpacing: '0.48px',
  textTransform: 'uppercase',
  pb: '10px',
});
