import React, { FC, useCallback, useEffect, useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import SearchPeopleResultItem from './SearchPeopleResultItem';
import { IUser } from '../../../../interfaces/user.interface';
import {
  useGetPaginatedPostsQuery,
  useGetPaginatedUsersQuery,
} from '../../../../store/apiSlice/searchApi';
import { useSearchParams } from 'react-router-dom';
import { IPost } from '../../../../interfaces/post.interface';
import ProfilePost from '../../../home/shared-components/posts/ProfilePost';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface SearchResultsProps {
  isPeople?: boolean;
}

const PEOPLE_RESULTS_LIMIT = 5;
const POSTS_RESULTS_LIMIT = 3;

const SearchResults: FC<SearchResultsProps> = ({ isPeople = true }) => {
  const { theme } = useThemeContext();
  const [searchParams] = useSearchParams();

  const [page, setPage] = useState<number>(
    Number(searchParams.get('page')) || 1
  );
  const [allUsersResult, setAllUsersResult] = useState<IUser[]>([]);
  const [allPostsResult, setAllPostsResult] = useState<IPost[]>([]);

  const limit =
    Number(searchParams.get('limit')) ||
    (isPeople ? PEOPLE_RESULTS_LIMIT : POSTS_RESULTS_LIMIT);
  const search = searchParams.get('query') ?? '';
  const sort = searchParams.get('sort') ?? 'asc';
  const sports = searchParams.get('sports') ?? '';
  const leagues = searchParams.get('leagues') ?? '';
  const teams = searchParams.get('teams') ?? '';
  const country = searchParams.get('country') ?? '';

  const tags = searchParams.get('tags') ?? '';
  const from = searchParams.get('from') ?? '';
  const to = searchParams.get('to') ?? '';

  const { data: usersResponse, isLoading: isUsersLoading } =
    useGetPaginatedUsersQuery({
      page,
      limit,
      search,
      sort,
      sports,
      leagues,
      teams,
      country,
    });

  const { data: postsResponse, isLoading: isPostsLoading } =
    useGetPaginatedPostsQuery({
      tags,
      page,
      limit,
      search,
      sort,
      from,
      to,
    });

  const updatePeopleResults = useCallback(
    (newResults: IUser[]) => {
      setAllUsersResult(prevResults => {
        const uniqueResults = newResults.filter(
          newResult => !prevResults.some(prev => prev.id === newResult.id)
        );
        return [...prevResults, ...uniqueResults];
      });
    },
    [setAllUsersResult]
  );

  const updatePostsResults = useCallback(
    (newResults: IPost[]) => {
      setAllPostsResult(prevResults => {
        const uniqueResults = newResults.filter(
          newResult => !prevResults.some(prev => prev.id === newResult.id)
        );
        return [...prevResults, ...uniqueResults];
      });
    },
    [setAllUsersResult]
  );

  useEffect(() => {
    setAllUsersResult([]);
    setAllPostsResult([]);
    setPage(1);
  }, [search, searchParams]);

  useEffect(() => {
    if (usersResponse?.data) {
      updatePeopleResults(usersResponse.data);
    }
  }, [usersResponse, updatePeopleResults]);

  useEffect(() => {
    if (postsResponse?.data) {
      updatePostsResults(postsResponse.data);
    }
  }, [postsResponse, updatePostsResults]);

  const countResults =
    (isPeople ? usersResponse?.total : postsResponse?.total) ?? 0;

  const handleShowMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  return (
    <Stack direction="column" spacing={'2.25rem'} padding={'2.25rem'}>
      <Typography variant={'h2'}>{`Search results for '${search}'`}</Typography>

      <Stack direction="column" spacing={'1.375rem'}>
        <Typography variant="h3">
          {countResults > 0
            ? `${isPeople ? 'People' : 'Posts'} (${countResults} results)`
            : `No ${isPeople ? 'People' : 'Posts'} Found`}
        </Typography>
        <Divider />
      </Stack>

      <Stack direction="column" spacing={'1.375rem'}>
        {isPeople &&
          allUsersResult.length > 0 &&
          allUsersResult?.map((user, index) => (
            <SearchPeopleResultItem key={index} user={user} />
          ))}
        {!isPeople &&
          allPostsResult?.map((post, index) => (
            <Box
              key={post.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: theme.palette.background.white,
                borderBottom: `1px solid ${theme.palette.background.darker}`,
              }}
            >
              <ProfilePost post={post} refetchPosts={() => {}} />
            </Box>
          ))}
      </Stack>

      {allUsersResult.length < countResults && (
        <CustomButton
          onClick={handleShowMore}
          variantType={ButtonTypeEnum.SECONDARY}
          sx={{
            width: '100%',
            height: '3.5rem',
          }}
        >
          Show more
        </CustomButton>
      )}
    </Stack>
  );
};

export default SearchResults;
