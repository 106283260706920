import {
  Divider,
  FormControl,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import Input from '../../../../components/MUIComponents/Input';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import Box from '@mui/material/Box';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { errorHelper } from '../../../../utils/helper/error-helper';
import {
  useApproveIntroductionMutation,
  useCancelIntroductionMutation,
  useGetIntroductionByIdQuery,
} from '../../../../store/apiSlice/friendIntroductionApi';
import { CancelIntroductionReasonEnum } from '../../../../enums/cancel-introduction-reason-enum';
import { useForm } from 'react-hook-form';
import { requestIntroductionSchema } from '../../../../validation/request-introduction';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGetUserInfoQuery } from '../../../../store/apiSlice/userApi';
import { SelectItemUser } from './components/SelectItemUser';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { ICancelIntroductionRequest } from '../../../../interfaces/friend-introduction.interface';

interface IntroductionCheckModalProps {
  isOpen?: boolean;
  onClose: () => void;
  introductionId?: string;
}

interface RequestIntroductionFormInputs {
  reasonToDeny?: CancelIntroductionReasonEnum;
}

enum SubmitType {
  ACCEPT_INTRODUCTION,
  DENY_INTRODUCTION,
}

const IntroductionCheckModal = ({
  onClose,
  isOpen = true,
  introductionId,
}: IntroductionCheckModalProps) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const [isDenyBtnPressed, setDenyBtnPressed] = useState<boolean>(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const { data: introduction, isLoading: isIntroductionLoading } =
    useGetIntroductionByIdQuery(
      { id: introductionId || '' },
      { skip: !introductionId }
    );

  const {
    data: userWhoIntroducesHimself,
    isLoading: isUserWhoIntroducesHimselfLoading,
  } = useGetUserInfoQuery(introduction?.firstFriendId || '', {
    skip: !introductionId || !introduction,
  });
  const {
    data: userToWhomRepresented,
    isLoading: isUserToWhomRepresentedLoading,
  } = useGetUserInfoQuery(introduction?.secondFriendId || '', {
    skip: !introductionId || !introduction,
  });

  const [approveIntroduction, { isLoading: isApproveIntroductionLoading }] =
    useApproveIntroductionMutation();
  const [denyIntroduction, { isLoading: isDenyIntroductionLoading }] =
    useCancelIntroductionMutation();

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const {
    register,
    getValues,
    formState: { errors },
  } = useForm<RequestIntroductionFormInputs>({
    resolver: yupResolver(requestIntroductionSchema),
  });

  const getSubmitType = useCallback(() => {
    return isDenyBtnPressed
      ? SubmitType.DENY_INTRODUCTION
      : SubmitType.ACCEPT_INTRODUCTION;
  }, [isDenyBtnPressed]);

  const handleDenyButtonClick = () => {
    if (isDenyBtnPressed) {
      setDenyBtnPressed(false);
      return;
    }

    setDenyBtnPressed(true);
  };

  const onSubmit = async () => {
    try {
      if (!introductionId) {
        setSnackbarOpen(true);
        setSnackbarMessage('Please select a user who will represent you.');
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        return;
      }

      const submitType = getSubmitType();

      if (submitType === SubmitType.DENY_INTRODUCTION) {
        const reason = getValues('reasonToDeny');

        const denyIntroductionRequest: ICancelIntroductionRequest = {
          introductionId,
        };

        if (reason) {
          denyIntroductionRequest.reason = reason;
        }

        await denyIntroduction(denyIntroductionRequest).unwrap();
        setSnackbarOpen(true);
        setSnackbarMessage('Introduction request successfully denied!');
        setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);

        setTimeout(() => {
          onClose();
        }, 2000);
        return;
      }
      await approveIntroduction(introductionId).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Introduction request successfully approved!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    }
  };

  const modalTitle = useMemo(() => {
    return (
      <>
        <Typography variant="h3" textAlign="left" mb={'22px'}>
          Introduction request
        </Typography>
        <Divider sx={{ m: { xs: '0 -12px', lg: '0 -36px' } }} />
      </>
    );
  }, []);

  const modalFooter = useMemo(
    () => (
      <Box mt={3}>
        <Grid
          container
          justifyContent="space-between"
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          gap={{ xs: '10px', lg: '0px' }}
          width={'100%'}
        >
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="outlined"
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={handleDenyButtonClick}
              disabled={
                !introductionId ||
                isIntroductionLoading ||
                isUserToWhomRepresentedLoading ||
                isUserWhoIntroducesHimselfLoading ||
                isDenyIntroductionLoading ||
                isApproveIntroductionLoading
              }
            >
              {isDenyBtnPressed ? 'back' : 'deny'}
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              onClick={onSubmit}
              disabled={
                !introductionId ||
                isIntroductionLoading ||
                isUserToWhomRepresentedLoading ||
                isUserWhoIntroducesHimselfLoading ||
                isDenyIntroductionLoading ||
                isApproveIntroductionLoading
              }
            >
              {isDenyBtnPressed ? 'deny' : 'accept'}
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    ),
    [onSubmit, onClose, introductionId]
  );

  return (
    <BaseModal
      header={{ component: modalTitle }}
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: modalFooter }}
      styleEscapeBtnStylesBelow={{ top: 26 }}
      disableEscapeButton={true}
    >
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        gap={'1.75rem'}
      >
        <Stack
          width={'100%'}
          display={'flex'}
          flexDirection={mobileView ? 'column' : 'row'}
          gap={'1.75rem'}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={mobileView ? '100%' : '50%'}
          >
            <Typography variant="body4" mb="16px">
              Introduce from:
            </Typography>
            {isUserWhoIntroducesHimselfLoading ||
              (isIntroductionLoading && (
                <Box display="flex" alignItems="center">
                  <Skeleton
                    variant="circular"
                    width={'2.5rem'}
                    height={'2.5rem'}
                  />
                  <Box ml={2}>
                    <Skeleton
                      variant="text"
                      width={'7.5rem'}
                      height={'1.25rem'}
                    />
                    <Skeleton
                      variant="text"
                      width={'6.25rem'}
                      height={'1.25rem'}
                    />
                  </Box>
                </Box>
              ))}
            {userWhoIntroducesHimself && !isUserWhoIntroducesHimselfLoading && (
              <SelectItemUser
                userId={userWhoIntroducesHimself.id}
                avatarUrl={
                  userWhoIntroducesHimself?.optimizedUserAvatarBaseUrl || ''
                }
                firstName={userWhoIntroducesHimself.firstName || ''}
                lastName={userWhoIntroducesHimself.lastName || ''}
                sport={userWhoIntroducesHimself.sports[0]?.name}
                isAvatarBase64={true}
              />
            )}
          </Box>

          <Box
            display={'flex'}
            flexDirection={'column'}
            width={mobileView ? '100%' : '50%'}
          >
            <Typography variant="body4" mb="16px">
              Introduce to:
            </Typography>
            {isUserToWhomRepresentedLoading ||
              (isIntroductionLoading && (
                <Box display="flex" alignItems="center">
                  <Skeleton
                    variant="circular"
                    width={'2.5rem'}
                    height={'2.5rem'}
                  />
                  <Box ml={2}>
                    <Skeleton
                      variant="text"
                      width={'7.5rem'}
                      height={'1.25rem'}
                    />
                    <Skeleton
                      variant="text"
                      width={'6.25rem'}
                      height={'1.25rem'}
                    />
                  </Box>
                </Box>
              ))}
            {userToWhomRepresented && !isUserToWhomRepresentedLoading && (
              <SelectItemUser
                userId={userToWhomRepresented.id}
                avatarUrl={
                  userToWhomRepresented?.optimizedUserAvatarBaseUrl || ''
                }
                firstName={userToWhomRepresented.firstName || ''}
                lastName={userToWhomRepresented.lastName || ''}
                sport={userToWhomRepresented.sports[0]?.name}
                isAvatarBase64={true}
              />
            )}
          </Box>
        </Stack>
        <Stack>
          {isDenyBtnPressed && (
            <FormControl fullWidth>
              <Input
                select
                selectItems={Object.values(CancelIntroductionReasonEnum).map(
                  val => ({ label: val, value: val })
                )}
                containerFlex="1"
                caption="Reason for denial:"
                variant="outlined"
                fullWidth
                placeholder="Choose one"
                register={register('reasonToDeny')}
                error={!!errors.reasonToDeny}
                helperText={errors.reasonToDeny?.message || ''}
              />
            </FormControl>
          )}
        </Stack>
      </Box>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default IntroductionCheckModal;
