import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import ArrowLeftIcon from '../../../components/Icons/ArrowLeftIcon';
import UserInfo from './components/UserInfo';
import CareerDevelopmentMap from './components/CareerDevelopmentMap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CustomButton from '../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';

const ProgressPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleBackBtn = () => {
    return navigate(-1);
  };

  return (
    <Container
      disableGutters
      className="progress-page__container"
      maxWidth={false}
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <Box bgcolor="background.white" width={1} maxWidth={872}>
        <Box
          component="header"
          p="22px 36px"
          sx={theme => ({
            borderBottom: `1px solid ${theme.palette.background.lightDark}`,
          })}
        >
          <Link
            to="#"
            onClick={handleBackBtn}
            style={{ color: '#000', textDecoration: 'none' }}
          >
            <Stack direction="row" alignItems="center" gap="16px">
              <ArrowLeftIcon sx={{ width: 17, height: 12 }} />
              <Typography variant="body1">Progress</Typography>
            </Stack>
          </Link>
        </Box>

        <Box component="main" p="36px" display="flex" flexDirection="column">
          {!state?.userInfo && (
            <Box
              width={1}
              height={500}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1">Bad request.</Typography>
            </Box>
          )}
          {state?.userInfo && (
            <Grid container flexDirection="column" rowGap="36px">
              <Grid item className="progress-page__user-info">
                <UserInfo userInfo={state.userInfo} />
              </Grid>
              <Grid item className="progress-page__career-development-map">
                <CareerDevelopmentMap userInfo={state.userInfo} />
              </Grid>
            </Grid>
          )}
          {state?.userInfo && (
            <Box
              display={'flex'}
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}
              marginTop={'2.25rem'}
            >
              <CustomButton
                onClick={() =>
                  navigate('/set-career-development-map', {
                    state: { userInfo: state.userInfo },
                  })
                }
                variantType={ButtonTypeEnum.PRIMARY}
                sx={{ p: '12px 16px' }}
              >
                Add more Milestones
              </CustomButton>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default ProgressPage;
