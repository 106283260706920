import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import { IGetAllMessagesResponse, IMessage, IUser } from './chatApi';
import { getCurrentUserId } from '../../../utils/helper/getCurrentUserId';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';
import { IInviteFriendData } from '../../../interfaces/friend.interface';

export interface IGroupChat {
  id: string;
  userId: string;
  name: string;
  thumb: string | null;
  externalId: string;
  type: string;
  createdAt: Date;
  updatedAt: Date;
  isNewMessage: boolean;
  lastMessageFrom: string | null;
  lastMessageText: string | null;
  lastMessageId: string | null;
  memberIds: string[];
  members: IUser[];
  description?: string;
}

const groupChatApi = createApi({
  reducerPath: 'groupChatApi',
  baseQuery: baseQueryWithInterceptor(
    config.BASE_GROUP_CHAT_MICROSERVICE_API_URL
  ),
  tagTypes: ['chats', 'token', 'messages'],

  endpoints: builder => ({
    getAllMyGroupChats: builder.query<
      IGroupChat[],
      { page?: number; limit?: number } | void
    >({
      query: (args = { page: 1, limit: 100 }) => {
        const userId = getCurrentUserId();
        const { page, limit } = args || {};
        return `/user/${userId}?page=${page}&limit=${limit}`;
      },
      providesTags: result => {
        if (!result) return [{ type: 'chats', id: 'LIST' }];
        return [
          ...result.map(chat => ({ type: 'chats' as const, id: chat.id })),
          { type: 'chats', id: 'LIST' },
        ];
      },
    }),

    getGroupChatById: builder.query<IGroupChat, { groupChatId?: string }>({
      query: ({ groupChatId }) => {
        return `/${groupChatId}`;
      },
      providesTags: result => {
        if (!result) return [{ type: 'chats', id: 'LIST' }];
        return [
          { type: 'chats' as const, id: result.id },
          { type: 'chats', id: 'LIST' },
        ];
      },
    }),

    createGroupChat: builder.mutation<IGroupChat, FormData>({
      query: formData => {
        return {
          url: '/',
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: [{ type: 'chats', id: 'LIST' }],
    }),

    deleteGroupChat: builder.mutation<void, string>({
      query: chatId => {
        return { url: `/${chatId}`, method: 'DELETE' };
      },
      invalidatesTags: [{ type: 'chats', id: 'LIST' }],
    }),

    updateGroupChat: builder.mutation<
      IGroupChat,
      { chatId: string; formData: FormData }
    >({
      query: ({ chatId, formData }) => {
        return {
          url: `/${chatId}`,
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'chats', id: arg.chatId },
      ],
    }),

    removeGroupChatMember: builder.mutation<
      IGroupChat,
      { chatId: string; userId: string }
    >({
      query: ({ chatId, userId }) => {
        return { url: `${chatId}/members/${userId}`, method: 'DELETE' };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'chats', id: arg.chatId },
      ],
    }),

    getAllGroupChatMessages: builder.query<
      IGetAllMessagesResponse,
      { chatId: string; page?: string; limit?: number }
    >({
      query: ({ chatId, page = '', limit = 10 }) => {
        return `/${chatId}/messages?page=${page}&limit=${limit?.toString()}`;
      },
      serializeQueryArgs: ({ endpointName, queryArgs: { chatId } }) => {
        return `${endpointName}-${chatId}`;
      },
      forceRefetch({ previousArg, currentArg }) {
        return (
          previousArg?.page !== currentArg?.page || currentArg?.page === ''
        );
      },
      merge: (currentCache, newItems, args) => {
        if (args.arg.page === '') {
          currentCache.messages = newItems.messages;
        } else {
          currentCache.messages.push(...newItems.messages);
        }
        currentCache.nextPage = newItems.nextPage;
        currentCache.prevPage = newItems.prevPage;
      },
      providesTags: (result, error, arg) => [
        { type: 'messages', id: arg.chatId },
      ],
    }),

    sendGroupMessage: builder.mutation<
      IMessage,
      { chatId: string; formData: FormData }
    >({
      query: ({ chatId, formData }) => {
        return {
          url: `${chatId}/messages`,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'messages', id: arg.chatId },
        { type: 'chats', id: arg.chatId },
      ],
    }),

    updateGroupChatMessage: builder.mutation<
      IMessage,
      { chatId: string; messageId: string; attributes: string }
    >({
      query: ({ chatId, messageId, attributes }) => {
        return {
          url: `${chatId}/messages/${messageId}`,
          method: 'PUT',
          body: { attributes },
        };
      },

      onQueryStarted: async (
        { messageId, chatId, attributes },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          groupChatApi.util.updateQueryData(
            'getAllGroupChatMessages',
            { chatId },
            draft => {
              const message = draft.messages.find(msg => msg.id === messageId)!;
              message.attributes = JSON.stringify({
                ...(typeof message.attributes === 'string'
                  ? JSON.parse(message.attributes as string)
                  : message.attributes),
                ...JSON.parse(attributes),
              });
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    getGroupChatToken: builder.query<
      string,
      { chatId: string; userId: string }
    >({
      query: ({ chatId, userId }) => {
        return {
          url: `/${chatId}/token?tokenUser=${userId}`,
          responseHandler: (response: { text: () => any }) => response.text(),
        };
      },

      providesTags: (result, error, arg) => [{ type: 'token', id: arg.chatId }],
    }),

    deleteGroupChatToken: builder.mutation<
      void,
      { chatId: string; userId: string }
    >({
      query: ({ chatId, userId }) => {
        return {
          url: `/${chatId}/token?tokenUser=${userId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'token', id: arg.chatId },
      ],
    }),

    addGroupChatMembers: builder.mutation<
      IGroupChat,
      { groupId: string; addMemberDto: IInviteFriendData[]; userId: string }
    >({
      query: ({ groupId, addMemberDto, userId }) => ({
        url: `/${groupId}/members?tokenUser=${userId}`,
        method: 'POST',
        body: addMemberDto,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'chats', id: arg.groupId },
      ],
    }),
  }),
});

export const {
  useCreateGroupChatMutation,
  useGetAllMyGroupChatsQuery,
  useGetGroupChatByIdQuery,
  useDeleteGroupChatTokenMutation,
  useGetGroupChatTokenQuery,
  useGetAllGroupChatMessagesQuery,
  useSendGroupMessageMutation,
  useDeleteGroupChatMutation,
  useUpdateGroupChatMutation,
  useRemoveGroupChatMemberMutation,
  useUpdateGroupChatMessageMutation,
  useAddGroupChatMembersMutation,
} = groupChatApi;

export default groupChatApi;
