import { Box, BoxProps, Grid, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { CareerDevelopmentStatus } from '../../../../../interfaces/individual-stage.interface';
import { useGetIndividualStageForUserByAgentIdQuery } from '../../../../../store/apiSlice/individualStageApi';
import { useGetMyAgentQuery } from '../../../../../store/apiSlice/userApi';
import CareerDevelopmentCard from '../../../shared-components/career-development/CareerDevelopmentCard';
import LiveAgentCard from './LiveAgentCard';
import SkeletonList from '../../../../../components/MUIComponents/SkeletonList';

interface CareerDevelopmentMainContentProps extends BoxProps {}

const CareerDevelopmentMainContent = ({
  ...rest
}: CareerDevelopmentMainContentProps) => {
  const {
    data: agentData,
    isLoading: isMyAgentLoading,
    refetch: refetchMyAgent,
  } = useGetMyAgentQuery();

  const queryParams = useMemo(
    () => ({
      id: agentData?.id ?? '',
      page: 1,
      limit: 10,
      search: '',
    }),
    [agentData?.id]
  );

  const { data: individualStages, isLoading } =
    useGetIndividualStageForUserByAgentIdQuery(queryParams, {
      skip: !agentData?.id,
    });

  const individualStagesList = useMemo(() => {
    if (!individualStages) {
      return [];
    }

    return individualStages.data;
  }, [individualStages]);

  const isShowLeaveReview = useMemo(
    () =>
      !individualStagesList.find(
        s => s.status !== CareerDevelopmentStatus.COMPLETED
      ),
    [individualStagesList]
  );

  return (
    <Box {...rest} justifyContent="center">
      <Typography component="header" variant="h2" pb="48px">
        Career development
      </Typography>

      <Box
        width={1}
        height={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction={{ xs: 'column-reverse', md: 'row' }}
          gap="22px"
        >
          <Grid item flex={1}>
            <Box component="article">
              <Typography variant="body1" pb="16px">
                Your personalized career milestones are ready!
              </Typography>
              <Typography variant="body2" pb="36px">
                Your dedicated agent has created a set of tailored milestones to
                help you advance your career. Let’s start achieving your goals
                together!
              </Typography>
              {isLoading && skeletonDevelopmentCards}
              {!isLoading && (
                <Stack rowGap="12px">
                  {!!individualStagesList.length &&
                    individualStagesList.map((stage: any) => (
                      <CareerDevelopmentCard
                        key={stage.id}
                        component="section"
                        cardData={stage}
                      />
                    ))}
                </Stack>
              )}
            </Box>
          </Grid>
          {agentData && (
            <Grid item minWidth={{ xs: 277 }}>
              <LiveAgentCard
                agentData={agentData}
                isAgentLoading={isMyAgentLoading}
                refetchAgent={refetchMyAgent}
                isShowLeaveReview={isShowLeaveReview}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default CareerDevelopmentMainContent;

const skeletonDevelopmentCards = (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      alignItems: 'center',
      width: '100%',
      padding: '0 1rem',
    }}
  >
    <SkeletonList
      count={2}
      sx={{
        minHeight: '300px',
      }}
    />
  </Box>
);
