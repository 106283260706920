import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { CategoryScale } from 'chart.js';
import Chart from 'chart.js/auto';
import Cookies from 'js-cookie';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import SubscriptionPaymentProtectedRoute from './components/Auth/SubscriptionPaymentProtectedRoute';
import AddNewJobPostForm from './components/Company/AddNewJobPostForm/AddNewJobPostForm';
import Analytics from './components/Company/Analytics/Analytics';
import { CookieEnum } from './enums/cookie-enum';
import { UserRoleEnum } from './enums/user-data-field-enum';
import BaseLayout from './layouts/BaseLayout';
import BlankLayout from './layouts/BlankLayout';
import AccountSettingsPage from './pages/AccountSettingPage/AccountSettingsPage';
import CompanyPage from './pages/auth/CompanyPage/CompanyPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage/ForgotPasswordPage';
import GetStartedPage from './pages/auth/GetStartedPage/GetStartedPage';
import InvitationsPage from './pages/auth/InvitationsPage/InvitationsPage';
import LoginPage from './pages/auth/LoginPage/LoginPage';
import ProfilePage from './pages/auth/ProfilePage/ProfilePage';
import RegistrationPage from './pages/auth/RegistrationPage/RegistrationPage';
import UpdatePasswordPage from './pages/auth/UpdatePasswordPage/UpdatePasswordPage';
import VerifyAccountPage from './pages/auth/VerifyAccountPage/VerifyAccountPage';
import VerifyCodePage from './pages/auth/VerifyCodePage/VerifyCodePage';
import WelcomeToPlatformPage from './pages/auth/WelcomeToPlatformPage/WelcomeToPlatformPage';
import CandidatesPage from './pages/CandidatesPage/CandidatesPage';
import CareerDevelopmentPage from './pages/career/CareerDevelopmentPage/CareerDevelopmentPage';
import CareerQuestionnairePage from './pages/career/CareerQuestionnairePage';
import LiveAgentInProgressPage from './pages/career/LiveAgentInProgressPage/LiveAgentInProgressPage';
import PersonalizedCareerModalPage from './pages/career/PersonalizedCareerModalPage';
import ProgressPage from './pages/career/ProgressPage/ProgressPage';
import QuizResultsPage from './pages/career/QuizResultsPage/QuizResultsPage';
import SetCareerDevelopmentMapPage from './pages/career/SetCareerDevelopmentMapPage/SetCareerDevelopmentMapPage';
import ChatPage from './pages/ChatPage/ChatPage';
import GlobalSearchPage from './pages/ClobalSearchPage/GlobalSearchPage';
import CompanyRegistrationPage from './pages/company/CompanyRegistrationPage/CompanyRegistrationPage';
import CompanyQuestionnairePage from './pages/CompanyQuestionnairePage/CompanyQuestionnairePage';
import ConnectionsPage from './pages/ConnectionsPage/ConnectionsPage';
import CompanyInfoPage from './pages/home/CompanyInfoPage/CompanyInfoPage';
import MyFeedPage from './pages/home/MyFeedPage/MyFeedPage';
import MyJobApplicationDetailsPage from './pages/home/MyJobApplicationDetailsPage/MyJobApplicationDetailsPage';
import PersonalInfoPage from './pages/home/PersonalInfoPage/PersonalInfoPage';
import JobPostApplyPage from './pages/JobPostApplyPage/JobPostApplyPage';
import JobPostsPage from './pages/JobPostsPage/JobPostsPage';
import ProfileSettingsPage from './pages/live-agent/ProfileSettingsPage/ProfileSettingsPage';
import MyJobPostPage from './pages/MyJobPostPage/MyJobPostPage';
import SubscriptionPaymentPage from './pages/subscription/SubscriptionPaymentPage';
import SubscriptionPage from './pages/subscription/SubscriptionPlansPage';
import SubscriptionSuccessfullyPage from './pages/SubscriptionSuccessfullyPage/SubscriptionSuccessfullyPage';
import { selectAuthData } from './store/selectors/authSelector';
import { useThemeContext } from './theme/ThemeContextProvider';

Chart.register(CategoryScale);

const App = () => {
  const { theme } = useThemeContext();
  const authData = useSelector(selectAuthData);
  const role = authData?.user?.role || Cookies.get(CookieEnum.ROLE);

  const pagesForDefaultPath = useMemo(() => {
    switch (role) {
      case UserRoleEnum.COMPANY:
        return (
          <ProtectedRoute>
            <CompanyInfoPage />
          </ProtectedRoute>
        );
      case UserRoleEnum.USER:
        return (
          <ProtectedRoute>
            <MyFeedPage />
          </ProtectedRoute>
        );
      case UserRoleEnum.AGENT:
        return (
          <ProtectedRoute>
            <LiveAgentInProgressPage />
          </ProtectedRoute>
        );
      default:
        return null;
    }
  }, [role]);

  const pagesForMePath = useMemo(() => {
    switch (role) {
      case UserRoleEnum.COMPANY:
        return (
          <ProtectedRoute>
            <CompanyInfoPage />
          </ProtectedRoute>
        );
      case UserRoleEnum.USER:
        return (
          <ProtectedRoute>
            <PersonalInfoPage />
          </ProtectedRoute>
        );
      case UserRoleEnum.AGENT:
        return (
          <ProtectedRoute>
            <LiveAgentInProgressPage />
          </ProtectedRoute>
        );
      default:
        return null;
    }
  }, [role]);

  const pagesForProfilePath = useMemo(() => {
    switch (role) {
      case UserRoleEnum.COMPANY:
        return (
          <ProtectedRoute>
            <CompanyPage />
          </ProtectedRoute>
        );
      case UserRoleEnum.USER:
        return (
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        );
      case UserRoleEnum.AGENT:
        return (
          <ProtectedRoute>
            <LiveAgentInProgressPage />
          </ProtectedRoute>
        );
      default:
        return null;
    }
  }, [role]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Routes>
          <Route path="/" element={<BaseLayout />}>
            <Route path="" element={pagesForDefaultPath}></Route>
            <Route path="me" element={pagesForMePath}>
              <Route
                path="job-application/:applicationId"
                element={
                  <ProtectedRoute>
                    <MyJobApplicationDetailsPage />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="candidates"
              element={
                <ProtectedRoute>
                  <CandidatesPage />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path=":id"
              element={
                <ProtectedRoute>
                  <PersonalInfoPage />{' '}
                </ProtectedRoute>
              }
            />
            <Route path="profile" element={pagesForProfilePath} />
            <Route
              path="account-settings"
              element={
                <ProtectedRoute>
                  <AccountSettingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="connections"
              element={
                <ProtectedRoute>
                  <ConnectionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="global-search"
              element={
                <ProtectedRoute>
                  <GlobalSearchPage />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path="company-questionnaire"
              element={
                <ProtectedRoute>
                  <CompanyQuestionnairePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="subscription-plans"
              element={
                <SubscriptionPaymentProtectedRoute>
                  <SubscriptionPage />{' '}
                </SubscriptionPaymentProtectedRoute>
              }
            />
            <Route
              path="subscription-payment"
              element={
                <SubscriptionPaymentProtectedRoute>
                  <SubscriptionPaymentPage />{' '}
                </SubscriptionPaymentProtectedRoute>
              }
            />
            <Route
              path="subscription-successfully-paid"
              element={
                <SubscriptionPaymentProtectedRoute>
                  <SubscriptionSuccessfullyPage />{' '}
                </SubscriptionPaymentProtectedRoute>
              }
            />{' '}
            <Route
              path="personalized-career-modal"
              element={
                <ProtectedRoute>
                  <PersonalizedCareerModalPage />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path="career-questionnaire"
              element={
                <ProtectedRoute>
                  <CareerQuestionnairePage />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path="career-development"
              element={
                <ProtectedRoute>
                  <CareerDevelopmentPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="live-agent"
              element={
                <ProtectedRoute>
                  <LiveAgentInProgressPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="set-career-development-map"
              element={
                <ProtectedRoute>
                  <SetCareerDevelopmentMapPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="progress"
              element={
                <ProtectedRoute>
                  <ProgressPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="quiz-results"
              element={
                <ProtectedRoute>
                  <QuizResultsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="profile-settings"
              element={
                <ProtectedRoute>
                  <ProfileSettingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="invitations"
              element={
                <ProtectedRoute>
                  <InvitationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="chat"
              element={
                <ProtectedRoute>
                  <ChatPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="job-posts"
              element={
                <ProtectedRoute>
                  <JobPostsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="job-post-apply/:id"
              element={
                <ProtectedRoute>
                  <JobPostApplyPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="my-job-post/:id"
              element={
                <ProtectedRoute>
                  <MyJobPostPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="manage-job-post/:id"
              element={
                <ProtectedRoute>
                  <AddNewJobPostForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="analytics"
              element={
                <ProtectedRoute>
                  <Analytics />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route>
            <Route path="login" element={<LoginPage />} />

            <Route element={<BlankLayout />}>
              <Route path="registration" element={<RegistrationPage />} />
              <Route path="verify-code" element={<VerifyCodePage />} />
              <Route path="forgot-password" element={<ForgotPasswordPage />} />
              <Route path="update-password" element={<UpdatePasswordPage />} />
            </Route>
            <Route
              path="welcome"
              element={
                <ProtectedRoute>
                  <WelcomeToPlatformPage step={1} />
                </ProtectedRoute>
              }
            />
            <Route
              path="welcome-to-platform"
              element={
                <ProtectedRoute>
                  <WelcomeToPlatformPage step={2} />
                </ProtectedRoute>
              }
            />
            <Route
              path="verify-account"
              element={
                <ProtectedRoute>
                  <VerifyAccountPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="registration/company"
              element={<CompanyRegistrationPage />}
            />
            <Route
              path="get-started"
              element={
                <ProtectedRoute>
                  <GetStartedPage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </div>
    </ThemeProvider>
  );
};

export default App;
