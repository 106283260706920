import { Box, Collapse, Typography, useMediaQuery } from '@mui/material';
import JobPostsFilterActionButtons from './JobPostsFilterActionButtons';

import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import JobPostFilterSingleSelect from './JobPostFilterSingleSelect';
import JobPostFilterMultiSelect from './JobPostFilterMultiSelect';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import { useGetIndustriesQuery } from '../../../../../store/apiSlice/jobpost/jobpostApi';
import {
  TLocation,
  TPosition,
} from '../../../../../interfaces/jobpost.interface';
import createArrayFromEnum from '../../../../../utils/createArrayFromEnum';
import { LocationEnum, PositionEnum } from '../../../../../enums/job-post-enum';
import FiltersIcon from '../../../../../components/Icons/FiltersIcon';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';

const JobPostsFilters = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const [searchParams, setSearchParams] = useSearchParams();

  const [industryFilterState, setIndustryFilterState] = useState<string>(
    searchParams.get('industry') ?? ''
  );
  const [positionFilterState, setPositionFilterState] = useState<string>(
    searchParams.get('position') ?? ''
  );
  const [locationFilterState, setLocationFilterState] = useState<string>(
    searchParams.get('location') ?? ''
  );

  const [isScoreExpanded, setIsScoreExpanded] = useState(false);

  const handleFilterChange = (industriesArray: string[]) => {
    if (industriesArray.length > 0)
      setIndustryFilterState(industriesArray.join(','));
    else setIndustryFilterState('');
  };

  const handleFilterReset = () => {
    // setFilterState(initialFilter);

    setIndustryFilterState('');
    setPositionFilterState('');
    setLocationFilterState('');

    setSearchParams(prevParams => {
      prevParams.set('industry', '');
      prevParams.set('position', '');
      prevParams.set('location', '');
      prevParams.set('page', '1');

      return prevParams;
    });
  };

  const handleFilterApply = () => {
    setSearchParams(prevParams => {
      prevParams.set('industry', industryFilterState);
      prevParams.set('position', positionFilterState);
      prevParams.set('location', locationFilterState);
      prevParams.set('page', '1');

      return prevParams;
    });
  };

  const { data: sportsIndustries } = useGetIndustriesQuery();

  const locationsArray: TLocation[] = createArrayFromEnum(
    LocationEnum,
    'location'
  );

  const positionsArray: TPosition[] = createArrayFromEnum(
    PositionEnum,
    'position'
  );

  const toggleExpand = () => {
    setIsScoreExpanded(prevState => !prevState);
  };

  const JobpostFiltersBlock = (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          maxWidth: {
            xs: '100%',
            lg: '314px',
          },
          p: '22px 16px',
          gap: '28px',
        }}
      >
        {!mobileView && (
          <Typography
            component="h3"
            variant="h3"
            color={'text.primary'}
            textAlign={'left'}
          >
            Filters
          </Typography>
        )}

        <JobPostFilterMultiSelect
          label={'industry'}
          placeholder={'Choose Industry(s)'}
          selectItems={sportsIndustries || []}
          handleFilterChange={handleFilterChange}
          filterState={industryFilterState}
        />

        <JobPostFilterSingleSelect
          label={'position'}
          placeholder={'Choose Position'}
          selectItems={positionsArray}
          setFilterState={setPositionFilterState}
          filterState={positionFilterState}
        />
        <JobPostFilterSingleSelect
          label={'location'}
          placeholder={'Choose Location'}
          selectItems={locationsArray}
          setFilterState={setLocationFilterState}
          filterState={locationFilterState}
        />
      </Box>

      <JobPostsFilterActionButtons
        handleFilterReset={handleFilterReset}
        handleFilterApply={handleFilterApply}
      />
    </>
  );

  return (
    <Box
      sx={{
        minWidth: 227,
        width: '100%',
        borderRadius: '1px',
        bgcolor: 'background.white',
      }}
    >
      {mobileView && (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            p: '22px 16px',
            gap: '28px',
          }}
          onClick={toggleExpand}
        >
          <Typography
            component="h3"
            variant="h3"
            color={'text.primary'}
            textAlign={'left'}
            display={'flex'}
            alignItems={'center'}
          >
            <FiltersIcon />
            Filters
          </Typography>
          <Box display="flex" gap="12px">
            <ArrowDownIcon
              sx={{
                transform: isScoreExpanded ? 'rotate(180deg)' : '',
              }}
            />
          </Box>
        </Box>
      )}
      {mobileView ? (
        <Collapse in={isScoreExpanded}>{JobpostFiltersBlock}</Collapse>
      ) : (
        JobpostFiltersBlock
      )}
    </Box>
  );
};

export default JobPostsFilters;
