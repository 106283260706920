import React, { FC } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

interface LeagueTeamItemLabelProps {
  name: string;
  isChecked: boolean;
  onChange: () => void;
}

const LeagueTeamItemLabel: FC<LeagueTeamItemLabelProps> = ({
  name,
  isChecked,
  onChange,
}) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={isChecked} onChange={onChange} />}
      label={
        <Typography variant="body3" color={'text.primary'}>
          {name}
        </Typography>
      }
    />
  );
};

export default LeagueTeamItemLabel;
