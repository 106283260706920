import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  GetQuizByUserIdRes,
  IQuizUserRequest,
} from '../../interfaces/quiz.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const quizApi = createApi({
  reducerPath: 'quizApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_USER_MICROSERVICE_API_URL}/quiz`
  ),
  tagTypes: ['quiz'],

  endpoints: builder => ({
    getQuizByUserId: builder.query<GetQuizByUserIdRes, string>({
      query: userId => `/user/${userId}`,
      providesTags: ['quiz'],
    }),
    createOrUpdateQuiz: builder.mutation<GetQuizByUserIdRes, IQuizUserRequest>({
      query: (data: IQuizUserRequest) => ({
        url: `/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['quiz'],
    }),
  }),
});

export const { useGetQuizByUserIdQuery, useCreateOrUpdateQuizMutation } =
  quizApi;

export default quizApi;
