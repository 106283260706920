import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import { dayjsSchema } from './dayjs-schema';

const isOlderThan16Years = (date: dayjs.Dayjs) => {
  return dayjs().diff(date, 'year') >= 16;
};

const today = dayjs();

export const userSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  birthdate: dayjsSchema
    .required('Birthdate is required')
    .test('complete-date', 'Please enter a complete Birthdate', value => {
      // Ensure that the value is a valid date before performing further checks
      return value && dayjs(value).isValid();
    })
    .test('max-date', 'Birthdate cannot be in the future', value => {
      if (!value) return true; // Allow the user to finish typing
      return (value as Dayjs).isBefore(today);
    })
    .test(
      'is-old-enough',
      'User must be at least 16 years old',
      function (value) {
        if (!value) return true;
        return isOlderThan16Years(value as Dayjs);
      }
    ),
});

const educationSchema = yup.object().shape({
  educationId: yup.string().optional(),
  university: yup.string().required('University is required'),
  specialty: yup.string().required('Specialty is required'),
  startYear: dayjsSchema
    .nullable()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required('Start education date is required')
    .test(
      'max-date',
      'Start education date cannot be in the future',
      value => !value || (value as Dayjs).isBefore(today)
    ),
  endYear: dayjsSchema
    .nullable()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value
    )
    .required('End education date is required')
    .test(
      'is-greater',
      'End education date must be greater than start education date',
      function (value) {
        const { startYear } = this.parent;
        if (!startYear || !value) return true;
        return value > startYear;
      }
    ),
});

export const profileSchema = yup.object().shape({
  firstName: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable()
    .min(2, 'First name must be at least 2 characters'),
  lastName: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable()
    .min(2, 'Last name must be at least 2 characters'),
  birthdate: dayjsSchema
    .optional()
    .nullable()
    .test(
      'is-dayjs',
      'Invalid date format',
      value => value === null || dayjs.isDayjs(value as Dayjs)
    )
    .test(
      'max-date',
      'Birthdate cannot be in the future',
      value => !value || (value as Dayjs).isBefore(today)
    )
    .test(
      'is-old-enough',
      'User must be at least 16 years old',
      function (value) {
        if (!value) return true;
        return isOlderThan16Years(value as Dayjs);
      }
    ),
  bio: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable()
    .min(10, 'Biography must be at least 10 characters'),
  country: yup.string().optional().nullable(),
  city: yup.string().optional().nullable(),
  educations: yup
    .array()
    .of(
      yup.lazy(value => {
        if (Object.keys(value).length === 0) {
          return yup.object().shape({});
        }
        return educationSchema;
      })
    )
    .required(),
});

export const emailsSchema = yup.object().shape({
  emails: yup
    .array()
    .of(
      yup.object().shape({
        value: yup
          .string()
          .email('Invalid email')
          .required('Email is required'),
      })
    )
    .min(1, 'At least one email is required')
    .required('Emails are required')
    .test('unique-emails', 'Emails must be unique', emails => {
      const seen = new Set();
      return emails
        ? emails.every(email => {
            if (seen.has(email.value)) {
              return false;
            }
            seen.add(email.value);
            return true;
          })
        : true;
    }),
});

export const fileSchema = yup.object().shape({
  documents: yup
    .array()
    .of(
      yup.object().shape({
        webkitRelativePath: yup.string().optional(),
        lastModified: yup.number().required(),
        lastModifiedDate: yup.date().required(),
        name: yup.string().required(),
        size: yup
          .number()
          .max(5 * 1024 * 1024, 'File size is too large')
          .required(),
        type: yup
          .string()
          .oneOf(['image/jpeg', 'image/png'], 'Unsupported file format')
          .required(),
      })
    )
    .min(1, 'At least one file is required')
    .required('Files are required'),
});

export const userCompanySchema = yup.object().shape({
  companyName: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable()
    .min(2, 'First name must be at least 2 characters'),
  industryIds: yup.array().of(yup.string().required()).optional().nullable(),
  country: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable(),
  city: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable(),
  address: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable(),
  state: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable(),
  zipCode: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable(),
  phone: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable(),
  contactEmail: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable(),
  website: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable(),
});

// Live Agent
export const liveAgentProfileSettingsSchema = yup.object().shape({
  firstName: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable()
    .min(2, 'First name must be at least 2 characters'),
  lastName: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable()
    .min(2, 'Last name must be at least 2 characters'),
  bio: yup
    .string()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .optional()
    .nullable()
    .min(10, 'Biography must be at least 10 characters'),
});
