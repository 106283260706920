import { Box, Stack, useTheme } from '@mui/material';
import ReviewCard from '../../../shared-components/review/ReviewCard';
import { useGetAllAgentReviewsByAgentIdQuery } from '../../../../../store/apiSlice/reviewApi';
import ListToolBar from '../../../../../components/ListToolbar/ListToolBar';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import SkeletonTabContent from '../SkeletonTabContent';

const sortMenuLabel = 'Show:';

const sortMenuItems = [
  { label: 'Latest', value: 'desc' },
  { label: 'Oldest', value: 'asc' },
];

const FeedBackTab = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const { userId } = useCurrentPageUserId();

  const page = parseInt(searchParams.get('page') || '1', 10);
  const limit = parseInt(searchParams.get('limit') || '10', 10);
  const search = searchParams.get('query') || '';
  const sort = searchParams.get('sortBy') || 'desc';

  const { data: reviews, isLoading } = useGetAllAgentReviewsByAgentIdQuery(
    {
      id: userId ?? '',
      page,
      limit,
      search,
      sort,
    },
    {
      skip: !userId,
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <Box bgcolor={theme.palette.background.white} p="36px">
      <ListToolBar
        subTitle={(reviews?.total ?? 0) + ' reviews'}
        sortMenuLabel={sortMenuLabel}
        sortMenuItems={sortMenuItems}
        isSearchShown={false}
        sx={{ mb: '36px', height: 50 }}
        listToolBarActionsSx={{ width: 'unset' }}
      />
      {isLoading && <SkeletonTabContent />}
      {!isLoading && (
        <Stack component="ul" rowGap="28px">
          {!!reviews?.data.length &&
            reviews.data.map(review => (
              <ReviewCard key={review.id} component="li" cardData={review} />
            ))}
        </Stack>
      )}
    </Box>
  );
};

export default FeedBackTab;
