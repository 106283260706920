import { PaletteMode, Shadows } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const breakpoints = {
  values: {
    xs: 320,
    sm: 480,
    md: 768,
    lg: 1024,
    xl: 1440,
  },
};

export const colors = {
  bgRed: '#E92726',
  bgDarkOrange: '#F5431D',
  bgOrange: '#F5431D',
  bgDark: '#142237',
  bgDarkInput: '#19212D',
  bgWelcome: '#121822',
  gray: '#DADADA',
  textGray: '#A7A7A7',
  textDarkGray: '#71777A',
  disabledInputText: '#A1ABBA',
  inputBGOpacity: 'rgba(255, 255, 255, 0.05)',
  inputBg: '#19212D',
  primaryBg: '#0E131B',
  secondaryBg: '#121822',
  bgGreen: '#4caf50',
  bgGrey: '#EDF2F5',
  bgWhite: '#ffffff',
  buttonBorder: '#D1D6DD',
  selectedBg: '#F7F7F7',
  selectedBgHover: '#F7F7F7',
  bgDarkGrey: '#F5F8FA',
  bgLightOrange: 'rgba(245, 67, 29, 0.15)',
  uploadSecondary: 'rgba(255, 255, 255, 0.05)',
  orangeLightBg: '#FEEDE9',
  lightGreyBg: '#727F93',
  photoFileHoverBg: 'rgba(0, 0, 0, 0.8)',
  flagBg: '#1E242D',
  darkBg: '#434E5F',
  white: '#ffffff',
  stepperNotActiveBg: '#292E38',
  lightGreen: '#28BA2E',
  stepperCompletedBg: '#3ACC40',
  negativeBg: '#222731',
  lightGreenBg: '#DAF0DB',
  darkGreenBg: '#24A729',
  lightText: '#142237',
  fontDark: '#142237',
  orange: '#F5431D',
  lightOrange: '#F5651D',
  appBg: '#F0F1F3',
  dark10: '#E8EBEF',
  dark15: '#DDE1E8',
  dark05: '#F6F7F8',
  dark20: '#D1D6DD',
  dark60: '#727F93', // for caption
  pink: '#FEE3DD',
  darkGreen: '#19851D',
  mint: '#DAF0DB',
  emptyRange: '#BFBFBF',
};

const darkTheme = createTheme({
  palette: {
    success: {
      main: colors.lightGreen,
      light: colors.darkGreenBg,
    },
    error: {
      main: colors.bgOrange,
    },
    primary: {
      main: colors.bgRed,
    },
    secondary: {
      main: colors.bgDark,
    },
    text: {
      primary: colors.dark15,
      secondary: colors.lightGreyBg,
    },
    background: {
      default: colors.primaryBg,
    },
  },
  typography: {
    fontFamily: 'ES Klarheit Kurrent TRIAL',
    h1: {
      fontSize: '34px',
      fontStyle: 'normal',
      fontWeight: 600,
      color: colors.secondaryBg,
    },
    h2: {
      fontSize: '26px',
      fontWeight: 600,
      lineHeight: '28.6px',
      color: 'text.primary',
    },
    h3: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      color: colors.dark15,
      lineHeight: '24px',
    },
    caption: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 700,
      color: colors.secondaryBg,
      lineHeight: 'normal',
    },
    h4: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      color: colors.dark15,
      lineHeight: '1',
      fontFamily: 'Inter',
    },
    body2: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22.4px',
      color: 'text.secondary',
      textAlign: 'center',
    },
    body3: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16.94px',
      color: 'text.primary',
    },
    body4: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '14.52px',
      color: 'text.primary',
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '1px',
          '& .MuiInputBase-root': {
            backgroundColor: colors.inputBg,
            borderColor: colors.inputBg,
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '16.94px',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: colors.inputBg,
            },
            '&:hover fieldset': {
              borderColor: colors.inputBg,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.bgDarkGrey,
            },
            '&.Mui-disabled fieldset': {
              borderColor: colors.inputBg,
              color: colors.lightGreyBg,
              cursor: 'not-allowed',
            },
          },
          '& .MuiSvgIcon-root': {
            color: colors.lightGreyBg,
            backgroundColor: colors.buttonBorder,
          },
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px ${colors.bgDarkInput} inset`,
              WebkitTextFillColor: colors.lightGreyBg,
            },
            '&::placeholder': {
              opacity: 1,
            },
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          padding: '10px',
          color: '#fff',
          '&.success': {
            backgroundColor: colors.bgGreen,
          },
          '&.error': {
            backgroundColor: colors.bgRed,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 600,
          color: colors.bgDarkOrange,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: '50px',
          width: '50px',
          minHeight: '50px',
          minWidth: '50px',
          padding: '12px',
          '&.delete': {
            backgroundColor: colors.inputBGOpacity,
          },
          '&.replace': {
            backgroundColor: colors.inputBGOpacity,
          },
          '&.add': {
            backgroundColor: colors.inputBGOpacity,
          },
          '&.confirm': {
            backgroundColor: colors.inputBGOpacity,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,

          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${colors.bgGrey}`,
          },
          '& .MuiOutlinedInput-input': {
            fontWeight: 400,
            fontSize: '0.875rem',
            boxSizing: 'border-box',
            backgroundColor: colors.bgDarkInput,
            padding: '1.25rem 1rem',
            color: colors.lightGreyBg,
            height: '3.125rem',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondaryBg,
        },
      },
    },
  },
  breakpoints,
});

const lightTheme = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  transitions: {
    create: () => 'none',
    duration: {
      shortest: 0,
      shorter: 0,
      short: 0,
      standard: 0,
      complex: 0,
      enteringScreen: 0,
      leavingScreen: 0,
    },
    easing: {
      easeInOut: 'none',
      easeOut: 'none',
      easeIn: 'none',
      sharp: 'none',
    },
  },
  palette: {
    success: {
      main: colors.lightGreen,
      light: colors.darkGreenBg,
    },
    error: {
      main: colors.bgOrange,
    },
    primary: {
      main: colors.orange,
    },
    secondary: {
      main: colors.bgDark,
    },
    secondary2: {
      main: colors.dark05,
    },
    text: {
      primary: colors.fontDark,
      secondary: colors.dark60,
      accent: colors.bgDarkOrange,
      done: colors.darkGreen,
      disabled: colors.disabledInputText,
    },
    background: {
      default: colors.appBg,
      light: colors.dark05,
      white: colors.white,
      lightDark: colors.dark10,
      dark: colors.dark15,
      darker: colors.dark20,
      mint: colors.mint,
      pink: colors.pink,
    },
    button: {
      primary: colors.orange,
      primaryText: colors.white,
      primaryIconBox: colors.lightOrange,
      primaryHover: colors.lightOrange,
      light: colors.pink,
    },
    stepperConnector: {
      main: '',
      light: colors.stepperCompletedBg,
      dark: colors.dark15,
    },
  },
  typography: {
    h1: {
      fontSize: '34px',
      fontStyle: 'normal',
      fontWeight: 600,
      color: colors.secondaryBg,
    },
    h2: {
      fontFamily: 'ES Klarheit Kurrent TRIAL',
      fontSize: '26px',
      fontWeight: 600,
      lineHeight: '28.6px',
    },
    h3: {
      fontFamily: 'ES Klarheit Kurrent TRIAL',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '22px',
    },
    caption: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 700,
      color: colors.secondaryBg,
      lineHeight: 'normal',
    },
    badge2: {
      fontFamily: 'ES Klarheit Kurrent TRIAL',
      fontSize: '12px',
      fontWeight: 800,
      lineHeight: '12px',
      letterSpacing: '0.04em',
    },
    badge3: {
      fontFamily: 'ES Klarheit Kurrent TRIAL',
      fontSize: '10px',
      fontWeight: 800,
      lineHeight: '10px',
      letterSpacing: '0.04em',
      color: colors.lightOrange,
    },
    h4: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      color: colors.dark15,
      lineHeight: '1',
      fontFamily: 'Inter',
    },
    body1: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.78px',
    },
    body2: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22.4px',
      color: colors.dark60,
    },
    body3: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '16.94px',
      color: colors.dark60,
    },
    body4: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '14.52px',
      fontFamily: 'Inter',
    },
    button: {
      fontSize: '12px',
      fontFamily: 'ES Klarheit Kurrent TRIAL',
      fontStyle: 'normal',
      fontWeight: '800',
      lineHeight: '12px',
      letterSpacing: '0.48px',
      color: 'white',
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: 'outlined' },
              style: {
                '&:hover': {
                  borderColor: colors.dark10,
                  backgroundColor: colors.dark10,
                },
              },
            },
          ],
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.white,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '1px',
          '& .MuiInputBase-root': {
            backgroundColor: colors.dark05,
            borderColor: colors.inputBg,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: colors.dark60,
            },
            '&:hover fieldset': {
              borderColor: colors.dark60,
            },
            '&.Mui-focused fieldset': {
              borderColor: colors.inputBg,
              border: 'none',
            },
            '&.Mui-disabled fieldset': {
              borderColor: colors.dark60,
              color: colors.lightGreyBg,
              cursor: 'not-allowed',
            },
          },
          '& .MuiSvgIcon-root': {
            color: colors.lightGreyBg,
            backgroundColor: colors.buttonBorder,
          },
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: `0 0 0 100px ${colors.dark05} inset`,
              WebkitTextFillColor: colors.lightGreyBg,
            },
            '&::placeholder': {
              opacity: 1,
            },
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          padding: '10px',
          color: '#fff',
          '&.success': {
            backgroundColor: colors.bgGreen,
          },
          '&.error': {
            backgroundColor: colors.bgRed,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 600,
          color: colors.bgDarkOrange,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          height: '50px',
          width: '50px',
          minHeight: '50px',
          minWidth: '50px',
          padding: '12px',
          '&.delete': {
            backgroundColor: colors.inputBGOpacity,
          },
          '&.replace': {
            backgroundColor: colors.inputBGOpacity,
          },
          '&.add': {
            backgroundColor: colors.inputBGOpacity,
          },
          '&.confirm': {
            backgroundColor: colors.inputBGOpacity,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,

          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${colors.buttonBorder}`,
          },
          '& .MuiOutlinedInput-input': {
            fontWeight: 400,
            fontSize: '0.875rem',
            boxSizing: 'border-box',
            backgroundColor: colors.dark05,
            color: colors.fontDark,
            height: '3.125rem',

            '&:disabled': {
              opacity: 0.4,
            },

            '&::placeholder': {
              opacity: 0.4,
            },
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: colors.bgOrange,
          height: 8,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: `${colors.white}`,
          border: `2px solid ${colors.white}`,
          '&:before': {
            display: 'none',
          },
          '&::after': {
            content: '""',
            width: '12px',
            height: '12px',
            backgroundColor: colors.bgOrange,
            borderRadius: '50%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        },
        track: {
          border: 'none',
        },
        rail: {
          opacity: 0.5,
          backgroundColor: `${colors.emptyRange}`,
        },
        mark: {
          backgroundColor: `${colors.emptyRange}`,
          height: 8,
          width: 8,
          '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: colors.bgOrange,
          },
        },
      },
    },
  },
  breakpoints,
});

export const getDesignTokens = (mode: PaletteMode) => ({
  mode,
  ...(mode === 'dark' ? darkTheme : lightTheme),
});
