import { Avatar, ListItemAvatar, ListItemText, Stack } from '@mui/material';

interface SelectItemProps {
  userId: string;
  avatarUrl: string;
  firstName: string;
  lastName: string;
  sport: string;
  isAvatarBase64?: boolean;
}
export const SelectItemUser = ({
  avatarUrl,
  firstName,
  lastName,
  sport,
  isAvatarBase64 = false,
}: SelectItemProps) => (
  <Stack direction="row" spacing="12px" alignItems="center">
    {isAvatarBase64 ? (
      <ListItemAvatar
        sx={{
          width: '2.5rem',
          height: '2.5rem',
          position: 'relative',
          minWidth: 'auto',
        }}
      >
        <Avatar
          alt={'Profile'}
          src={avatarUrl ? `data:image/jpeg;base64,${avatarUrl}` : ''}
          sx={{ width: '100%', height: '100%' }}
        />
      </ListItemAvatar>
    ) : (
      <Avatar
        alt="Profile"
        src={avatarUrl}
        sx={{ width: '2.5rem', height: '2.5rem' }}
      />
    )}

    <Stack spacing={1}>
      <ListItemText
        primary={`${firstName} ${lastName}`}
        secondary={sport}
        primaryTypographyProps={{
          sx: {
            display: 'inline',
            fontSize: '0.875rem',
          },
        }}
        secondaryTypographyProps={{
          sx: {
            display: 'inline',
            textAlign: 'left',
            lineHeight: '140%',
            fontSize: '0.875rem',
          },
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.125rem',
          color: 'text.primary',
        }}
      />
    </Stack>
  </Stack>
);
