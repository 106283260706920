import {
  Avatar,
  Box,
  CardHeader,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import StepProgressBar from '../../../../components/MUIComponents/StepProgressBar';
import { UserInfo as IUserInfo } from '../../LiveAgentInProgressPage/components/user-list/UserListItem';
import React, { useMemo } from 'react';
import { useGetIndividualStageStatusesByUserIdQuery } from '../../../../store/apiSlice/individualStageApi';
import { CareerDevelopmentStatus } from '../../../../interfaces/individual-stage.interface';

interface UserInfoProps {
  userInfo?: IUserInfo;
}

const UserInfo = ({ userInfo }: UserInfoProps) => {
  const { data: individualStageStatuses, isLoading } =
    useGetIndividualStageStatusesByUserIdQuery(userInfo?.id || '', {
      skip: !userInfo?.id,
    });

  const { totalSteps, completedSteps } = useMemo(() => {
    const totalSteps = individualStageStatuses?.length;
    const completedSteps = individualStageStatuses?.filter(
      s => s.status === CareerDevelopmentStatus.COMPLETED
    ).length;

    return { totalSteps, completedSteps };
  }, [individualStageStatuses]);

  return (
    <Box className="user-info">
      {isLoading && skeletonUserInfo}

      {!isLoading && (
        <Grid container width="unset" alignItems="center" columnGap="16px">
          <Grid className="layout-container__user-avatar" item>
            <Avatar
              alt={'alt'}
              src={userInfo?.avatarSrc ?? ''}
              sx={{ width: '64px', height: '64px' }}
            />
          </Grid>
          <Grid className="layout-container__user-info" item>
            <Typography variant="body1">
              {userInfo?.firstName ?? ''} {userInfo?.lastName ?? ''}
            </Typography>
            <Typography variant="body3" marginTop="8px" display="inline-flex">
              {userInfo?.sportNames.join(', ') ?? ''}
            </Typography>
            <StepProgressBar
              totalSteps={totalSteps ?? 0}
              completedSteps={completedSteps ?? 0}
              mt="12px"
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default UserInfo;

const skeletonUserInfo = (
  <CardHeader
    avatar={
      <Skeleton
        animation="wave"
        variant="circular"
        sx={{
          width: '2.5rem',
          height: '2.5rem',
        }}
      />
    }
    action={null}
    title={
      <Skeleton
        animation="wave"
        sx={{
          width: '80%',
          height: '1.125rem',
          marginBottom: '0.375rem',
        }}
      />
    }
    subheader={
      <>
        <Skeleton
          animation="wave"
          sx={{
            width: '40%',
            height: '0.85rem',
          }}
        />
        <Skeleton
          animation="wave"
          sx={{
            paddingTop: '1rem',
            width: '10%',
            height: '0.75rem',
          }}
        />
      </>
    }
    sx={{ padding: '1rem 0.75rem' }}
  />
);
