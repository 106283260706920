import { Box, Container, Stack, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '../../../components/Icons/ArrowLeftIcon';
import { useGetQuizByUserIdQuery } from '../../../store/apiSlice/quizApi';
import Loader from '../../../components/MUIComponents/Loader';
import { useMemo } from 'react';

const QuizResultsPage = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data: quiz, isLoading } = useGetQuizByUserIdQuery(
    state?.userInfo.id ?? '',
    {
      skip: !state?.userInfo.id,
    }
  );

  const formatedQuiz = useMemo(
    () =>
      quiz &&
      Object.entries(quiz).reduce(
        (acc, [key, val]) => {
          const newVal = (val as String).replaceAll('_', ' ').toLowerCase();

          return {
            ...acc,
            [key]: newVal[0].toLocaleUpperCase() + newVal.slice(1),
          };
        },
        {} as typeof quiz
      ),
    [quiz]
  );

  const handleBackBtn = () => {
    return navigate(-1);
  };

  return (
    <Container
      disableGutters
      className="quiz-results-page__container"
      maxWidth={false}
      sx={{ display: 'flex', justifyContent: 'center' }}
    >
      <Box bgcolor="background.white" width={1} maxWidth={872}>
        <Box
          component="header"
          p="22px 36px"
          sx={theme => ({
            borderBottom: `1px solid ${theme.palette.background.lightDark}`,
          })}
        >
          <Link
            to="#"
            onClick={handleBackBtn}
            style={{ color: '#000', textDecoration: 'none' }}
          >
            <Stack direction="row" alignItems="center" gap="16px">
              <ArrowLeftIcon sx={{ width: 17, height: 12 }} />
              <Typography variant="body1">Quiz results</Typography>
            </Stack>
          </Link>
        </Box>

        <Box
          component="main"
          p="36px"
          display="flex"
          flexDirection="column"
          rowGap="36px"
        >
          {isLoading && <Loader />}
          {!isLoading && formatedQuiz && (
            <Stack component="ul" gap="16px">
              <Stack
                component="li"
                gap="12px"
                p="22px"
                sx={theme => ({
                  border: `1px solid ${theme.palette.background.lightDark}`,
                })}
              >
                <Typography variant="body1">
                  What is your primary career goal in the next 2-3 years?
                </Typography>
                <Typography variant="body3">
                  {formatedQuiz.primaryCareerGoal}
                </Typography>
              </Stack>
              <Stack
                component="li"
                gap="12px"
                p="22px"
                sx={theme => ({
                  border: `1px solid ${theme.palette.background.lightDark}`,
                })}
              >
                <Typography variant="body1">
                  How would you rate your current level of networking within the
                  sports industry?
                </Typography>
                <Typography variant="body3">
                  {formatedQuiz.currentNetworkingLevel}
                </Typography>
              </Stack>
              <Stack
                component="li"
                gap="12px"
                p="22px"
                sx={theme => ({
                  border: `1px solid ${theme.palette.background.lightDark}`,
                })}
              >
                <Typography variant="body1">
                  What type of support do you feel you need the most to advance
                  your career?
                </Typography>
                <Typography variant="body3">
                  {formatedQuiz.typeOfSupport}
                </Typography>
              </Stack>
              <Stack
                component="li"
                gap="12px"
                p="22px"
                sx={theme => ({
                  border: `1px solid ${theme.palette.background.lightDark}`,
                })}
              >
                <Typography variant="body1">
                  Which area do you believe requires the most improvement for
                  your career development?
                </Typography>
                <Typography variant="body3">
                  {formatedQuiz.improvementAreaRequire}
                </Typography>
              </Stack>
              <Stack
                component="li"
                gap="12px"
                p="22px"
                sx={theme => ({
                  border: `1px solid ${theme.palette.background.lightDark}`,
                })}
              >
                <Typography variant="body1">
                  How do you prefer to receive career advice and support?
                </Typography>
                <Typography variant="body3">
                  {formatedQuiz.adviceSupport}
                </Typography>
              </Stack>
            </Stack>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default QuizResultsPage;
