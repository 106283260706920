import { useMemo, useState } from 'react';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { useModalManager } from '../../../hooks/useModalManager';
import { VideoAlbum } from '../../../interfaces/media.interface';
import CustomButton from '../../MUIComponents/CustomButton';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import MediaLayout from '../Layouts/MediaLayout';
import AddAlbumModal from '../Modals/AddAlbumModal';
import SingleVideoAlbum from './components/albums-tab/SingleVideoAlbum';
import VideoAlbumArea from './components/albums-tab/VideoAlbumArea';
import VideoArea from './components/videos-tab/VideoArea';
import AddMediaMobileButton from '../AddMediaMobileButton';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { useMediaQuery } from '@mui/material';
import AddVideosModal from '../Modals/AddVideosModal';
import { useNavigate } from 'react-router-dom';

const VIDEO_ALBUM = 'VIDEO_ALBUM';
const ADD_VIDEOS_MODAL = 'ADD_VIDEOS_MODAL';

enum WindowType {
  ALL_VIDEOS,
  ALL_ALBUMS,
}

const Videos = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));
  const [window, setWindow] = useState<WindowType>(WindowType.ALL_VIDEOS);
  const [album, setAlbum] = useState<VideoAlbum | null>(null);
  const [isOpenAlbum, setIsOpenAlbum] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const { currentModal, toggleModal } = useModalManager();

  const navigate = useNavigate();

  const addVideosOrAlbumHandler = useMemo(() => {
    if (window === WindowType.ALL_VIDEOS || album) {
      return () => toggleModal(ADD_VIDEOS_MODAL);
    }

    return () => toggleModal(VIDEO_ALBUM);
  }, [album, window, toggleModal]);

  const handleBackToAlbums = () => {
    navigate(-1);
    setAlbum(null);
    setIsOpenAlbum(false);
  };

  const handleOpenAlbum = () => {
    setIsOpenAlbum(true);
  };

  const addVideosOrAlbumButton = useMemo(
    () => (
      <>
        {mobileView ? (
          <AddMediaMobileButton onClick={addVideosOrAlbumHandler} />
        ) : (
          <CustomButton
            sx={{
              height: 42,
              width: { xs: '100%', md: 124 },
            }}
            variantType={ButtonTypeEnum.SECONDARY}
            fontSize={'0.625rem'}
            onClick={addVideosOrAlbumHandler}
          >
            {`add ${window === WindowType.ALL_VIDEOS || album ? 'videos' : 'album'}`}
          </CustomButton>
        )}
        {currentModal === VIDEO_ALBUM && (
          <AddAlbumModal
            isOpen
            isVideo
            onClose={() => toggleModal(VIDEO_ALBUM)}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}

        {currentModal === ADD_VIDEOS_MODAL && (
          <AddVideosModal
            albumId={album?.id}
            isOpen
            onClose={() => toggleModal(ADD_VIDEOS_MODAL)}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarSeverity={setSnackbarSeverity}
          />
        )}
      </>
    ),
    [
      album,
      window,
      mobileView,
      currentModal,
      toggleModal,
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
      addVideosOrAlbumHandler,
    ]
  );

  return (
    <>
      {isOpenAlbum && album && (
        <SingleVideoAlbum
          album={album}
          setSnackbarOpen={setSnackbarOpen}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbarSeverity={setSnackbarSeverity}
          handleBackToAlbums={handleBackToAlbums}
          addButton={addVideosOrAlbumButton}
        />
      )}
      {!isOpenAlbum && (
        <MediaLayout
          title="Videos"
          isVideos
          isAllMedia={window === WindowType.ALL_VIDEOS}
          onAllMediaClick={() => setWindow(WindowType.ALL_VIDEOS)}
          onYourAlbumsClick={() => setWindow(WindowType.ALL_ALBUMS)}
          addButton={addVideosOrAlbumButton}
        >
          {window === WindowType.ALL_VIDEOS && (
            <VideoArea
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarSeverity={setSnackbarSeverity}
            />
          )}
          {window === WindowType.ALL_ALBUMS && (
            <VideoAlbumArea
              album={album}
              setAlbum={album => setAlbum(album)}
              openAlbum={handleOpenAlbum}
              setSnackbarOpen={setSnackbarOpen}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbarSeverity={setSnackbarSeverity}
            />
          )}
          <SnackbarCustom
            open={snackbarOpen}
            onClose={() => setSnackbarOpen(false)}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </MediaLayout>
      )}
    </>
  );
};

export default Videos;
