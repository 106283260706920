export enum CareerDevelopmentStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  INACTIVE = 'INACTIVE',
}

export interface IndividualStage {
  id: string;
  agentId: string;
  userId: string;
  title: string;
  objective: string;
  outcome: string;
  status: CareerDevelopmentStatus;
  step: number;
  createdAt: string;
  updatedAt: string;
}

export interface GetIndividualStageReq {
  id: string;
  page?: number;
  limit?: number;
  search?: string;
  sort?: string;
  type?: 'user' | 'agent';
}

export interface GetIndividualStageForUserByAgentIdReq
  extends GetIndividualStageReq {}

export interface GetIndividualStageForAgentByUserIdReq
  extends GetIndividualStageReq {}

interface IndividualStageStatus {
  id: string;
  step: number;
  status: CareerDevelopmentStatus;
}

export type GetIndividualStageStatusesByUserIdRes = IndividualStageStatus[];

export interface CreateIndividualStageReq
  extends Omit<
    IndividualStage,
    'id' | 'agentId' | 'createdAt' | 'updatedAt' | 'status' | 'step'
  > {}

export interface CreateIndividualStageRes extends IndividualStage {}

export interface UpdateIndividualStageReq
  extends Partial<Omit<IndividualStage, 'id' | 'createdAt' | 'updatedAt'>> {
  id: string;
}

export interface UpdateIndividualStageRes extends IndividualStage {}
