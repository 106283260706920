export enum NotificationTypeEnum {
  POST_LIKE = 'POST_LIKE',
  COMMENT_LIKE = 'COMMENT_LIKE',
  COMMENT_POST = 'COMMENT_POST',
  FRIEND_INVITATION = 'FRIEND_INVITATION',
  FRIEND_REQUEST_APPROVE = 'FRIEND_REQUEST_APPROVE',
  FRIEND_REQUEST_REJECT = 'FRIEND_REQUEST_REJECT',
  FRIEND_INTRODUCTION = 'FRIEND_INTRODUCTION',
  FRIEND_INTRODUCTION_REQUEST_APPROVE = 'FRIEND_INTRODUCTION_REQUEST_APPROVE',
  FRIEND_INTRODUCTION_REQUEST_REJECT = 'FRIEND_INTRODUCTION_REQUEST_REJECT',
  APPROVE_JOB_APPLICATION = 'APPROVE_JOB_APPLICATION',
  REJECT_JOB_APPLICATION = 'REJECT_JOB_APPLICATION',
  APPLY_JOB = 'APPLY_JOB',
  REJECT_APPLY_JOB = 'REJECT_APPLY_JOB',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  AGENT_ASSIGNMENT = 'AGENT_ASSIGNMENT',
  LEFT_REVIEW = 'LEFT_REVIEW',
  STOP_MENTORING = 'STOP_MENTORING',
}
