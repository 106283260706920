import { Stack } from '@mui/material';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import React from 'react';

interface IJobpostFilterActionButtonsProps {
  handleFilterReset: () => void;
  handleFilterApply: () => void;
}

const FilterActionButtons: React.FC<IJobpostFilterActionButtonsProps> = ({
  handleFilterReset,
  handleFilterApply,
}) => {
  return (
    <Stack
      direction={'column'}
      spacing={'0.75rem'}
      sx={{
        width: '100%',
        p: '1rem',
      }}
    >
      <CustomButton
        buttonWidth="100%"
        variant="contained"
        variantType={ButtonTypeEnum.PRIMARY}
        fullWidth
        onClick={handleFilterApply}
        type="button"
      >
        Apply filters
      </CustomButton>
      <CustomButton
        buttonWidth={'100%'}
        variantType={ButtonTypeEnum.SECONDARY}
        fullWidth
        onClick={handleFilterReset}
      >
        Reset filters
      </CustomButton>
    </Stack>
  );
};

export default FilterActionButtons;
