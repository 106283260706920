import { SxProps } from '@mui/material';
import HomeLeftBar from '../../../shared-components/leftbar/HomeLeftBar';
import ProfileCard from './ProfileCard';

interface ProfileLeftBarProps {
  styles?: SxProps;
  navigatePhotos: () => void;
  navigateVideos: () => void;
  hasAccess?: boolean;
  isBlocked?: boolean;
  hasMutualFriends?: boolean;
}
const ProfileLeftBar = ({
  styles,
  navigatePhotos,
  navigateVideos,
  hasAccess,
  isBlocked,
  hasMutualFriends,
}: ProfileLeftBarProps) => {
  return (
    <HomeLeftBar
      styles={styles}
      cardData={
        <ProfileCard
          hasAccess={hasAccess}
          isBlocked={isBlocked}
          hasMutualFriends={hasMutualFriends}
        />
      }
      navigatePhotos={navigatePhotos}
      navigateVideos={navigateVideos}
    />
  );
};

export default ProfileLeftBar;
