import { Box, Stack } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useGetIndividualStageForAgentByUserIdQuery,
  useUpdateIndividualStageMutation,
} from '../../../../store/apiSlice/individualStageApi';
import CareerDevelopmentCard from '../../shared-components/career-development/CareerDevelopmentCard';
import { UserInfo } from '../../LiveAgentInProgressPage/components/user-list/UserListItem';
import {
  CareerDevelopmentStatus,
  IndividualStage,
} from '../../../../interfaces/individual-stage.interface';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { errorHelper } from '../../../../utils/helper/error-helper';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import LoadMoreButton from './LoadMoreButton';
import SkeletonList from '../../../../components/MUIComponents/SkeletonList';

interface CareerDevelopmentMapProps {
  userInfo?: UserInfo;
}

const LIMIT = 10;

const CareerDevelopmentMap = ({ userInfo }: CareerDevelopmentMapProps) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [individualStagesList, setIndividualStagesList] = useState<
    IndividualStage[]
  >([]);

  const queryParams = useMemo(
    () => ({
      id: userInfo?.id ?? '',
      page: 1,
      limit: LIMIT * page,
      search: '',
    }),
    [page, userInfo?.id]
  );

  const {
    data: individualStages,
    isLoading,
    isFetching,
  } = useGetIndividualStageForAgentByUserIdQuery(queryParams, {
    skip: !userInfo,
  });

  const [updateIndividualStage, { isLoading: isUpdatingIndividualStage }] =
    useUpdateIndividualStageMutation();

  const uniqueObjects = (array: IndividualStage[]) => {
    const map = new Map();
    array.forEach(item => map.set(item.id, item));
    return Array.from(map.values());
  };

  useEffect(() => {
    if (!individualStages || individualStages.data.length <= 0) return;

    setIndividualStagesList(prevStages =>
      uniqueObjects([...prevStages, ...individualStages.data])
    );

    setHasMore(individualStages.total > page * LIMIT);
  }, [individualStages]);

  const onMarkAsCompleted = (stages: IndividualStage[]) => async () => {
    try {
      if (stages.length > 2) {
        return;
      }

      await Promise.all(
        stages.map((stage, index) => {
          if (!stage) return null;
          updateIndividualStage({
            id: stage.id,
            status:
              index === 0
                ? CareerDevelopmentStatus.COMPLETED
                : CareerDevelopmentStatus.IN_PROGRESS,
            title: stage.title,
            step: stage.step,
            objective: stage.objective,
            outcome: stage.outcome,
          });
        })
      );

      setSnackbarMessage('Milestone status successfully changed');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
      setTimeout(() => {
        setSnackbarOpen(false);
      }, 1000);
    } catch (error) {
      const errorMessage = errorHelper(error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      console.error('Failed to update milestone status:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleLoadMore = () => {
    if (!hasMore || isFetching) return;
    setPage(prevPage => prevPage + 1);
  };

  useEffect(() => {
    const index = (page - 1) * LIMIT;
    if (
      individualStagesList &&
      individualStagesList[index] &&
      individualStagesList[index - 1] &&
      individualStagesList[index].status !==
        CareerDevelopmentStatus.IN_PROGRESS &&
      individualStagesList[index].status !==
        CareerDevelopmentStatus.COMPLETED &&
      individualStagesList[index - 1].status ===
        CareerDevelopmentStatus.COMPLETED
    ) {
      updateIndividualStage({
        id: individualStagesList[index].id,
        status: CareerDevelopmentStatus.IN_PROGRESS,
        title: individualStagesList[index].title,
        step: individualStagesList[index].step,
        objective: individualStagesList[index].objective,
        outcome: individualStagesList[index].outcome,
      });
    }
  }, [individualStagesList]);

  return (
    <Box component="ul" className="career-development-map">
      {isLoading && page === 1 && skeletonDevelopmentCards}
      <Stack component="li" rowGap="12px">
        {!isLoading &&
          !!individualStagesList.length &&
          individualStagesList.map((stage, index, arr) => (
            <CareerDevelopmentCard
              key={stage.id}
              component="section"
              cardData={stage}
              viewType="agent"
              onComplete={onMarkAsCompleted([stage, arr[index + 1]])}
              isLoading={isUpdatingIndividualStage}
            />
          ))}
      </Stack>
      {isLoading && page > 1 && skeletonDevelopmentCards}{' '}
      {hasMore && !isLoading && <LoadMoreButton onClick={handleLoadMore} />}
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default CareerDevelopmentMap;

const skeletonDevelopmentCards = (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      alignItems: 'center',
      width: '100%',
      padding: '0 1rem',
    }}
  >
    <SkeletonList
      count={3}
      sx={{
        minHeight: '300px',
      }}
    />
  </Box>
);
