import React from 'react';
import { Box } from '@mui/material';
import SkeletonList from '../../../../components/MUIComponents/SkeletonList';

const SkeletonTabContent = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        alignItems: 'center',
        width: '100%',
        padding: '1.5rem 2rem',
      }}
    >
      <SkeletonList
        count={3}
        sx={{
          minHeight: '60px',
        }}
      />
    </Box>
  );
};

export default SkeletonTabContent;
