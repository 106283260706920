import React, { FC } from 'react';
import { FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { getCountries } from '../../../../../utils/coutry-city-utils';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';
import { ICountry } from 'country-state-city';

interface CountryFilterProps {
  countryFilterState: string;
  setCountryFilterState: (value: string) => void;
}

const CountryFilter: FC<CountryFilterProps> = ({
  countryFilterState,
  setCountryFilterState,
}) => {
  const findCountryByName = (name?: string | null): ICountry | null => {
    if (!name) return null;

    const countries = getCountries();
    return countries.find(country => country.name === name) ?? null;
  };

  return (
    <FormControl
      variant="outlined"
      sx={{ padding: '0 1rem 1.375rem' }}
      fullWidth
    >
      <Autocomplete
        options={getCountries()}
        getOptionLabel={option => option.name || ''}
        isOptionEqualToValue={(option, value) => option.name === value?.name}
        value={findCountryByName(countryFilterState) ?? null}
        onChange={(_, newValue) => {
          setCountryFilterState(newValue?.name ?? '');
        }}
        renderInput={params => (
          <TextField {...params} placeholder="Select a country" />
        )}
        sx={countryAutocompleteStyles}
        popupIcon={<ArrowDownIcon />}
      />
    </FormControl>
  );
};

export default CountryFilter;

const countryAutocompleteStyles = {
  '.MuiInputBase-root': {
    height: '3.125rem',
  },
  '.MuiSvgIcon-root': {
    bgcolor: 'transparent !important',
  },
};
