import { Box, Skeleton, Typography } from '@mui/material';
import { useCurrentPageUserId } from '../../../../hooks/useCurrentPageUserId';
import { useGetUserSportBackgroundsByUserIdQuery } from '../../../../store/apiSlice/sportBackgroundApi';
import { useMemo } from 'react';
import CareersList from './components/CareersList';
import ExperiencesList from './components/ExperiencesList';

const ExperienceTab = () => {
  const { userId } = useCurrentPageUserId();
  const { data: sportBgs, isLoading } = useGetUserSportBackgroundsByUserIdQuery(
    userId ?? '',
    {
      skip: !userId,
    }
  );

  const sortedSportBgs = useMemo(() => {
    if (!sportBgs) return [];
    return [...sportBgs].sort(
      (a, b) =>
        new Date(b.startCareerDate).getTime() -
        new Date(a.startCareerDate).getTime()
    );
  }, [sportBgs]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={{ xs: '16px', xl: '22px' }}
    >
      {isLoading && (
        <Skeleton
          variant="rounded"
          sx={{
            minHeight: '530px',
          }}
        />
      )}

      {sortedSportBgs?.length !== 0 && (
        <>
          <Box
            sx={{
              bgcolor: 'background.white',
              p: { xs: '36px 16px', md: '24px', lg: '36px' },
            }}
            display={'flex'}
            flexDirection={'column'}
          >
            <Typography variant="h3" textTransform="capitalize">
              experience
            </Typography>

            {!!sortedSportBgs?.length && !isLoading && (
              <ExperiencesList sportBackgrounds={sortedSportBgs} />
            )}
          </Box>

          <Box
            sx={{
              bgcolor: 'background.white',
              p: { xs: '36px 16px', md: '24px', lg: '36px' },
            }}
            display={'flex'}
            flexDirection={'column'}
          >
            <Typography variant="h3" textTransform="capitalize">
              teams
            </Typography>

            {!!sortedSportBgs?.length && !isLoading && (
              <CareersList
                careers={sortedSportBgs.flatMap(sportBg => sportBg.careers)}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default ExperienceTab;
