import {
  Box,
  Fade,
  Input,
  Popper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {
  ChangeEvent,
  KeyboardEventHandler,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseX from '../../../../components/Icons/CloseX';
import SendMessageIcon from '../../../../components/Icons/SendMessageIcon';
import UploadFileIcon from '../../../../components/Icons/UploadFileIcon';
import {
  getForwardingFrom,
  getReplyingTo,
  getSelectedChat,
  resetForwardingFrom,
  resetReplyingTo,
} from '../../../../store/apiSlice/chat/chatSlice';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { isGroupChat } from '../ChatNavigation/Chat';
import UploadFileMenu from './UploadFileMenu';

interface IWritingFieldProps {
  onSend?: () => void;
  onChange?: (_: ChangeEvent<HTMLInputElement>) => void;
  messageInput?: string;
  setMessageInput?: () => void;
  defaultMessageInput?: string;
  disableSendIcon?: boolean;
  disableFileIcon?: boolean;
  disableSpacing?: boolean;
  disableReplyView?: boolean;
}

const WritingField = ({
  onSend = () => {},
  onChange = () => {},
  messageInput,
  defaultMessageInput,
  setMessageInput,
  disableSendIcon = false,
  disableFileIcon = false,
  disableSpacing = false,
  disableReplyView = false,
}: IWritingFieldProps) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const searchBarRef = useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const replyingTo = useSelector(getReplyingTo);
  const forwardingFrom = useSelector(getForwardingFrom);
  const selectedChat = useSelector(getSelectedChat);
  const dispatch = useDispatch();

  const handleKeyDown: KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = e => {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      onSend();
    }
  };

  const replyingOrForwardingHeader = useMemo(() => {
    if (!selectedChat) {
      return '';
    }
    if (replyingTo) {
      return `Reply to ${isGroupChat(selectedChat) ? selectedChat.members.find(member => member.id === replyingTo.from)?.firstName : selectedChat.withUser.firstName}`;
    }

    if (forwardingFrom) {
      try {
        return `${forwardingFrom.user.firstName} ${forwardingFrom.user.lastName}`;
      } catch (error) {
        console.log(error);
        return '';
      }
    }
  }, [replyingTo, forwardingFrom, selectedChat]);

  const resetForwardingOrReplyingMessage = useCallback(
    () => dispatch(replyingTo ? resetReplyingTo() : resetForwardingFrom()),
    [dispatch, replyingTo]
  );

  return (
    <>
      {(replyingTo || forwardingFrom) && !disableReplyView && (
        <Box
          bgcolor={'background.light'}
          m={'1rem 1rem 0 1rem'}
          p={'0.75rem'}
          alignItems={'center'}
          justifyContent={'space-between'}
          display={'flex'}
          borderBottom={'1px solid'}
          borderColor={'background.dark'}
        >
          <Box>
            <Typography
              component={'p'}
              maxWidth={'100ch'}
              variant="body3"
              fontWeight={700}
              sx={{ color: 'text.primary' }}
              noWrap
            >
              {replyingOrForwardingHeader}
            </Typography>
            <Typography
              component={'p'}
              maxWidth={'100ch'}
              variant="body4"
              fontWeight={500}
              sx={{ color: 'text.secondary' }}
              noWrap
            >
              {replyingTo && replyingTo.body}
              {forwardingFrom && forwardingFrom.message.body}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={resetForwardingOrReplyingMessage}
          >
            <CloseX stroke={theme.palette.text.secondary} />
          </Box>
        </Box>
      )}
      <Box
        ref={searchBarRef}
        bgcolor={'background.light'}
        m={disableSpacing ? '0' : `${replyingTo ? 0 : '1rem'} 1rem 1rem 1rem`}
        p={'0.75rem'}
        alignItems={'center'}
        justifyContent={'space-between'}
        display={'flex'}
      >
        <Box
          alignItems={'center'}
          display={'flex'}
          gap={'1rem'}
          pr={'1rem'}
          flexGrow={1}
        >
          {!disableFileIcon && (
            <Box
              sx={{
                display: 'flex',
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <UploadFileIcon />
            </Box>
          )}
          <Input
            onKeyDown={handleKeyDown}
            onChange={onChange}
            sx={{ typography: 'body3', color: 'text.primary' }}
            multiline
            maxRows={5}
            fullWidth
            disableUnderline
            placeholder="Write Message"
            value={messageInput}
            defaultValue={defaultMessageInput}
            inputProps={{ style: { scrollbarWidth: 'none' } }}
          />
        </Box>
        {!disableSendIcon && (
          <Box
            sx={{
              display: 'flex',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={onSend}
          >
            <SendMessageIcon />
          </Box>
        )}
        <Popper
          open={menuOpen}
          anchorEl={searchBarRef.current}
          placement={mobileView ? 'bottom' : 'top-start'}
          disablePortal
          sx={{
            width: searchBarRef.current
              ? mobileView
                ? '100%'
                : `max(${searchBarRef.current.offsetWidth * 0.2}, content-max)`
              : 'auto',
            zIndex: 10,
          }}
        >
          <UploadFileMenu
            onClose={() => setMenuOpen(false)}
            clearMessageInput={setMessageInput}
            messageInput={`${messageInput}` || ''}
          />
        </Popper>
        {mobileView && (
          <Fade in={menuOpen} timeout={100}>
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 0,
                transition: 'opacity 100ms ease-in-out',
              }}
            />
          </Fade>
        )}
      </Box>
    </>
  );
};

export default WritingField;
