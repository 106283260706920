import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  CreatePartnershipReq,
  CreatePartnershipRes,
  EndPartnershipAssistanceReq,
  GetAllPartnershipsRes,
} from '../../interfaces/partnership.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const partnershipApi = createApi({
  reducerPath: 'partnershipApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_FRIEND_MICROSERVICE_API_URL}/partnership`
  ),
  tagTypes: ['partnership'],

  endpoints: builder => ({
    getAllParterships: builder.query<
      GetAllPartnershipsRes,
      {
        page?: number;
        limit?: number;
        search?: string;
        sort?: string;
        sortType?: string;
      } | void
    >({
      query: (
        args = {
          page: 1,
          limit: 10,
          search: '',
          sort: 'desc',
          sortType: 'country',
        }
      ) => {
        const {
          page = 1,
          limit = 10,
          search = '',
          sort = 'desc',
          sortType = '',
        } = args || {};
        return `/?page=${page}&limit=${limit}&search=${search}&sort=${sort}&sortType=${sortType}`;
      },
      providesTags: ['partnership'],
    }),
    createPartnership: builder.mutation<
      CreatePartnershipRes,
      CreatePartnershipReq
    >({
      query: (data: CreatePartnershipReq) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['partnership'],
    }),
    endAssistance: builder.mutation<
      any,
      { agentId: string; data: EndPartnershipAssistanceReq }
    >({
      query: ({ agentId, data }) => ({
        url: `end-assistance/${agentId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['partnership'],
    }),
    resumeAssistance: builder.mutation<any, { agentId: string }>({
      query: ({ agentId }) => ({
        url: `resume-assistance/${agentId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['partnership'],
    }),
  }),
});

export const {
  useGetAllPartershipsQuery,
  useCreatePartnershipMutation,
  useEndAssistanceMutation,
  useResumeAssistanceMutation,
} = partnershipApi;

export default partnershipApi;
