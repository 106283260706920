import { CircularProgress, Stack } from '@mui/material';
import { useMemo } from 'react';
import BaseModal from '../../../../../components/MUIComponents/BaseModal';
import { useGetAllAgentReviewsByAgentIdQuery } from '../../../../../store/apiSlice/reviewApi';
import ReviewCard from '../../../shared-components/review/ReviewCard';

interface ReviewModalProps {
  agentId: string;
  isOpen?: boolean;
  onClose: () => void;
}

const ReviewModal = ({
  agentId,
  onClose,
  isOpen = false,
}: ReviewModalProps) => {
  const queryParams = useMemo(
    () => ({
      id: agentId,
      page: 1,
      limit: 10,
      search: '',
      sort: 'desc',
    }),
    [agentId]
  );

  const { data: reviews, isLoading } =
    useGetAllAgentReviewsByAgentIdQuery(queryParams);

  const modalTitle = useMemo(() => {
    return `Reviews (${46})`;
  }, []);

  return (
    <BaseModal
      header={{ text: modalTitle }}
      isOpen={isOpen}
      toggle={onClose}
      disableDefaultFooter
    >
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <Stack component="ul" rowGap="28px">
          {!!reviews?.data.length &&
            reviews.data.map(review => (
              <ReviewCard key={review.id} component="li" cardData={review} />
            ))}
        </Stack>
      )}
    </BaseModal>
  );
};

export default ReviewModal;
