import * as yup from 'yup';

export const passwordValidationMessages = {
  minLength: 'Minimum 8 characters',
  lowercase: '1 lowercase letter',
  uppercase: '1 uppercase letter',
  number: 'Contains a number',
  symbol: 'Contains a symbol',
};

export const baseRegistrationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('email is required'),
  password: yup
    .string()
    .required('password is required')
    .min(8, passwordValidationMessages.minLength)
    .matches(/[a-z]/, passwordValidationMessages.lowercase)
    .matches(/[A-Z]/, passwordValidationMessages.uppercase)
    .matches(/\d/, passwordValidationMessages.number)
    .matches(/[@$!%*?&#]/, passwordValidationMessages.symbol),
  confirmPassword: yup
    .string()
    .required('confirm password is required')
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
});

export const registrationSchema = baseRegistrationSchema.shape({});

export const verifyCodeSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .required('Password is required'),
  verificationCode: yup
    .string()
    .length(6, 'Verification code must be exactly 6 characters')
    .required('Verification code is required'),
});

export const verifyCodeCompanySchema = yup.object().shape({
  verificationCode: yup
    .string()
    .length(6, 'Verification code must be exactly 6 characters')
    .required('Verification code is required'),
});

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
});

export const updatePasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  ConfirmationCode: yup
    .string()
    .length(6, 'Confirmation code must 6 numbers long')
    .required('Confirmation Code is required'),
  newPassword: yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .matches(/[@$!%*?&#]/, 'Password must contain at least one symbol')
    .required('New Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm Password is required'),
});
