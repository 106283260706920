import { SvgIcon, SvgIconProps } from '@mui/material';

const RejectIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      sx={{ width: 14, height: 14, fill: 'none' }}
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        id="Vector"
        stroke="#fff"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M2.918 7h8.167"
      ></path>
    </SvgIcon>
  );
};

export default RejectIcon;
