import { Box, useMediaQuery, useTheme } from '@mui/material';
import { ChatStateEnum } from '../../enums/chat-enum';
import ChatNavigation from './components/ChatNavigation/ChatNavigation';
import ChatHeader from './components/ChatHeader/ChatHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  getChatState,
  getSelectedChat,
  resetForwardingFrom,
  resetReplyingTo,
  setChatState,
} from '../../store/apiSlice/chat/chatSlice';
import { useEffect } from 'react';
import GroupInfo from './components/GroupInfo/GroupInfo';
import ChatBox from './components/ChatBox';
import NewPersonalChat from './components/NewPersonalChat/NewPersonalChat';
import NewGroupChat from './components/NewGroupChat/NewGroupChat';

const ChatPage = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const selectedChat = useSelector(getSelectedChat);
  const chatState = useSelector(getChatState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (chatState === ChatStateEnum.CHAT && !selectedChat) {
      dispatch(setChatState(ChatStateEnum.NEW_PERSONAL));
    }
    dispatch(resetReplyingTo());
    dispatch(resetForwardingFrom());
  }, [dispatch, chatState]);

  return (
    <Box
      sx={{
        ...chatPageStyles,
        margin: mobileView ? '-20px 0' : '0',
      }}
      gridTemplateColumns={'1fr 2.0fr 1.0fr'}
      gridTemplateRows={'100%'}
      width={'100%'}
    >
      <ChatNavigation />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: theme.palette.background.white,
          minHeight: '100%',
          height:
            chatState === ChatStateEnum.GROUP_INFO ? 'fit-content' : '100%',
          borderRadius: '4px',
        }}
      >
        <ChatHeader />
        {chatState === ChatStateEnum.CHAT && selectedChat && <ChatBox />}
        {chatState === ChatStateEnum.NEW_PERSONAL && <NewPersonalChat />}
        {chatState === ChatStateEnum.NEW_GROUP && <NewGroupChat />}
        {chatState === ChatStateEnum.GROUP_INFO && <GroupInfo />}
      </Box>
    </Box>
  );
};

export default ChatPage;

const chatPageStyles = {
  display: {
    xs: 'flex',
    lg: 'grid',
  },
  flexDirection: {
    xs: 'column',
    lg: 'unset',
  },
  justifyContent: {
    xs: 'space-between',
    lg: 'unset',
  },
  maxWidth: {
    lg: 'calc(1440px - 32px)',
    xl: 'calc(1440px - 120px)',
  },
  height: {
    xs: 'calc(100vh - 72px)',
    lg: '75vh',
  },
  marginX: {
    lg: 'auto',
  },
  gap: {
    lg: '1.375rem',
  },
};
