import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Grid, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import {
  RepostMethod,
  repostSchema,
} from '../../../../validation/post-validation';
import { IPost } from '../../../../interfaces/post.interface';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import {
  useGetAllMyChatsQuery,
  useSendMessageWithRepostMutation,
} from '../../../../store/apiSlice/chat/chatApi';
import { useDispatch, useSelector } from 'react-redux';
import userApi from '../../../../store/apiSlice/userApi';
import { useGetFriendsByUserIdQuery } from '../../../../store/apiSlice/friendApi';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { useNavigate } from 'react-router-dom';
import {
  setChatState,
  setSelectedChat,
} from '../../../../store/apiSlice/chat/chatSlice';
import { ChatStateEnum } from '../../../../enums/chat-enum';
import SearchContacts from '../../../ChatPage/components/NewPersonalChat/SearchContacts';
import InviteFriendCard from '../../../ChatPage/components/GroupInfo/InviteFriendCard';
import SkeletonList from '../../../../components/MUIComponents/SkeletonList';
import { IInviteFriendData } from '../../../../interfaces/friend.interface';

const LIMIT = 10;
interface RepostModalProps {
  isOpen?: boolean;
  onClose: () => void;
  post?: IPost;
  isShare?: boolean;
}

interface RepostFormInputs {
  content?: string;
  title?: string | null;
  repostedPostId?: string | null;
  shareOption: RepostMethod;
  friendIdToMessage?: string;
}

const ShareModal: React.FC<RepostModalProps> = ({
  isOpen = false,
  onClose,
  post,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>('');
  const [page, setPage] = useState(1);
  const [friends, setFriends] = useState<IInviteFriendData[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedFriend, setSelectedFriend] =
    useState<IInviteFriendData | null>(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<RepostFormInputs>({
    resolver: yupResolver(repostSchema),
    defaultValues: {
      repostedPostId: post ? post.id : '',
      shareOption: RepostMethod.BY_MESSAGE,
    },
  });

  const { data: myData } = useSelector(userApi.endpoints.getMyProfile.select());
  const { data, isFetching } = useGetFriendsByUserIdQuery(
    { page, limit: LIMIT, search: debouncedSearch },
    { skip: !post }
  );

  const { data: myChats, refetch: refetchChats } = useGetAllMyChatsQuery();
  const dispatch = useDispatch();

  const [sendMessageWithRepost, { isLoading: isSending }] =
    useSendMessageWithRepostMutation();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchValue);
      setPage(1);
    }, 500);
    return () => clearTimeout(handler);
  }, [searchValue]);

  useEffect(() => {
    if (data && data.friends) {
      const newFriends: IInviteFriendData[] = data.friends.map(friend => ({
        id: friend.userId,
        firstName: friend.firstName ?? '',
        lastName: friend.lastName ?? '',
        thumbnail: friend.avatarUrl || '',
        sports: friend?.sports ?? [],
      }));

      setFriends(prevFriends => {
        if (page === 1) {
          return Array.from(
            new Map(newFriends.map(friend => [friend.id, friend])).values()
          );
        }

        return Array.from(
          new Map(
            [...prevFriends, ...newFriends].map(friend => [friend.id, friend])
          ).values()
        );
      });

      if (data.pagination) {
        setHasMore(page < data.pagination.totalPages);
      }
    }
  }, [data, page]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const loadMoreFriends = () => {
    if (!isFetching && hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = async (data: RepostFormInputs) => {
    try {
      if (!selectedFriend) {
        throw new Error('Please select a friend to message');
      }

      if (data.repostedPostId && myData?.id && selectedFriend.id) {
        await sendMessageWithRepost({
          postId: data.repostedPostId,
          description: data?.content ?? '',
          fromUserId: myData.id,
          toUserId: selectedFriend.id,
        });

        await refetchChats();

        if (myChats) {
          const chat = myChats.find(
            chat => chat.withUserId === selectedFriend.id
          );
          if (chat) {
            dispatch(setSelectedChat(chat));
          }
        }
        dispatch(setChatState(ChatStateEnum.CHAT));
        navigate('/chat');

        onClose();
      }

      setSnackbarOpen(true);
      setSnackbarMessage('Repost successful!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);

      onClose();
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    }
  };

  const handleSelectFriend = (friend: IInviteFriendData) => () => {
    setSelectedFriend(friend);
  };

  const modalTitle = useMemo(() => {
    return (
      <>
        <Typography variant="h3" textAlign="left" mb={'22px'}>
          Send to
        </Typography>
        <Divider sx={{ m: { xs: '0 -12px', lg: '0 -36px' } }} />
      </>
    );
  }, []);

  const modalFooter = useMemo(
    () => (
      <Box mt={3}>
        <Grid
          container
          justifyContent="space-between"
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          gap={{ xs: '10px', lg: '0px' }}
          width={'100%'}
        >
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="outlined"
              variantType={ButtonTypeEnum.SECONDARY}
              onClick={onClose}
            >
              Cancel
            </CustomButton>
          </Grid>
          <Grid item>
            <CustomButton
              sx={{ width: { xs: '100%', lg: '220px' } }}
              fullWidth
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              type="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={isSending || !selectedFriend}
            >
              Share
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    ),
    [handleSubmit, isSending, onClose, selectedFriend]
  );

  return (
    <BaseModal
      header={{ component: modalTitle }}
      isOpen={isOpen}
      toggle={onClose}
      footer={{ component: modalFooter }}
      styleEscapeBtnStylesBelow={{ top: 26 }}
      disableEscapeButton={true}
    >
      <Stack spacing="0.5rem">
        <SearchContacts onSearch={handleSearchChange} />
        {isFetching ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.75rem',
              padding: '1.75rem 1rem',
            }}
          >
            <SkeletonList
              count={3}
              sx={{
                minHeight: '60px',
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1.75rem',
              padding: '1.75rem 1rem',
            }}
          >
            <Box gap={'0.625rem'} display={'flex'} flexDirection={'column'}>
              {friends.length > 0
                ? friends.map((friend, index) => (
                    <InviteFriendCard
                      key={index}
                      friend={friend}
                      selectedFriends={selectedFriend ? [selectedFriend] : []}
                      handleSelectFriend={handleSelectFriend(friend)}
                    />
                  ))
                : !isFetching && (
                    <Typography textAlign="center" color="text.secondary">
                      Oops, you have no friends to share this post with.
                    </Typography>
                  )}
            </Box>
            {hasMore && friends.length > 0 && (
              <CustomButton
                sx={{ width: '100%', mt: 2 }}
                fullWidth
                variantType={ButtonTypeEnum.SECONDARY}
                onClick={loadMoreFriends}
                disabled={isFetching}
              >
                Load More
              </CustomButton>
            )}
          </Box>
        )}
      </Stack>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default ShareModal;
