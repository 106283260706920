import { Stack } from '@mui/material';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { throttle } from '../../../utils/throttle';
import { FinalCropData } from './modals/CropBackgroundModal';

interface Data {
  img: HTMLImageElement;
  canvas: HTMLCanvasElement;
  crop: PixelCrop;
}

const throttledSetFinalCropData = throttle(
  (data: Data, callback: (data: FinalCropData) => void) => {
    callback(data);
  },
  500
);

const processCenterCrop = (e: HTMLImageElement) => {
  const { width, height } = e;

  return centerCrop(
    {
      unit: 'px',
      width,
      height: height >= 280 ? 280 : height,
      x: 0,
      y: 0,
    },
    width,
    height
  );
};

// const ASPECT_RATIO = 1;

interface CropperProps {
  setFinalCroppedData: (data: FinalCropData) => void;
  imgSrc: string;
}

const Cropper: React.FC<CropperProps> = ({ setFinalCroppedData, imgSrc }) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const [crop, setCrop] = useState<Crop | undefined>();

  const onComplete = useCallback(
    (completedCrop: PixelCrop) => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        throttledSetFinalCropData(
          {
            img: imgRef.current,
            crop: completedCrop,
            canvas: previewCanvasRef.current,
          },
          setFinalCroppedData
        );
      }
    },
    [setFinalCroppedData]
  );

  useEffect(() => {
    // this should be done once when the img and canvas loaded
    if (imgRef.current && previewCanvasRef.current) {
      onComplete(processCenterCrop(imgRef.current));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgRef.current, previewCanvasRef.current]);

  const onImageLoad = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      setCrop(processCenterCrop(e.currentTarget));
    },
    []
  );

  return (
    <Stack>
      {imgSrc && (
        <Stack display="flex" flexDirection="column" alignItems="center">
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={onComplete}
            // aspect={ASPECT_RATIO}
            minWidth={500}
            maxHeight={280}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{
                maxHeight: '100vh',
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </Stack>
      )}

      {crop && (
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            marginTop: '16px',
          }}
        />
      )}
    </Stack>
  );
};

export default memo(Cropper);
