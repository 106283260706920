import { Box, Container } from '@mui/material';
import { useState } from 'react';
import CareerDevelopmentProfile from '../../../components/FreeAgentPersonalCareer/CareerDevelopmentProfile';
import CareerDevelopmentMainContent from './components/career-development-main/CareerDevelopmentMainContent';
import ThanksCard from './components/career-development-main/ThanksCard';
import ProfileCard from './components/leftbar/ProfileCard';
import { useLocation } from 'react-router-dom';

const CareerDevelopmentPage = () => {
  const { state } = useLocation();
  const [showThanksCard, setShowThanksCard] = useState<boolean>(
    !!state?.isRedirectedFromCompletedQuiz
  );

  return (
    <Container
      disableGutters
      className="career-developement-page__container"
      sx={{
        maxWidth: '1320px !important',
      }}
    >
      <Box
        className={'career-developement-page__layout-container'}
        display="flex"
        flexDirection="row"
        gap={{ lg: '16px', xl: '22px' }}
        width="100%"
      >
        <CareerDevelopmentProfile
          component="aside"
          className="career-developement-page__left-bar"
          cardData={<ProfileCard />}
          width={{ lg: 236, xl: 314 }}
          display={{ xs: 'none', lg: 'block' }}
        />
        {showThanksCard && (
          <ThanksCard
            closeCard={() => setShowThanksCard(false)}
            component="main"
            className="career-developement-page__main-content"
            bgcolor={'white'}
            p={{ lg: '36px' }}
            width={1}
          />
        )}

        {!showThanksCard && (
          <CareerDevelopmentMainContent
            component="main"
            className="career-developement-page__main-content"
            display={'flex'}
            flexDirection={'column'}
            width="100%"
            bgcolor={'white'}
            p={{ xs: '16px', sm: '36px' }}
          />
        )}
      </Box>
    </Container>
  );
};

export default CareerDevelopmentPage;
