import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Input,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SectionLayout from '../../layouts/SectionLayout';
import Label from '../MUIComponents/Label';
import { LabelTypeEnum } from '../../enums/label-enum';
import ProfilePersonalInfoForm from './Forms/ProfilePersonalInfoForm/ProfilePersonalInfoForm';
import ProfileEducationForm from './Forms/ProfileEducationForm/ProfileEducationForm';
import CustomButton from '../MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../enums/button-type-enum';
import { profileSchema } from '../../validation/user-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { ProfilelInfoFormInputs } from '../../interfaces/profile.interface';
import {
  useGetMyProfileQuery,
  useUpdateMyProfileMutation,
} from '../../store/apiSlice/userApi';
import {
  useCreateUpdateEducationMutation,
  useDeleteEducationMutation,
  useGetMyEducationQuery,
} from '../../store/apiSlice/userEducationApi';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import CloseX from '../Icons/CloseX';
import { IEducationInputs } from '../../interfaces/education.interface';
import dayjs from 'dayjs';
import CameraIcon from '../Icons/CameraIcon';
import { useThemeContext } from '../../theme/ThemeContextProvider';

const PersonalInfo = () => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { data: profileInfo, isLoading: isProfileInfoLoading } =
    useGetMyProfileQuery();

  const { data: educationInfo, isLoading: isEducationInfoLoading } =
    useGetMyEducationQuery();

  const [updateMyProfile] = useUpdateMyProfileMutation();
  const [createUpdateEducation] = useCreateUpdateEducationMutation();
  const [deleteEducation] = useDeleteEducationMutation();

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProfilelInfoFormInputs>({
    resolver: yupResolver(profileSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      birthdate: null,
      bio: '',
      country: '',
      city: '',
      educations: [],
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'educations',
  });

  useEffect(() => {
    if (
      !isProfileInfoLoading &&
      !isEducationInfoLoading &&
      profileInfo &&
      educationInfo
    ) {
      const formData: ProfilelInfoFormInputs = {
        firstName: profileInfo?.firstName || null,
        lastName: profileInfo?.lastName || null,
        birthdate: profileInfo?.birthdate
          ? dayjs(profileInfo?.birthdate)
          : null,
        bio: profileInfo?.bio || null,
        country: profileInfo?.country || null,
        city: profileInfo?.city || null,
        educations: educationInfo.length
          ? educationInfo.map(education => ({
              id: education.educationId,
              educationId: education.educationId,
              university: education.university,
              specialty: education.specialty,
              startYear: dayjs(education.startYear),
              endYear: dayjs(education.endYear),
            }))
          : [],
      };

      reset(formData);
    }
  }, [
    isProfileInfoLoading,
    isEducationInfoLoading,
    profileInfo,
    educationInfo,
    reset,
    append,
  ]);

  const addEducation = () => {
    append({});
  };

  const removeEducation = (index: number) => {
    const educationId = (fields[index] as IEducationInputs).educationId;
    remove(index);
    if (educationId) deleteEducation(educationId);
  };

  const onSubmit: SubmitHandler<ProfilelInfoFormInputs> = async data => {
    const { educations, ...profileData } = data;
    const personalInfoFormData = new FormData();

    if (selectedImage) {
      personalInfoFormData.append('file', selectedImage);
    }

    if (profileData.firstName) {
      personalInfoFormData.append('firstName', profileData.firstName);
    }

    if (profileData.lastName) {
      personalInfoFormData.append('lastName', profileData.lastName);
    }

    if (profileData.birthdate) {
      personalInfoFormData.append(
        'birthdate',
        profileData.birthdate.toISOString()
      );
    }

    personalInfoFormData.append('bio', profileData.bio || '');
    personalInfoFormData.append('country', profileData.country || '');
    personalInfoFormData.append('city', profileData.city || '');

    const validEducations = educations
      .filter(education => Object.keys(education).length > 0)
      .map(education => ({
        ...education,
        startYear: (education as IEducationInputs)?.startYear?.toISOString(),
        endYear: (education as IEducationInputs)?.endYear?.toISOString(),
      }));

    try {
      await updateMyProfile(personalInfoFormData).unwrap();
      await createUpdateEducation({ educations: validEducations }).unwrap();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  return (
    <SectionLayout>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={mobileView ? 'flex-start' : 'center'}
          flexDirection={mobileView ? 'column-reverse' : 'row'}
          gap={mobileView ? '12px' : ''}
        >
          <Typography component="h2" variant="h2">
            Personal information
          </Typography>
          <Label
            labelType={
              profileInfo?.verified
                ? LabelTypeEnum.VERIFIED
                : LabelTypeEnum.UNDER_VERIFICATION
            }
            sx={{ fontSize: '0.625rem' }}
          >
            {profileInfo?.verified
              ? LabelTypeEnum.VERIFIED
              : LabelTypeEnum.UNDER_VERIFICATION}
          </Label>
        </Box>
        <Typography component="h3" variant="h3" mt="48px">
          About me
        </Typography>
        <Box mt="40px" display="flex" flexDirection="column">
          <Box
            position="relative"
            display="inline-block"
            width="fit-content"
            sx={{ '&:hover .avatar-overlay': { opacity: 1 } }}
          >
            <Avatar
              alt="Profile"
              src={previewUrl || profileInfo?.avatar?.fileUrl}
              sx={{ width: 140, height: 140 }}
            />
            <Box
              className="avatar-overlay"
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                opacity: 0,
                transition: 'opacity 0.3s',
              }}
            >
              <IconButton
                component="label"
                sx={{
                  width: 60,
                  height: 60,
                  color: 'white',
                  bgcolor: 'text.accent',
                }}
              >
                <CameraIcon />
                <Input
                  type="file"
                  sx={{ display: 'none' }}
                  inputProps={{ accept: 'image/*' }}
                  onChange={handleImageChange}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <ProfilePersonalInfoForm
          control={control}
          register={register}
          errors={errors}
          setValue={setValue}
          watch={watch}
        />
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <Divider sx={{ m: '48px 0' }} />
            <Box display="flex" justifyContent="space-between">
              <Typography component="h3" variant="h3">
                Education {index === 0 ? '' : index + 1}
              </Typography>
              <Box
                width="24px"
                height="24px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{ cursor: 'pointer' }}
                onClick={() => removeEducation(index)}
              >
                <CloseX />
              </Box>
            </Box>
            <ProfileEducationForm
              control={control}
              register={register}
              errors={errors}
              index={index}
            />
          </Fragment>
        ))}
        <CustomButton
          buttonWidth="100%"
          variantType={ButtonTypeEnum.SECONDARY}
          sx={{ borderColor: 'background.dark', mt: '36px' }}
          onClick={addEducation}
          disabled={fields.length === 5}
          type="button"
        >
          add education
        </CustomButton>
        <Divider sx={{ m: '48px 0' }} />
        <Box
          display="flex"
          flexDirection={mobileView ? 'column' : 'row'}
          gap="22px"
        >
          <CustomButton
            buttonWidth={mobileView ? '100%' : '220px'}
            variantType={ButtonTypeEnum.PRIMARY}
            type="submit"
          >
            save
          </CustomButton>
        </Box>
      </Box>
    </SectionLayout>
  );
};

export default PersonalInfo;
