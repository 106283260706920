import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface AutocompleteOptionProps {
  text: string;
  props: React.HTMLAttributes<HTMLLIElement> & { key: any };
}

const AutocompleteOption: FC<AutocompleteOptionProps> = ({ text, props }) => {
  const { theme } = useThemeContext();

  const listItemStyles = {
    width: '100%',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.error.main,
    },
  };
  return (
    <li {...props}>
      <Typography
        variant="body3"
        sx={{
          ...listItemStyles,
        }}
      >
        {text}
      </Typography>
    </li>
  );
};

export default AutocompleteOption;
