import { Avatar, Box, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { IFriendSport } from '../../../../interfaces/friend.interface';

interface ISenderInfoProps {
  firstname?: string;
  lastname?: string;
  avatar?: string;
  sports?: IFriendSport[];
}

const SenderInfo = ({
  firstname = 'firstname',
  lastname = 'lastname',
  avatar,
  sports,
}: ISenderInfoProps) => {
  return (
    <Box display={'flex'} gap={'1rem'}>
      <Avatar
        sx={{ width: '3.125rem', height: '3.125rem' }}
        src={
          !avatar
            ? ''
            : avatar.startsWith('https')
              ? avatar
              : `data:image/jpeg;base64, ${avatar}`
        }
      />
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'0.375rem'}
        justifyContent={'center'}
      >
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '16.94px',
          }}
        >
          {firstname} {lastname}
        </Typography>

        {sports && sports.length > 0 ? (
          <Box
            display={'flex'}
            alignItems={'center'}
            flexDirection={'row'}
            flexWrap={'wrap'}
            gap={'4px'}
            maxWidth={'100%'}
          >
            {sports.map((sport, idx, arr) => (
              <ListItemText
                secondaryTypographyProps={{
                  sx: {
                    textAlign: 'left',
                    lineHeight: '15px',
                    fontSize: '14px',
                  },
                }}
                secondary={
                  <React.Fragment key={sport.id}>
                    <span>{sport.name}</span>
                    {idx < arr.length - 1 && ', '}
                  </React.Fragment>
                }
              />
            ))}
          </Box>
        ) : (
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '14.52px',
              color: '#727F93',
            }}
          >
            Status
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default SenderInfo;
