import * as yup from 'yup';
import { dayjsSchema } from './dayjs-schema';
import dayjs, { Dayjs } from 'dayjs';
import { PostDate } from '../pages/home/shared-components/modals/PostFiltersModal';

export enum RepostMethod {
  ON_WALL = 'ON_WALL',
  BY_MESSAGE = 'BY_MESSAGE',
}

export const createPostSchema = yup.object().shape({
  content: yup.string().required('Content is required'),
  title: yup.string().nullable(),
  repostedPostId: yup.string().nullable(),
});

export const repostSchema = yup.object().shape({
  title: yup.string().nullable(),
  repostedPostId: yup.string().nullable(),
  shareOption: yup
    .string()
    .oneOf(
      [RepostMethod.ON_WALL, RepostMethod.BY_MESSAGE],
      'Invalid share option'
    )
    .required('Share option is required'),
});

export const postFilterSchema = yup.object().shape({
  postDate: yup
    .mixed<PostDate>()
    .oneOf(Object.values(PostDate), 'Invalid post date')
    .required('post date is required'),
  from: yup
    .mixed<Dayjs>()
    .nullable()
    .when('postDate', (postDate, schema) => {
      if (postDate[0] === PostDate.CUSTOM) {
        return schema
          .required("'from' date is required")
          .test(
            'is-dayjs',
            'Invalid date format',
            value => value === null || dayjs.isDayjs(value)
          );
      }
      return schema.nullable();
    }),
  to: yup
    .mixed<Dayjs>()
    .nullable()
    .when('postDate', (postDate, schema) => {
      if (postDate[0] === PostDate.CUSTOM) {
        return schema
          .required("'to' date is required")
          .test(
            'is-dayjs',
            'Invalid date format',
            value => value === null || dayjs.isDayjs(value)
          );
      }
      return schema.nullable();
    }),
});
