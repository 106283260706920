import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../../enums/button-type-enum';
import {
  UserInfo,
  UserItemStatus,
} from '../../../components/user-list/UserListItem';
import BaseActions from '../../../../shared-components/user-list/actions/BaseActions';

interface ActionButtonsProps {
  userInfo: UserInfo;
  userItemStatus: UserItemStatus;
}

const ActionButtons = ({ userInfo, userItemStatus }: ActionButtonsProps) => {
  const navigate = useNavigate();

  const renderDynamicButtons = useMemo(() => {
    switch (userItemStatus) {
      case UserItemStatus.ACTIVE:
        return (
          <CustomButton
            onClick={() => navigate('/progress', { state: { userInfo } })}
            variantType={ButtonTypeEnum.SECONDARY}
            sx={{ p: '12px 16px' }}
          >
            Show Progress
          </CustomButton>
        );
      case UserItemStatus.INACTIVE:
        return (
          <CustomButton
            onClick={() =>
              navigate('/set-career-development-map', { state: { userInfo } })
            }
            variantType={ButtonTypeEnum.PRIMARY}
            sx={{ p: '12px 16px' }}
          >
            Set Career Development Map
          </CustomButton>
        );

      default:
        return null;
    }
  }, [userInfo, userItemStatus, navigate]);

  return (
    <>
      {renderDynamicButtons}
      <BaseActions userInfo={userInfo} />
    </>
  );
};

export default ActionButtons;
