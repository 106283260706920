import React, { FC } from 'react';
import { Box } from '@mui/material';
import SenderInfo from '../ChatHeader/SenderInfo';
import IconWithBackground from '../../../../components/MUIComponents/IconWithBackground';
import CheckIcon from '../../../../components/Icons/CheckIcon';
import { IInviteFriendData } from '../../../../interfaces/friend.interface';

interface InviteFriendsListProps {
  friend: IInviteFriendData;
  handleSelectFriend: (friend: IInviteFriendData) => void;
  selectedFriends: IInviteFriendData[];
}

const InviteFriendCard: FC<InviteFriendsListProps> = ({
  friend,
  handleSelectFriend,
  selectedFriends,
}) => {
  return (
    <Box
      onClick={() => handleSelectFriend(friend)}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0.375rem',
        '&:hover': {
          bgcolor: 'background.light',
          cursor: 'pointer',
        },
      }}
    >
      <SenderInfo
        firstname={friend.firstName}
        lastname={friend.lastName}
        avatar={friend.thumbnail}
        sports={friend?.sports}
      />
      <Box
        display={
          selectedFriends.find(user => user.id === friend.id) ? 'block' : 'none'
        }
      >
        <IconWithBackground
          icon={<CheckIcon />}
          size={'2.5rem'}
          bgcolor={'stepperConnector.light'}
        />
      </Box>
    </Box>
  );
};

export default InviteFriendCard;
