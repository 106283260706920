import { FC, useEffect, useState } from 'react';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  Box,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '../../../MUIComponents/Input';
import {
  CityOption,
  getCitiesOfCountry,
  getCountries,
} from '../../../../utils/coutry-city-utils';
import { ProfilelInfoFormInputs } from '../../../../interfaces/profile.interface';
import { ICountry } from 'country-state-city';
import ArrowDownIcon from '../../../Icons/ArrowDownIcon';
import DateInput from '../../../MUIComponents/DateInput';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import AutocompleteOption from '../../../shared/AutocompleteOption/AutocompleteOption';

interface ProfilePersonalInfoFormProps {
  control: Control<ProfilelInfoFormInputs, any>;
  register: UseFormRegister<ProfilelInfoFormInputs>;
  errors: FieldErrors<ProfilelInfoFormInputs>;
  setValue: UseFormSetValue<ProfilelInfoFormInputs>;
  watch: UseFormWatch<ProfilelInfoFormInputs>;
}

const ProfilePersonalInfoForm: FC<ProfilePersonalInfoFormProps> = ({
  control,
  register,
  errors,
  setValue,
  watch,
}) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState<
    string | undefined
  >();
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const findCountryByName = (
    name: string | null | undefined
  ): ICountry | null => {
    const countries = getCountries();
    return countries.find(country => country.name === name) || null;
  };

  useEffect(() => {
    const subscription = watch(value => {
      const countryName = value.country;
      const country = findCountryByName(countryName);
      setSelectedCountryCode(country?.isoCode || undefined);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <Box
      mt="48px"
      display="flex"
      flexDirection="column"
      gap={mobileView ? '28px' : '36px'}
    >
      <Box
        display="flex"
        flexDirection={mobileView ? 'column' : 'row'}
        gap={mobileView ? '28px' : '22px'}
      >
        <Input
          caption="First Name:"
          containerFlex="1"
          variant="outlined"
          fullWidth
          placeholder="First Name"
          register={register('firstName')}
          error={!!errors.firstName}
          helperText={errors.firstName ? errors.firstName.message : ''}
        />
        <Input
          caption="Last Name:"
          containerFlex="1"
          variant="outlined"
          fullWidth
          placeholder="Last Name"
          register={register('lastName')}
          error={!!errors.lastName}
          helperText={errors.lastName ? errors.lastName.message : ''}
        />
      </Box>
      <Box display="flex">
        <Box flex={mobileView ? '1' : '0 1 23%'}>
          <DateInput<ProfilelInfoFormInputs>
            control={control}
            label="Birthday:"
            name="birthdate"
            error={errors.birthdate as FieldError}
            helperText={errors.birthdate?.message}
            placeholder="DD.MM.YYYY"
          />
        </Box>
      </Box>
      <Box display="flex">
        <Input
          caption="Your bio:"
          containerFlex="1"
          variant="outlined"
          fullWidth
          multiline
          rows={5}
          margin="none"
          placeholder="Few words about yourself"
          register={register('bio')}
          error={!!errors.bio}
          helperText={errors.bio ? errors.bio.message : ''}
          inputProps={{ p: 0 }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection={mobileView ? 'column' : 'row'}
        gap={mobileView ? '28px' : '22px'}
      >
        <Controller
          name="country"
          control={control}
          render={({ field }) => {
            const countryValue =
              typeof field.value === 'string'
                ? findCountryByName(field.value)
                : field.value;

            return (
              <FormControl
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
                variant="outlined"
                fullWidth
                error={!!errors.country}
              >
                <Typography
                  variant="caption"
                  sx={{
                    padding: '0 2px',
                    mb: '12px',
                    color: field.disabled ? 'text.disabled' : 'text.primary',
                  }}
                >
                  Countries:
                </Typography>
                <Autocomplete
                  {...field}
                  options={getCountries()}
                  getOptionLabel={option => option.name || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value?.name
                  }
                  value={countryValue || null}
                  onChange={(event, newValue) => {
                    setSelectedCountryCode(newValue?.isoCode);
                    field.onChange(newValue ? newValue.name : null);
                    // Clear the city if the country is removed
                    if (!newValue) {
                      setValue('city', '');
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Select a country"
                      error={!!errors.country}
                      helperText={errors.country ? errors.country.message : ''}
                    />
                  )}
                  renderOption={(props, option) => (
                    <AutocompleteOption text={option.name} props={props} />
                  )}
                  sx={{
                    '.MuiInputBase-root': {
                      height: '50px',
                    },
                    '.MuiSvgIcon-root': {
                      bgcolor: 'transparent !important',
                    },
                  }}
                  popupIcon={<ArrowDownIcon />}
                />
              </FormControl>
            );
          }}
        />
        <Controller
          name="city"
          control={control}
          render={({ field }) => {
            const cityValue =
              typeof field.value === 'string'
                ? { name: field.value, id: '' }
                : (field.value as CityOption | null);
            return (
              <FormControl
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
                variant="outlined"
                fullWidth
                error={!!errors.city}
              >
                <Typography
                  variant="caption"
                  sx={{
                    padding: '0 2px',
                    mb: '12px',
                    color: selectedCountryCode
                      ? 'text.primary'
                      : 'text.disabled',
                  }}
                >
                  City:
                </Typography>
                <Autocomplete
                  {...field}
                  options={
                    selectedCountryCode
                      ? getCitiesOfCountry(selectedCountryCode) || []
                      : []
                  }
                  getOptionLabel={option => option?.name || ''}
                  isOptionEqualToValue={(option, value) =>
                    option.name === value?.name
                  }
                  value={cityValue || null}
                  onChange={(event, newValue) => {
                    field.onChange(newValue ? newValue.name : null);
                  }}
                  renderOption={(props, option) => (
                    <AutocompleteOption text={option.name} props={props} />
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      placeholder="Select a city"
                      error={!!errors.city}
                      helperText={errors.city ? errors.city.message : ''}
                      disabled={!selectedCountryCode}
                    />
                  )}
                  disabled={!selectedCountryCode}
                  sx={{
                    '.MuiInputBase-root': {
                      height: '50px',
                    },
                    '.MuiSvgIcon-root': {
                      bgcolor: 'transparent !important',
                    },
                  }}
                  popupIcon={<ArrowDownIcon />}
                />
              </FormControl>
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default ProfilePersonalInfoForm;
