export const getOrdinalMilestone = (index: number): string => {
  const ordinalNumbers = [
    'First',
    'Second',
    'Third',
    'Fourth',
    'Fifth',
    'Sixth',
    'Seventh',
    'Eighth',
    'Ninth',
    'Tenth',
    'Eleventh',
    'Twelfth',
    'Thirteenth',
    'Fourteenth',
    'Fifteenth',
    'Sixteenth',
    'Seventeenth',
    'Eighteenth',
    'Nineteenth',
    'Twentieth',
    'Twenty-first',
    'Twenty-second',
    'Twenty-third',
    'Twenty-fourth',
    'Twenty-fifth',
    'Twenty-sixth',
    'Twenty-seventh',
    'Twenty-eighth',
    'Twenty-ninth',
    'Thirtieth',
    'Thirty-first',
    'Thirty-second',
    'Thirty-third',
    'Thirty-fourth',
    'Thirty-fifth',
    'Thirty-sixth',
    'Thirty-seventh',
    'Thirty-eighth',
    'Thirty-ninth',
    'Fortieth',
    'Forty-first',
    'Forty-second',
    'Forty-third',
    'Forty-fourth',
    'Forty-fifth',
    'Forty-sixth',
    'Forty-seventh',
    'Forty-eighth',
    'Forty-ninth',
    'Fiftieth',
    'Fifty-first',
    'Fifty-second',
    'Fifty-third',
    'Fifty-fourth',
    'Fifty-fifth',
    'Fifty-sixth',
    'Fifty-seventh',
    'Fifty-eighth',
    'Fifty-ninth',
    'Sixtieth',
    'Sixty-first',
    'Sixty-second',
    'Sixty-third',
    'Sixty-fourth',
    'Sixty-fifth',
    'Sixty-sixth',
    'Sixty-seventh',
    'Sixty-eighth',
    'Sixty-ninth',
    'Seventieth',
    'Seventy-first',
    'Seventy-second',
    'Seventy-third',
    'Seventy-fourth',
    'Seventy-fifth',
    'Seventy-sixth',
    'Seventy-seventh',
    'Seventy-eighth',
    'Seventy-ninth',
    'Eightieth',
    'Eighty-first',
    'Eighty-second',
    'Eighty-third',
    'Eighty-fourth',
    'Eighty-fifth',
    'Eighty-sixth',
    'Eighty-seventh',
    'Eighty-eighth',
    'Eighty-ninth',
    'Ninetieth',
    'Ninety-first',
    'Ninety-second',
    'Ninety-third',
    'Ninety-fourth',
    'Ninety-fifth',
    'Ninety-sixth',
    'Ninety-seventh',
    'Ninety-eighth',
    'Ninety-ninth',
    'One Hundredth',
  ];

  if (index < 1 || index > 100) {
    return `${index}th milestone`;
  }

  return ordinalNumbers[index - 1] + ' milestone';
};
