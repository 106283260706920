import CommentNotificationIcon from '../../components/Icons/CommentNotificationIcon';
import FriendNotificationIcon from '../../components/Icons/FriendNotificationIcon';
import LikeNotificationIcon from '../../components/Icons/LikeNotificationIcon';
import { NotificationTypeEnum } from '../../enums/notification-type.enum';
import RejectIcon from '../../components/Icons/RejectIcon';

const getNotificationDetails = (type: NotificationTypeEnum) => {
  switch (type) {
    case NotificationTypeEnum.POST_LIKE:
      return {
        message: 'liked your post',
        Icon: LikeNotificationIcon,
        color: 'primary.main',
      };
    case NotificationTypeEnum.COMMENT_LIKE:
      return {
        message: 'liked your comment',
        Icon: LikeNotificationIcon,
        color: 'primary.main',
      };
    case NotificationTypeEnum.COMMENT_POST:
      return {
        message: 'commented on your post',
        Icon: CommentNotificationIcon,
        color: 'text.primary',
      };
    case NotificationTypeEnum.FRIEND_INVITATION:
      return {
        message: 'sent you a friend invitation',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.FRIEND_REQUEST_APPROVE:
      return {
        message: 'approved your friend request',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.FRIEND_REQUEST_REJECT:
      return {
        message: 'rejected your friend request',
        Icon: RejectIcon,
        color: 'text.accent',
      };
    case NotificationTypeEnum.FRIEND_INTRODUCTION:
      return {
        message: 'sent an introduction request',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.FRIEND_INTRODUCTION_REQUEST_APPROVE:
      return {
        message: 'has accepted your request',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.FRIEND_INTRODUCTION_REQUEST_REJECT:
      return {
        message: 'has rejected your request',
        Icon: RejectIcon,
        color: 'text.accent',
      };
    case NotificationTypeEnum.APPROVE_JOB_APPLICATION:
      return {
        message: 'approved your job application',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.REJECT_JOB_APPLICATION:
      return {
        message: 'rejected your job application',
        Icon: RejectIcon,
        color: 'text.accent',
      };
    case NotificationTypeEnum.APPLY_JOB:
      return {
        message: 'applied for a job',
        Icon: FriendNotificationIcon,
        color: 'text.done',
      };
    case NotificationTypeEnum.REJECT_APPLY_JOB:
      return {
        message: 'cancelled your job application',
        Icon: RejectIcon,
        color: 'text.accent',
      };
    case NotificationTypeEnum.CHAT_MESSAGE:
      return {
        message: 'new message in chat',
        Icon: CommentNotificationIcon,
        color: 'text.primary',
      };

    case NotificationTypeEnum.AGENT_ASSIGNMENT:
      return {
        message: 'has been assigned to you as a Live Agent.',
        Icon: CommentNotificationIcon,
        color: 'text.primary',
      };
    case NotificationTypeEnum.LEFT_REVIEW:
      return {
        message: 'has left a new review for you.',
        Icon: CommentNotificationIcon,
        color: 'text.primary',
      };
    case NotificationTypeEnum.STOP_MENTORING:
      return {
        message: 'has stopped mentoring you.',
        Icon: RejectIcon,
        color: 'text.accent',
      };
    default:
      return { message: 'unknown notification', Icon: null };
  }
};

export default getNotificationDetails;
