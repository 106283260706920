import { Box, Collapse, Typography, useMediaQuery } from '@mui/material';
import FilterActionButtons from './FilterActionButtons';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import CandidateSportFilter from './CandidateSportFilter';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import FiltersIcon from '../../../../../components/Icons/FiltersIcon';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';
import { useGetPaginatedSportsQuery } from '../../../../../store/apiSlice/sportApi';
import CandidateLocationFilter from './CandidateLocationFilter';
import CandidateExperienceFilter from './CandidateExperienceFilter';
import SkeletonList from '../../../../../components/MUIComponents/SkeletonList';

const CandidatesFilters = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const [searchParams, setSearchParams] = useSearchParams();

  const [sportsFilterState, setSportsFilterState] = useState<string>(
    searchParams.get('sports') ?? ''
  );
  const [positionFilterState, setPositionFilterState] = useState<string>(
    searchParams.get('position') ?? ''
  );
  const [countryFilterState, setCountryFilterState] = useState<string>(
    searchParams.get('country') ?? ''
  );
  const [cityFilterState, setCityFilterState] = useState<string>(
    searchParams.get('city') ?? ''
  );

  const [isScoreExpanded, setIsScoreExpanded] = useState(false);
  const [limit, setLimit] = useState<number>(5);

  const [from, setFrom] = useState<number>(
    Number(searchParams.get('from')) ?? 0
  );
  const [to, setTo] = useState<number>(Number(searchParams.get('to')) ?? 0);

  const handleFilterReset = () => {
    setSportsFilterState('');
    setPositionFilterState('');
    setCountryFilterState('');
    setCityFilterState('');
    setLimit(5);
    setFrom(0);
    setTo(0);

    setSearchParams(prevParams => {
      prevParams.set('sports', '');
      prevParams.set('position', '');
      prevParams.set('country', '');
      prevParams.set('city', '');
      prevParams.set('from', '');
      prevParams.set('to', '');
      prevParams.set('page', '1');

      return prevParams;
    });
  };

  const handleFilterApply = () => {
    setSearchParams(prevParams => {
      prevParams.set('sports', sportsFilterState);
      prevParams.set('position', positionFilterState);
      prevParams.set('country', countryFilterState);
      prevParams.set('city', cityFilterState);
      prevParams.set('from', from.toString());
      prevParams.set('to', to.toString());
      prevParams.set('page', '1');

      return prevParams;
    });
  };

  const {
    data: sportsPaginationResponse,
    isLoading,
    isError,
    error,
  } = useGetPaginatedSportsQuery({
    page: 1,
    limit,
    search: '',
    sort: 'asc',
  });

  const handleShowMore = () => {
    setLimit(prevLimit => prevLimit + 5);
  };

  const toggleExpand = () => {
    setIsScoreExpanded(prevState => !prevState);
  };

  const candidatesFiltersBlock = (
    <>
      <Box display={'flex'} flexDirection={'column'}>
        {!mobileView && (
          <Typography
            component="h3"
            variant="h3"
            color={'text.primary'}
            textAlign={'left'}
            sx={{
              padding: '1.375rem 1rem',
              gap: '22px',
            }}
            borderBottom={`1px solid ${theme.palette.background.lightDark}`}
          >
            Filters
          </Typography>
        )}

        {isLoading ? (
          skeletonFilters
        ) : (
          <>
            <CandidateSportFilter
              sports={sportsPaginationResponse?.data ?? []}
              isLoading={isLoading}
              isError={isError}
              totalSportsCount={sportsPaginationResponse?.total ?? 0}
              sportsFilterState={sportsFilterState}
              setSportsFilterState={setSportsFilterState}
              label={'sports'}
              error={error}
              onShowMore={handleShowMore}
            />

            <CandidateExperienceFilter
              from={from}
              to={to}
              setFrom={setFrom}
              setTo={setTo}
            />

            <CandidateLocationFilter
              setCountryFilterState={setCountryFilterState}
              setCityFilterState={setCityFilterState}
              countryFilterState={countryFilterState}
              cityFilterState={cityFilterState}
            />
          </>
        )}
      </Box>

      <FilterActionButtons
        handleFilterReset={handleFilterReset}
        handleFilterApply={handleFilterApply}
      />
    </>
  );

  return (
    <Box
      sx={{
        minWidth: 227,
        width: mobileView ? '100%' : 314,
        borderRadius: '1px',
        bgcolor: 'background.white',
      }}
    >
      {mobileView && (
        <Box
          display={'flex'}
          sx={{
            gap: '22px',
          }}
        >
          <Box
            width={'100%'}
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              padding: '1.375rem 1rem',
            }}
          >
            <Typography
              component="h3"
              variant="h3"
              color={'text.primary'}
              textAlign={'left'}
              display={'flex'}
              alignItems={'center'}
            >
              <FiltersIcon />
              Filters
            </Typography>
            <Box display="flex" gap="12px" onClick={toggleExpand}>
              <ArrowDownIcon
                sx={{
                  transform: isScoreExpanded ? 'rotate(180deg)' : '',
                  width: '1.125rem',
                  height: '1.125rem',
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      {mobileView ? (
        <Collapse in={isScoreExpanded}>{candidatesFiltersBlock}</Collapse>
      ) : (
        candidatesFiltersBlock
      )}
    </Box>
  );
};

export default CandidatesFilters;

const skeletonFilters = (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1.375rem',
      padding: '1.375rem 1rem',
    }}
  >
    <SkeletonList
      count={3}
      sx={{
        minHeight: '2rem',
      }}
    />
  </Box>
);
