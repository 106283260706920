import { Box, Container, SxProps } from '@mui/material';
import QuiltedImageList from '../../../../components/MUIComponents/QuiltedImageList';
import { useGetPostByIdQuery } from '../../../../store/apiSlice/post/postApi';
import { useGetUserInfoQuery } from '../../../../store/apiSlice/userApi';
import React from 'react';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import PostHeader from './PostHeader';
import PostBodyText from './PostBodyText';
import { IPost } from '../../../../interfaces/post.interface';

type IRepostedPostProps = (
  | {
      previewPost: IPost; // If previewPost is present
      repostedPostId?: string; // repostedPostId is optional
    }
  | {
      previewPost?: IPost; // previewPost is optional
      repostedPostId: string; // repostedPostId is required
    }
) & {
  containerSx?: SxProps;
  ownerNameSx?: SxProps;
};

const RepostedPost = (props: IRepostedPostProps) => {
  const { theme } = useThemeContext();
  const {
    previewPost = null,
    repostedPostId,
    containerSx,
    ownerNameSx,
  } = props;
  const { data: post } = useGetPostByIdQuery(repostedPostId || '', {
    skip: !!previewPost || !repostedPostId,
  });
  const repostedPost = previewPost ? previewPost : post;

  const { data: userData } = useGetUserInfoQuery(repostedPost?.userId as string, {
    skip: !repostedPost,
  });


  const filesArray = repostedPost?.postFiles.map((file, idx) => {
    const fileObj = { id: file.id, img: file.fileUrl, title: file.fileName };
    if (idx === 4 || idx === 5 || idx === 11) return { ...fileObj, cols: 2 };
    if (idx === 8 || idx === 5) return { ...fileObj, rows: 2, cols: 2 };
    return fileObj;
  });

  if (repostedPost)
    return (
      <Container
        disableGutters
        sx={{ padding: '16px', bgcolor: 'background.light', ...containerSx }}
      >
        <PostHeader
          userData={userData}
          post={repostedPost}
          ownerNameSx={ownerNameSx}
        />

        <Box component="article">
          <PostBodyText
            post={repostedPost}
            sx={{
              padding: '1rem 0',
            }}
          />

          <Box component="figure" width={'100%'}>
            <QuiltedImageList itemData={filesArray || []} />
          </Box>
        </Box>
      </Container>
    );
  else return null;
};

export default RepostedPost;
