import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import FiltersIcon from '../../../../components/Icons/FiltersIcon';
import SortIcon from '../../../../components/Icons/SortIcon';
import { SORT_OPTIONS } from '../../../../enums/sort-options-enum';
import { useModalManager } from '../../../../hooks/useModalManager';
import { colors } from '../../../../theme/theme';
import PostFiltersModal from '../modals/PostFiltersModal';
import { Filter } from '../tabs/PostsTab';

export interface ISetFilter {
  (filter: Filter): void;
}

enum PostToolbarModals {
  POST_FILTERS = 'POST_FILTERS',
}
interface IPostsToolbarProps {
  postsCount: number | null;
  sort: SORT_OPTIONS;
  setSort: React.Dispatch<React.SetStateAction<SORT_OPTIONS>>;
  setFilter: ISetFilter;
}

const PostsToolbar = ({
  sort,
  setSort,
  setFilter,
  postsCount,
}: IPostsToolbarProps) => {
  const { currentModal, toggleModal } = useModalManager();
  const sortText: { [key in SORT_OPTIONS]: string } = {
    [SORT_OPTIONS.DESC]: 'Latest',
    [SORT_OPTIONS.ASC]: 'Oldest',
  };

  const openPostFilters = () => {
    toggleModal(PostToolbarModals.POST_FILTERS);
  };

  return (
    <>
      <PostFiltersModal
        isOpen={currentModal === PostToolbarModals.POST_FILTERS}
        onClose={() => toggleModal(PostToolbarModals.POST_FILTERS)}
        setFilter={setFilter}
      />
      <Box
        className="profile-posts-box"
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0.75rem 1rem',
          backgroundColor: '#FFFFFF',
          height: '3.75rem',
          color: '#142237',
        }}
      >
        <Box className="profile-posts-title" flex="1">
          {postsCount && (
            <Typography variant="h3">
              {postsCount} {postsCount >= 2 ? 'Posts' : 'Post'}
            </Typography>
          )}
        </Box>
        <Box className="profile-posts-actions" display="flex" gap="30px">
          <Button
            onClick={() =>
              setSort(
                sort === SORT_OPTIONS.ASC ? SORT_OPTIONS.DESC : SORT_OPTIONS.ASC
              )
            }
          >
            <Typography
              variant="subtitle2"
              display="flex"
              alignItems="center"
              columnGap="6px"
              textTransform="capitalize"
              color={colors.dark60}
            >
              <SortIcon />
              {sortText[sort]}
            </Typography>
          </Button>
          <Button>
            <Typography
              variant="subtitle2"
              display="flex"
              alignItems="center"
              columnGap="6px"
              textTransform="capitalize"
              color={colors.dark60}
              onClick={openPostFilters}
            >
              <FiltersIcon />
              Filter
            </Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default PostsToolbar;
