import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import SortIcon from '../Icons/SortIcon';

interface IAdditionalListSortIconProps {
  queryParam?: string;
}

const DefaultSortIcon: React.FC<IAdditionalListSortIconProps> = ({
  queryParam = 'sortBy',
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialSortOrder = searchParams.get(queryParam) || 'asc';
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>(
    initialSortOrder as 'asc' | 'desc'
  );

  useEffect(() => {
    setSortOrder(initialSortOrder as 'asc' | 'desc');
  }, [initialSortOrder]);

  const handleSortToggle = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    const newParams = new URLSearchParams(searchParams);
    newParams.set(queryParam, newSortOrder);
    setSearchParams(newParams);
  };

  return (
    <IconButton
      onClick={handleSortToggle}
      sx={{
        transform: sortOrder === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <SortIcon />
    </IconButton>
  );
};

export default DefaultSortIcon;
