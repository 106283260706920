import {
  Box,
  BoxProps,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';
import {
  CareerDevelopmentStatus,
  IndividualStage,
} from '../../../../interfaces/individual-stage.interface';
import { colors } from '../../../../theme/theme';
import CardStatus from './CardStatus';
import StepIndicator from './StepIndicator';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';

interface CareerDevelopmentCardProps extends BoxProps {
  cardData: Omit<IndividualStage, 'status'> & {
    status: CareerDevelopmentStatus;
    step: number;
  };
  viewType?: 'agent' | 'user';
  isLoading?: boolean;
  // onComplete?: (id: string) => void;
  onComplete?: (stage: IndividualStage) => void;
}
// to do: move to src/components/career
const CareerDevelopmentCard = ({
  cardData,
  viewType = 'user',
  isLoading,
  onComplete,
  ...rest
}: CareerDevelopmentCardProps) => {
  const theme = useTheme();
  const {
    cardBgColor,
    stepIndicatorBgColor,
    stepIndicatorBorderColor,
    textColor,
  } = useMemo(() => {
    switch (cardData.status) {
      case CareerDevelopmentStatus.COMPLETED: {
        return {
          cardBgColor: theme.palette.background.mint,
          stepIndicatorBgColor: theme.palette.success.main,
          stepIndicatorBorderColor: theme.palette.background.white,
          textColor: colors.white,
        };
      }
      case CareerDevelopmentStatus.IN_PROGRESS: {
        return {
          cardBgColor: theme.palette.background.light,
          stepIndicatorBgColor: colors.darkBg,
          stepIndicatorBorderColor: theme.palette.background.lightDark,
          textColor: colors.white,
        };
      }
      default: {
        return {
          cardBgColor: 'unset',
          stepIndicatorBgColor: theme.palette.background.lightDark,
          stepIndicatorBorderColor: theme.palette.background.light,
          textColor: 'unset',
        };
      }
    }
  }, [cardData.status, theme]);

  const showStatus = () => {
    return (
      <>
        {cardData.status === CareerDevelopmentStatus.COMPLETED && (
          <CardStatus textColor={textColor}>complete</CardStatus>
        )}
        {cardData.status === CareerDevelopmentStatus.IN_PROGRESS && (
          <CardStatus textColor={textColor} bgColor={colors.bgDark}>
            in progress
          </CardStatus>
        )}
      </>
    );
  };
  return (
    <Box
      p={{ xs: '36px 16px', lg: '36px' }}
      bgcolor={cardBgColor}
      border={
        cardData.status === CareerDevelopmentStatus.INACTIVE
          ? `1px solid ${theme.palette.background.lightDark}`
          : ''
      }
      {...rest}
    >
      <Grid container rowGap="36px">
        <Grid
          item
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack direction="row" columnGap="13px" alignItems="center">
            <StepIndicator
              value={cardData.step}
              bgColor={stepIndicatorBgColor}
              textColor={textColor}
              borderColor={stepIndicatorBorderColor}
            />
            <Typography variant="body1">{cardData.title}</Typography>
          </Stack>
          <Box display={{ xs: 'none', md: 'block' }}>{showStatus()}</Box>
        </Grid>
        <Grid
          item
          container
          color={theme.palette.text.primary}
          flexDirection="column"
          rowGap="28px"
        >
          <Grid item display="flex" flexDirection="column" rowGap="10px">
            <Typography variant="h4" color="inherit">
              Objective:
            </Typography>
            <Typography variant="body3">{cardData.objective}</Typography>
          </Grid>
          <Grid item display="flex" flexDirection="column" rowGap="10px">
            <Typography variant="h4" color="inherit">
              Outcome:
            </Typography>
            <Typography variant="body3">{cardData.outcome}</Typography>
          </Grid>
          <Grid item display={{ xs: 'block', md: 'none' }}>
            <Box width="100px">{showStatus()}</Box>
          </Grid>
          {viewType === 'agent' &&
            cardData.status === CareerDevelopmentStatus.IN_PROGRESS && (
              <Grid item display="flex" flexDirection="column" rowGap="10px">
                <CustomButton
                  variantType={ButtonTypeEnum.PRIMARY}
                  fontSize="10px"
                  sx={{ height: 40, p: '12px 16px' }}
                  disabled={isLoading}
                  // onClick={() => onComplete?.(cardData.id)}
                  onClick={() => onComplete?.(cardData)}
                >
                  mark as completed
                </CustomButton>
              </Grid>
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CareerDevelopmentCard;
