import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { Box } from '@mui/material';
import ListToolBar from '../../../../components/ListToolbar/ListToolBar';
import { useSearchParams } from 'react-router-dom';
import { IListSortPops } from '../../../../components/ListToolbar/ListSort';
import { IUser } from '../../../../interfaces/user.interface';
import CandidatesList from '../CandidatesList/CandidatesList';
import SkeletonList from '../../../../components/MUIComponents/SkeletonList';

interface JobPostsProps {
  listToolBarSubtitle: string;
  candidates: IUser[];
  sortMenuItems: IListSortPops['sortMenuItems'];
  isLoadMore: boolean;
  isLoading: boolean;
}

const Candidates = ({
  listToolBarSubtitle,
  candidates,
  sortMenuItems,
  isLoadMore,
  isLoading,
}: JobPostsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleLoadMore = () => {
    setSearchParams(prev => {
      const currentPage = parseInt(prev.get('page') ?? '1', 10);
      const nextPage = currentPage + 1;
      prev.set('page', nextPage.toString());
      return prev;
    });
  };

  return (
    <>
      <ListToolBar
        sortMenuItems={sortMenuItems}
        sortMenuLabel={'Show:'}
        subTitle={listToolBarSubtitle}
        paddingBottom={'16px'}
        sx={{
          p: '8px 18px',
        }}
      />

      <Box
        sx={{
          width: '100%',
          borderTopWidth: '1px',
          borderTopColor: 'background.dark',
          borderTopStyle: 'solid',
        }}
      />

      {!isLoading ? (
        <Box padding={'0.5rem 1.125rem'}>
          <CandidatesList candidates={candidates} />
        </Box>
      ) : (
        skeletonListOfCandidates
      )}

      <Box
        sx={{
          p: '36px 18px',
          mt: 'auto',
        }}
      >
        {isLoadMore && (
          <CustomButton
            fullWidth
            sx={{ width: '100%', color: 'text.primary' }}
            variant="outlined"
            variantType={ButtonTypeEnum.SECONDARY}
            onClick={handleLoadMore}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Load more'}
          </CustomButton>
        )}
      </Box>
    </>
  );
};

export default Candidates;

const skeletonListOfCandidates = (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      gap: { xs: '0.75rem', lg: '1rem' },
      p: '1.125rem',
    }}
  >
    <SkeletonList
      count={7}
      sx={{
        minHeight: '3.125rem',
      }}
    />
  </Box>
);
