import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { INotification } from '../../../interfaces/notification.interface';
import getNotificationDetails from '../../../utils/helper/getNotificationDetails';
import { useState } from 'react';
import RemoveConnectionIcon from '../../Icons/RemoveConnectionIcon';
import DeleteFileRedIcon from '../../Icons/DeleteFileRedIcon';
import {
  useDeleteMyNotificationMutation,
  useMarkMyNotificationAsReadMutation,
} from '../../../store/apiSlice/notificationApi';
import { useNavigate } from 'react-router-dom';
import { NotificationTypeEnum } from '../../../enums/notification-type.enum';
import RejectReasonJobApplyModal from '../../../pages/JobPostApplyPage/components/modals/RejectReasonJobApplyModal';
import { useModalManager } from '../../../hooks/useModalManager';
import { useGetChatByIdQuery } from '../../../store/apiSlice/chat/chatApi';
import { setSelectedChat } from '../../../store/apiSlice/chat/chatSlice';
import { useDispatch } from 'react-redux';
import { useGetGroupChatByIdQuery } from '../../../store/apiSlice/chat/groupApi';

const NotificationMenuItem = ({
  notification,
  onDelete,
  onMarkAsRead,
}: {
  notification: INotification;
  onDelete: (notificationId: string) => void;
  onMarkAsRead: () => void;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isRead, setIsRead] = useState<boolean>(notification.isRead);

  const { message, Icon, color } = getNotificationDetails(notification.type);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { currentModal, toggleModal } = useModalManager();

  const { data: chat } = useGetChatByIdQuery(
    { chatId: notification?.actionId },
    { skip: notification.type !== NotificationTypeEnum.CHAT_MESSAGE }
  );
  const { data: groupChat } = useGetGroupChatByIdQuery(
    { groupChatId: notification?.actionId },
    { skip: notification.type !== NotificationTypeEnum.CHAT_MESSAGE }
  );

  const onNotificationClick = () => {
    handleMarkMyNotificationAsRead(notification.id);

    switch (notification.type) {
      case NotificationTypeEnum.FRIEND_INTRODUCTION:
        navigate('/', {
          state: {
            introductionId: notification.introductionId,
          },
        });
        break;

      case NotificationTypeEnum.FRIEND_INVITATION:
        navigate('/connections?query=&sortBy=&tab=2');
        break;

      case NotificationTypeEnum.FRIEND_REQUEST_APPROVE:
      case NotificationTypeEnum.FRIEND_INTRODUCTION_REQUEST_APPROVE:
        navigate('/connections?query=&sortBy=&tab=1');
        break;

      case NotificationTypeEnum.APPROVE_JOB_APPLICATION:
        navigate(`/me/job-application/${notification?.actionId}`);
        break;
      case NotificationTypeEnum.REJECT_APPLY_JOB:
        navigate(`/my-job-post/${notification?.actionId}`);
        break;
      case NotificationTypeEnum.APPLY_JOB:
        navigate(`/my-job-post/${notification?.actionId}`);
        break;

      case NotificationTypeEnum.REJECT_JOB_APPLICATION:
        toggleModal('REJECT_REASON_JOB_APPLY_MODAL');
        break;

      case NotificationTypeEnum.POST_LIKE:
      case NotificationTypeEnum.COMMENT_POST:
      case NotificationTypeEnum.COMMENT_LIKE:
      case NotificationTypeEnum.FRIEND_REQUEST_REJECT:
      case NotificationTypeEnum.FRIEND_INTRODUCTION_REQUEST_REJECT:
        break;

      case NotificationTypeEnum.CHAT_MESSAGE:
        if (chat || groupChat) {
          if (chat) {
            dispatch(setSelectedChat(chat));
          } else {
            dispatch(setSelectedChat(groupChat));
          }

          navigate('/chat');
          return;
        }
        break;

      default:
        console.warn(
          `No action defined for notification type: ${notification.type}`
        );
        break;
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [markMyNotificationAsRead, { isLoading: isMarkingAsRead }] =
    useMarkMyNotificationAsReadMutation();

  const handleMarkMyNotificationAsRead = async (notificationId: string) => {
    try {
      await markMyNotificationAsRead(notificationId).unwrap();

      onMarkAsRead();
      setIsRead(true);
      handleClose();
    } catch (error) {
      console.error('Marking notification as read failed: ', error);
    }
  };
  const [deleteMyNotification, { isLoading: isDeletingNotification }] =
    useDeleteMyNotificationMutation();

  const handleDeleteMyNotification = async (notificationId: string) => {
    try {
      await deleteMyNotification(notificationId).unwrap();

      onDelete(notificationId);
      // setIsRead(true);
      handleClose();
    } catch (error) {
      console.error('Marking notification as read failed: ', error);
    }
  };

  const senderNameField =
    notification.senderUserFirstName || notification.senderUserLastName
      ? `${notification.senderUserFirstName || ''}  ${notification.senderUserLastName || ''}`
      : notification.senderUserEmail;

  return (
    <MenuItem
      sx={{
        minWidth: '360px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        bgcolor: `${isRead ? 'background.white' : 'background.light'}`,
      }}
    >
      {currentModal === 'REJECT_REASON_JOB_APPLY_MODAL' && (
        <RejectReasonJobApplyModal
          isOpen
          onClose={() => toggleModal('')}
          id={notification?.actionId}
        />
      )}
      <Box
        onClick={onNotificationClick}
        display={'flex'}
        alignItems={'center'}
        gap={'16px'}
      >
        <ListItemAvatar
          sx={{
            width: '50px',
            height: '50px',
            position: 'relative',
            minWidth: 'auto',
          }}
        >
          <Avatar
            alt={senderNameField}
            src={
              notification.senderUserOptimizedAvatar
                ? `data:image/jpeg;base64,${notification.senderUserOptimizedAvatar}`
                : ''
            }
            sx={{ width: '100%', height: '100%' }}
          />
          {Icon && (
            <Box
              bgcolor={color}
              width={'18px'}
              height={'18px'}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
              borderRadius={'50%'}
              position={'absolute'}
              bottom={0}
              right={0}
            >
              <Icon />
            </Box>
          )}
        </ListItemAvatar>
        <ListItemText
          primary={senderNameField}
          secondary={message}
          primaryTypographyProps={{
            sx: {
              display: 'inline',
              fontSize: '0.875rem',
            },
          }}
          secondaryTypographyProps={{
            sx: {
              display: 'inline',
              textAlign: 'left',
              lineHeight: '140%',
              fontSize: '0.875rem',
            },
          }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.125rem',
            color: 'text.primary',
          }}
        />
      </Box>

      <>
        <IconButton
          aria-label="More"
          sx={{
            p: 0,
            width: 'auto',
            height: 'auto',
            minWidth: 'auto',
            minHeight: 'auto',
            '&:hover': { background: 'transparent' },
          }}
          id="show-more-button"
          aria-controls={open ? 'show-more' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon sx={{ color: 'text.secondary' }} />
        </IconButton>
        <Menu
          sx={{
            pt: '0px',
            pb: '0px',
            '& .css-tansqo-MuiButtonBase-root-MuiMenuItem-root+.MuiDivider-root':
              { marginTop: '0px', marginBottom: '0px' },
          }}
          id="show-more"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'show-more-button',
            disablePadding: true,
          }}
        >
          <MenuItem
            disabled={isMarkingAsRead}
            onClick={() => {
              handleMarkMyNotificationAsRead(notification.id);
            }}
            sx={{ display: 'flex', gap: '6px', fontSize: '14px', p: '15px' }}
          >
            <RemoveConnectionIcon />
            Mark as read
          </MenuItem>
          <Divider
            component={'li'}
            sx={{
              '&.MuiDivider-root': { margin: 0 },
            }}
          />
          <MenuItem
            disabled={isDeletingNotification}
            onClick={() => {
              handleDeleteMyNotification(notification.id);
            }}
            sx={{ display: 'flex', gap: '6px', fontSize: '14px', p: '15px' }}
          >
            <DeleteFileRedIcon />
            Remove notification
          </MenuItem>
        </Menu>
      </>
    </MenuItem>
  );
};

export default NotificationMenuItem;
