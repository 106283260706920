import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useGetMyChats from '../../../../hooks/chat/useGetMyChats';
import useGetMyPersonaAndGroupChats from '../../../../hooks/chat/useGetMyPersonaAndGroupChats';
import { IChat } from '../../../../store/apiSlice/chat/chatApi';
import { getChatsLoaded } from '../../../../store/apiSlice/chat/chatSlice';
import { IGroupChat } from '../../../../store/apiSlice/chat/groupApi';
import ChatNavigationSection from '../ChatNavigationSection/ChatNavigationSection';

interface IChatListProps {
  isHighlightSelectedChat?: boolean;
  isBothTypeOfChats?: boolean;
  searchValue: string;
  isShowLastMessage?: boolean;
  setSearchValue: (_: string) => void;
  handleSelectChat: (chat: IChat | IGroupChat) => void;
}

const ChatList = ({
  isHighlightSelectedChat = true,
  isBothTypeOfChats = false,
  searchValue,
  isShowLastMessage = true,
  handleSelectChat,
}: IChatListProps) => {
  const chatsLoaded = useSelector(getChatsLoaded);

  const singleTypeChats = useGetMyChats({ searchValue });
  const bothTypeChats = useGetMyPersonaAndGroupChats({ searchValue });

  const { myChats } = useMemo(() => {
    return !isBothTypeOfChats ? singleTypeChats : bothTypeChats;
  }, [singleTypeChats, bothTypeChats, isBothTypeOfChats]);

  if (!chatsLoaded) return null;

  const chatSections = [
    {
      name: 'Live agent',
      chats: myChats?.filter(chat => chat.type === 'AGENT') ?? [],
    },
    {
      name: 'Contacts',
      chats: myChats?.filter(chat => chat.type !== 'AGENT') ?? [],
    },
  ];

  return (
    <Stack
      sx={{
        flexGrow: 1,
        padding: '0 1rem',
        gap: '1.625rem',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
      }}
    >
      {chatSections.map((section, index) => (
        <ChatNavigationSection
          key={index}
          name={section.name}
          chats={section.chats}
          handleSelectedChat={handleSelectChat}
          isHighlightSelectedChat={isHighlightSelectedChat}
          isShowLastMessage={isShowLastMessage}
        />
      ))}
    </Stack>
  );
};

export default ChatList;
