import {
  Box,
  Grid,
  Skeleton,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { useCurrentPageUserId } from '../../../../hooks/useCurrentPageUserId';
import ArticleLayout from '../../../../layouts/ArticleLayout';
import { useGetUserAboutQuery } from '../../../../store/apiSlice/userApi';
import TabEducationEntry from './components/TabEducationEntry';
import { getFullMonthNameAndDay } from '../../../../utils/date-time-formats.ts/formatDate';

const AboutTab = () => {
  const { userId } = useCurrentPageUserId();
  const { data: aboutData, isLoading } = useGetUserAboutQuery(userId ?? '', {
    skip: !userId,
  });
  const noData = 'No information';

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={{ xs: '16px', xl: '22px' }}
    >
      {isLoading && (
        <Skeleton
          variant="rounded"
          sx={{
            width: '100%',
            minHeight: '370px',
          }}
        />
      )}
      {!isLoading && (
        <ArticleLayout
          className="about-article"
          rowGap={8}
          display="flex"
          flexDirection="column"
        >
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3" textTransform="capitalize">
                about
              </Typography>
              {/*{isOwnPage && (*/}
              {/*  <SquareIconButton>*/}
              {/*    <EditIcon />*/}
              {/*  </SquareIconButton>*/}
              {/*)}*/}
            </Stack>

            <Grid container rowGap="28px" sx={gridContainerStyles}>
              <Grid item display="flex" flexDirection="row">
                <Typography className="label" variant="caption">
                  Full Name:
                </Typography>
                <Typography className="plain-text" variant="body3">
                  {aboutData?.firstName
                    ? `${aboutData?.firstName ?? ''} ${aboutData?.lastName ?? ''}`
                    : noData}
                </Typography>
              </Grid>
              <Grid item display="flex" flexDirection="row">
                <Typography className="label" variant="caption">
                  Birthday:
                </Typography>
                <Typography className="plain-text" variant="body3">
                  {getFullMonthNameAndDay(aboutData?.birthdate ?? '') ?? noData}
                </Typography>
              </Grid>
              <Grid item display="flex" flexDirection="row">
                <Typography className="label" variant="caption">
                  My Bio:
                </Typography>
                <Typography className="plain-text" variant="body3">
                  {aboutData?.bio ?? noData}
                </Typography>
              </Grid>
              <Grid item display="flex" flexDirection="row">
                <Typography className="label" variant="caption">
                  Country:
                </Typography>
                <Typography className="plain-text" variant="body3">
                  {aboutData?.country ?? noData}
                </Typography>
              </Grid>
              <Grid item display="flex" flexDirection="row">
                <Typography className="label" variant="caption">
                  City:
                </Typography>
                <Typography className="plain-text" variant="body3">
                  {aboutData?.city ?? noData}
                </Typography>
              </Grid>
            </Grid>
          </>
        </ArticleLayout>
      )}

      {isLoading && (
        <Skeleton
          variant="rounded"
          sx={{
            width: '100%',
            minHeight: '270px',
          }}
        />
      )}

      {!isLoading && (
        <ArticleLayout
          className="education-article"
          rowGap={8}
          display="flex"
          flexDirection="column"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h3" textTransform="capitalize">
              education
            </Typography>
            {/*{isOwnPage && (*/}
            {/*  <SquareIconButton>*/}
            {/*    <EditIcon />*/}
            {/*  </SquareIconButton>*/}
            {/*)}*/}
          </Stack>

          {!!aboutData?.educations.length ? (
            aboutData?.educations.map(education => (
              <TabEducationEntry key={education.id} education={education} />
            ))
          ) : (
            <TabEducationEntry />
          )}
        </ArticleLayout>
      )}
    </Box>
  );
};

export default AboutTab;

export const gridContainerStyles: SxProps<Theme> = theme => ({
  '& .label': {
    width: 165,
    flexShrink: 0,
  },
  '& .plain-text': {
    fontWeight: 400,
    lineHeight: '140%',
    color: theme.palette.text.primary,

    textAlign: 'left',
    flexGrow: '1',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    minWidth: 0,
  },
  '& .MuiGrid-item': {
    width: '100%',
  },
});
