import * as yup from 'yup';

export const setCareerDevelopmentSchema = yup.object().shape({
  milestones: yup
    .array()
    .of(
      yup.object().shape({
        stepTitle: yup
          .string()
          .min(5, 'Step title must be at least 5 characters long.')
          .max(100, 'Step title cannot exceed 100 characters.')
          .required('step title is required'),
        objective: yup
          .string()
          .min(10, 'Step title must be at least 10 characters long.')
          .max(500, 'Step title cannot exceed 500 characters.')
          .required('objective title is required'),
        outcome: yup
          .string()
          .min(10, 'Step title must be at least 10 characters long.')
          .max(500, 'Step title cannot exceed 500 characters.')
          .required('outcome title is required'),
      })
    )
    .min(1, 'At least one milestone is required')
    .required('milestones are required'),
});
