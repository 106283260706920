import { SxProps, Typography, useMediaQuery } from '@mui/material';
import { Fragment, useState } from 'react';
import { PixelCrop } from 'react-image-crop';
import UploadedFileIconElement from '../../../../components/Auth/Elements/UploadedFileIconElement';
import PhotosIcon from '../../../../components/Icons/PhotosIcon';
import BaseModal from '../../../../components/MUIComponents/BaseModal';
import DragAndDropBox from '../../../../components/MUIComponents/DragAndDropBox/DragAndDropBox';
import Loader from '../../../../components/MUIComponents/Loader';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { ElementIdEnum } from '../../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import { useUpdateBannerMutation } from '../../../../store/apiSlice/userApi';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { triggerFileInput } from '../../../../utils/helper/triggerInputHelper';
import setCanvasPreview from '../../../../utils/setCanvasPreview';
import Cropper from '../Cropper';
import { errorHelper } from '../../../../utils/helper/error-helper';

const MIN_WIDTH = 1500;

export interface FinalCropData {
  img: HTMLImageElement;
  canvas: HTMLCanvasElement;
  crop: PixelCrop;
}

interface CropBackgroundModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const CropBackgroundModal = ({
  onClose,
  isOpen = false,
}: CropBackgroundModalProps) => {
  const imageSizeHint = `Image width should be at least ${MIN_WIDTH} pixels`;
  const { theme } = useThemeContext();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('lg'));
  const [imgSrc, setImgSrc] = useState('');
  const [finalCroppedData, setFinalCroppedData] =
    useState<FinalCropData | null>(null);

  const [isUpdatingBanner, setIsUpdatingBanner] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  // eslint-disable-next-line
  const [dragOver, setDragOver] = useState(false);

  const [updateBanner] = useUpdateBannerMutation();

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const addFile = async (file: File) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || '';
      imageElement.src = imageUrl;

      imageElement.addEventListener('load', e => {
        const { naturalWidth } = e.currentTarget as HTMLImageElement;
        if (naturalWidth < MIN_WIDTH) {
          setSnackbarOpen(true);
          setSnackbarMessage(imageSizeHint);
          setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
          return setImgSrc('');
        }
      });
      setImgSrc(reader.result?.toString() || '');
    });
    reader.readAsDataURL(file);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      addFile(event.target.files[0]);
      event.target.value = '';
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      addFile(event.dataTransfer.files[0]);
    }
  };

  const boxSx: SxProps = {
    width: { xs: '100%', lg: '875px !important' },
    maxHeight: { xs: '80vh', lg: '90dvh' },
    p: { xs: '20px 12px 12px', lg: '36px 36px 48px' },
    position: { xs: 'fixed', lg: 'absolute' },
    top: { lg: '50%' },
    left: { lg: '50%' },
    bottom: { xs: 0, lg: 'unset' },
    borderRadius: { lg: '0 !important' },
    transform: { lg: 'translate(-50%, -50%)' },
    '.content': { m: '48px 0 36px' },
  };

  const closeModal = () => {
    setImgSrc('');
    setFinalCroppedData(null);
    onClose();
  };

  const onAccept = async () => {
    try {
      if (
        !finalCroppedData ||
        !finalCroppedData.img ||
        !finalCroppedData.canvas ||
        !finalCroppedData.crop
      ) {
        return;
      }
      setIsUpdatingBanner(true);

      const { img, crop, canvas } = finalCroppedData;
      setCanvasPreview(img, canvas, crop);

      const dataUrl = canvas.toDataURL('image/png');
      const response = await fetch(dataUrl);

      const blob = await response.blob();

      const file = new File([blob], 'croppedImage.png', { type: 'image/png' });
      const formData = new FormData();
      formData.append('file', file);
      await updateBanner(formData).unwrap();
      setIsUpdatingBanner(false);
      closeModal();
    } catch (error) {
      setIsUpdatingBanner(false);
      setSnackbarOpen(true);
      setSnackbarMessage(
        `Error uploading cropped image: ${errorHelper(error)}`
      );
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Error uploading cropped image:', error);
    } finally {
      setIsUpdatingBanner(false);
    }
  };

  const mainContent = !imgSrc ? (
    <DragAndDropBox
      handleDragOver={handleDragOver}
      handleDragLeave={handleDragLeave}
      handleDrop={handleDrop}
      handleFileChange={handleFileChange}
      triggerFileInput={() => triggerFileInput(ElementIdEnum.ADD_PHOTOS_MODAL)}
      fileInputId={ElementIdEnum.ADD_PHOTOS_MODAL}
      text={
        <Fragment>
          <Typography
            marginBottom={'2px'}
            variant="body4"
            color={'text.primary'}
          >
            {imageSizeHint}
            <br />
          </Typography>
          Drag and drop your photos here
        </Fragment>
      }
      icon={
        <UploadedFileIconElement>
          <PhotosIcon active />
        </UploadedFileIconElement>
      }
      multipleInput={false}
    />
  ) : (
    <Cropper
      setFinalCroppedData={data => setFinalCroppedData(data)}
      imgSrc={imgSrc}
    />
  );

  return (
    <BaseModal
      boxSx={{ ...boxSx }}
      disableEscapeButton
      disableContentMargin
      isOpen={isOpen}
      toggle={closeModal}
      onAccept={onAccept}
      footer={{
        okText: 'publish',
        declineText: 'cancel',
        styles: {
          gap: 'unset',
        },
        declineButtonStyles: {
          flex: 'unset !important',
          borderColor: theme.palette.background.darker,
          minWidth: tabletAndBigger ? '220px' : 'unset',
          borderRadius: 0,
        },
        okButtonStyles: {
          flex: 'unset !important',
          minWidth: tabletAndBigger ? '220px' : 'unset',
          borderRadius: 0,
        },
        isOkButtonDisabled: !imgSrc || isUpdatingBanner ? true : false,
      }}
      header={{ text: 'Crop Background' }}
    >
      {isUpdatingBanner ? <Loader color="primary" /> : mainContent}
      <SnackbarCustom
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default CropBackgroundModal;
