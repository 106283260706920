import React from 'react';
import { Box, SxProps } from '@mui/material';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';

interface LoadMoreButtonProps {
  onClick: () => void;
  sx?: SxProps;
}

const LoadMoreButton = ({ onClick, sx }: LoadMoreButtonProps) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      <CustomButton
        onClick={onClick}
        variantType={ButtonTypeEnum.SECONDARY}
        color="primary"
        style={{ marginTop: '1rem', minWidth: '100%' }}
      >
        Load More
      </CustomButton>
    </Box>
  );
};

export default LoadMoreButton;
