import UserListItem, {
  UserInfo,
  UserItemStatus,
} from '../../../components/user-list/UserListItem';
import ActionButtons from './ActionButtons';
import StepProgressBar from '../../../../../../components/MUIComponents/StepProgressBar';

interface RosterListitemProps {
  totalSteps?: number | null;
  completedSteps?: number | null;
  userItemStatus?: UserItemStatus;

  userInfo: UserInfo;
}

const RosterListItem = ({
  totalSteps = null,
  completedSteps = null,
  userItemStatus,
  userInfo,
}: RosterListitemProps) => (
  <UserListItem
    Actions={ActionButtons}
    userInfo={userInfo}
    extraData={
      userItemStatus !== UserItemStatus.INACTIVE ? (
        <StepProgressBar
          totalSteps={totalSteps ?? 0}
          completedSteps={completedSteps ?? 0}
          mt="12px"
        />
      ) : undefined
    }
    userItemStatus={userItemStatus}
  />
);

export default RosterListItem;
