import React, { FC } from 'react';
import { Avatar, Box, SxProps, Typography } from '@mui/material';
import { formatDate } from '../../../../utils/date-time-formats.ts/formatDate';
import { IUser } from '../../../../interfaces/user.interface';
import { IPost } from '../../../../interfaces/post.interface';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { Link } from 'react-router-dom';

interface PostHeaderProps {
  userData?: IUser;
  post: IPost;
  editButton?: React.ReactNode;
  sx?: SxProps;
  ownerNameSx?: SxProps;
}

const PostHeader: FC<PostHeaderProps> = ({
  userData,
  post,
  editButton,
  sx,
  ownerNameSx,
}) => {
  const { theme } = useThemeContext();

  return (
    <Box
      component="header"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
        color: theme.palette.secondary.main,
        ...sx,
      }}
    >
      <Box
        component={Link}
        to={`/${userData?.id}`}
        sx={{
          display: 'flex',
          gap: '1rem',
        }}
      >
        <Avatar
          alt="Profile"
          src={
            userData?.optimizedUserAvatarBaseUrl
              ? `data:image/jpeg;base64,${userData.optimizedUserAvatarBaseUrl}`
              : ''
          }
          sx={{ width: '2.5rem', height: '2.5rem' }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.375rem',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.secondary.main,
              ...ownerNameSx,
            }}
          >
            {`${userData?.firstName ?? ''} ${userData?.lastName ?? ''}`}
          </Typography>
          <Typography
            variant="body4"
            sx={{
              color: theme.palette.text.secondary,
              fontWeight: 500,
            }}
          >
            {formatDate(new Date(post.createdAt))}
          </Typography>
        </Box>
      </Box>

      {editButton}
    </Box>
  );
};

export default PostHeader;
