import React, { FC } from 'react';
import { IUser } from '../../../../interfaces/user.interface';
import { Avatar, Stack, SxProps, Typography } from '@mui/material';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { useNavigate } from 'react-router-dom';

interface SearchPeopleResultItemProps {
  user: IUser;
  sx?: SxProps;
}

const SearchPeopleResultItem: FC<SearchPeopleResultItemProps> = ({
  user,
  sx,
}) => {
  const { theme } = useThemeContext();
  const navigate = useNavigate();

  const avatarUrl = user?.optimizedUserAvatarBaseUrl
    ? `data:image/jpeg;base64,${user.optimizedUserAvatarBaseUrl}`
    : '';

  const sportNames = user?.sports.map(sport => sport.name).join(' • ');

  return (
    <Stack direction={'row'} alignItems={'center'} spacing={'1rem'} sx={sx}>
      <Avatar
        alt="Profile avatar"
        src={avatarUrl}
        sx={{ width: '4rem', height: '4rem' }}
      />

      <Stack direction={'column'} spacing={'0.375rem'} width={'100%'}>
        <Typography variant={'body1'}>
          {`${user?.firstName} ${user?.lastName}`}
        </Typography>
        <Typography variant={'body3'}>{sportNames}</Typography>
      </Stack>

      <CustomButton
        onClick={() => navigate(`/${user.id}`)}
        variantType={ButtonTypeEnum.SECONDARY}
        sx={{
          padding: '0.75rem 1rem',
          minWidth: 'fit-content',
          height: '2.5rem',
          minHeight: '2.5rem',
        }}
      >
        View Profile
      </CustomButton>
    </Stack>
  );
};

export default SearchPeopleResultItem;
