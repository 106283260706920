import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';
import { IUserPaginationResponse } from '../../interfaces/user.interface';
import { IPostResponse } from '../../interfaces/post.interface';

const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: baseQueryWithInterceptor(`${config.BASE_SEARCH_API_URL}/search`),
  tagTypes: ['searchUsers', 'searchPosts'],

  endpoints: builder => ({
    getPaginatedUsers: builder.query<
      IUserPaginationResponse,
      {
        page: number;
        limit: number;
        search: string;
        sort?: string;
        sports?: string;
        leagues?: string;
        teams?: string;
        country?: string;
      }
    >({
      query: ({
        page = 1,
        limit = 10,
        search = '',
        sort = 'desc',
        sports = '',
        leagues = '',
        teams = '',
        country = '',
      }) =>
        `users?page=${page}&limit=${limit}&search=${search}&sort=${sort}&sports=${sports}&leagues=${leagues}&teams=${teams}&country=${country}`,
      providesTags: ['searchUsers'],
    }),
    getPaginatedPosts: builder.query<
      IPostResponse,
      {
        tags?: string;
        page?: number;
        limit?: number;
        search?: string;
        sort?: string;
        from?: string;
        to?: string;
      }
    >({
      query: ({
        tags = '',
        page = 1,
        limit = 20,
        search = '',
        sort = 'desc',
        from = '',
        to = '',
      }) =>
        `/posts?tags=${tags}&page=${page}&limit=${limit}&search=${search}&sort=${sort}&from=${from}&to=${to}`,
      providesTags: ['searchPosts'],
    }),
  }),
});

export const { useGetPaginatedUsersQuery, useGetPaginatedPostsQuery } =
  searchApi;

export default searchApi;
