import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/config';
import { IPost, IPostResponse } from '../../../interfaces/post.interface';
import { baseQueryWithInterceptor } from '../../utils/baseQueryWithInterceptor';

const postApi = createApi({
  reducerPath: 'postApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_POST_MICROSERVICE_API_URL}/post`
  ),
  tagTypes: ['post'],

  endpoints: builder => ({
    createPost: builder.mutation<IPostResponse, FormData>({
      query: (data: FormData) => ({
        url: '/',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['post'],
    }),

    getAllPosts: builder.query<
      IPostResponse,
      {
        tags: string;
        page: number;
        limit: number;
        search: string;
        sort: string;
      }
    >({
      query: ({
        tags = '',
        page = 1,
        limit = 20,
        search = '',
        sort = 'desc',
      }) =>
        `/search?tags=${tags}&page=${page}&limit=${limit}&search=${search}&sort=${sort}`,
      providesTags: ['post'],
    }),

    getUserPostsByUserId: builder.query<
      IPostResponse,
      {
        id: string;
        tags: string;
        page: number;
        limit: number;
        search: string;
        sort: string;
        from?: string | null;
        to?: string | null;
      }
    >({
      query: ({
        id,
        tags = '',
        page = 1,
        limit = 20,
        search = '',
        sort = 'desc',
        from,
        to,
      }) => {
        const baseSearchUrl = `/user-posts/${id}?tags=${tags}&page=${page}&limit=${limit}&search=${search}&sort=${sort}`;

        const filterParams = new URLSearchParams();

        if (from) filterParams.set('from', from);
        if (to) filterParams.set('to', to);

        // If filterParams has any parameters, append them to the URL
        return filterParams.toString()
          ? `${baseSearchUrl}&${filterParams.toString()}`
          : baseSearchUrl;
      },
      providesTags: ['post'],
    }),

    getMyPosts: builder.query<
      IPostResponse,
      {
        tags: string;
        page: number;
        limit: number;
        search: string;
        sort: string;
      }
    >({
      query: ({
        tags = '',
        page = 1,
        limit = 20,
        search = '',
        sort = 'desc',
      }) =>
        `/my-posts?tags=${tags}&page=${page}&limit=${limit}&search=${search}&sort=${sort}`,
      providesTags: ['post'],
    }),

    getMyFriendsPosts: builder.query<
      IPostResponse,
      {
        tags: string;
        page: number;
        limit: number;
        search: string;
        sort: string;
        from?: string | null;
        to?: string | null;
      }
    >({
      query: ({
        tags = '',
        page = 1,
        limit = 20,
        search = '',
        sort = 'desc',
        from,
        to,
      }) => {
        const baseSearchUrl = `/my-friends-posts?tags=${tags}&page=${page}&limit=${limit}&search=${search}&sort=${sort}`;

        const filterParams = new URLSearchParams();

        if (from) filterParams.set('from', from);
        if (to) filterParams.set('to', to);

        // If filterParams has any parameters, append them to the URL
        return filterParams.toString()
          ? `${baseSearchUrl}&${filterParams.toString()}`
          : baseSearchUrl;
      },
      providesTags: ['post'],
    }),

    getPosts: builder.query<IPostResponse[], { page: number; limit: number }>({
      query: ({ page, limit }) => `/?page=${page}&limit=${limit}`,
      providesTags: ['post'],
    }),

    getPostById: builder.query<IPost, string>({
      query: id => `/${id}`,
      providesTags: ['post'],
    }),

    getUserPostsById: builder.query<
      IPostResponse,
      {
        id: string;
        tags: string;
        page: number;
        limit: number;
        search: string;
        sort: string;
      }
    >({
      query: ({
        id = '',
        tags = '',
        page = 1,
        limit = 20,
        search = '',
        sort = 'desc',
      }) =>
        `/user-posts/${id}?tags=${tags}&page=${page}&limit=${limit}&search=${search}&sort=${sort}`,
      providesTags: ['post'],
    }),

    updatePost: builder.mutation<IPostResponse, { id: string; data: FormData }>(
      {
        query: ({ id, data }) => ({
          url: `/${id}`,
          method: 'PUT',
          body: data,
        }),
        invalidatesTags: ['post'],
      }
    ),

    deletePost: builder.mutation<IPostResponse, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['post'],
    }),
  }),
});

export const {
  useCreatePostMutation,
  useGetPostsQuery,
  useGetAllPostsQuery,
  useGetUserPostsByUserIdQuery,
  useGetMyPostsQuery,
  useGetMyFriendsPostsQuery,
  useGetPostByIdQuery,
  useGetUserPostsByIdQuery,
  useUpdatePostMutation,
  useDeletePostMutation,
} = postApi;

export default postApi;
