import { Box, BoxProps } from '@mui/material';
import LiveAgentTabs from '../LiveAgentTabs';

interface LiveAgentMainContentProps extends BoxProps {}

const LiveAgentMainContent = ({ ...rest }: LiveAgentMainContentProps) => {
  return (
    <Box {...rest}>
      <LiveAgentTabs />
    </Box>
  );
};

export default LiveAgentMainContent;
