import React, { useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import CustomButton from '../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../enums/button-type-enum';
import { useNavigate } from 'react-router-dom';
import { useGetUserInfoQuery } from '../../../../store/apiSlice/userApi';
import { SelectItemUser } from '../../../home/shared-components/modals/components/SelectItemUser';
import { useCreateFriendshipByIntroductionMutation } from '../../../../store/apiSlice/friendApi';
import { SnackbarSeverityEnum } from '../../../../enums/snackbar-severity-enum';
import SnackbarCustom from '../../../../components/MUIComponents/SnackbarCustom';
import { errorHelper } from '../../../../utils/helper/error-helper';
import { ICreateFriendshipByIntroductionRequest } from '../../../../interfaces/friend.interface';
import { useSelector } from 'react-redux';
import { selectAuthData } from '../../../../store/selectors/authSelector';

interface IMessageIntroductionPreview {
  userId?: string;
  introductionId?: string;
}
const MessageIntroductionPreview = (props: IMessageIntroductionPreview) => {
  const { userId, introductionId } = props;
  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const viewProfile = (): void => {
    if (userId && userId.length > 0) {
      navigate(`/${userId}`);
    }
  };

  const authData = useSelector(selectAuthData);

  const {
    data: userWhoIntroducesHimself,
    isLoading: isUserWhoIntroducesHimselfLoading,
  } = useGetUserInfoQuery(userId || '', {
    skip: !userId,
  });

  const [
    createFriendshipByIntroduction,
    { isLoading: isCreateFriendshipByIntroductionLoading },
  ] = useCreateFriendshipByIntroductionMutation();

  const handleConnectButtonClick = async () => {
    if (!introductionId) {
      setSnackbarOpen(true);
      setSnackbarMessage('Introduction not exist');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      return;
    }

    if (!userWhoIntroducesHimself) {
      setSnackbarOpen(true);
      setSnackbarMessage('There is no user to create friendship');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      return;
    }

    if (!authData || !authData?.user || !authData?.user?.id) {
      setSnackbarOpen(true);
      setSnackbarMessage('User who create friendship not exist');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      return;
    }

    try {
      const friendshipByIntroductionData: ICreateFriendshipByIntroductionRequest =
        {
          firstFriendId: authData?.user?.id,
          secondFriendId: userWhoIntroducesHimself.id,
          introductionId: introductionId,
        };

      await createFriendshipByIntroduction(
        friendshipByIntroductionData
      ).unwrap();
      setSnackbarOpen(true);
      setSnackbarMessage('Friend successfully added!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    }
  };

  return (
    <>
      <Box
        borderRadius={'4px'}
        bgcolor={'white'}
        display={'flex'}
        flexDirection={'column'}
        gap={'1rem'}
        p={'1rem'}
      >
        {isUserWhoIntroducesHimselfLoading && (
          <Box display="flex" alignItems="center">
            <Skeleton variant="circular" width={'2.5rem'} height={'2.5rem'} />
            <Box ml={2}>
              <Skeleton variant="text" width={'7.5rem'} height={'1.25rem'} />
              <Skeleton variant="text" width={'6.25rem'} height={'1.25rem'} />
            </Box>
          </Box>
        )}

        {userWhoIntroducesHimself && (
          <>
            {' '}
            {!isUserWhoIntroducesHimselfLoading && (
              <SelectItemUser
                userId={userWhoIntroducesHimself.id}
                avatarUrl={
                  userWhoIntroducesHimself?.optimizedUserAvatarBaseUrl || ''
                }
                firstName={userWhoIntroducesHimself.firstName || ''}
                lastName={userWhoIntroducesHimself.lastName || ''}
                sport={userWhoIntroducesHimself.sports[0]?.name}
                isAvatarBase64={true}
              />
            )}
            <Box display={'flex'} gap={'0.75rem'}>
              <CustomButton
                variantType={ButtonTypeEnum.PRIMARY}
                sx={{
                  height: '2.25rem',
                }}
                buttonWidth={'50%'}
                onClick={handleConnectButtonClick}
                disabled={
                  isCreateFriendshipByIntroductionLoading || !introductionId
                }
              >
                connect
              </CustomButton>
              <CustomButton
                variantType={ButtonTypeEnum.SECONDARY}
                sx={{
                  height: '2.25rem',
                }}
                buttonWidth={'50%'}
                onClick={viewProfile}
                disabled={
                  isCreateFriendshipByIntroductionLoading || !introductionId
                }
              >
                view profile
              </CustomButton>
            </Box>
          </>
        )}
      </Box>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default MessageIntroductionPreview;
