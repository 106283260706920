import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ElementIdEnum } from '../../../enums/element-id-enum';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { IUserFileDB } from '../../../interfaces/file.interface';
import { useDeleteIdConfirmationFileByIdMutation } from '../../../store/apiSlice/userFilesApi';
import { errorHelper } from '../../../utils/helper/error-helper';
import { isSupportedIdConfirmationFileHelper } from '../../../utils/helper/file-validation-helper';
import StepForm from '../../Auth/VerifyAccount/StepForm';
import UploadIcon from '../../Icons/UploadIcon';
import DragAndDropBox from '../../MUIComponents/DragAndDropBox/DragAndDropBox';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import UploadDocumentFileList from './UploadDocumentFileList/UploadDocumentFileList';

export interface FileUploadFormInputs {
  documents: File[];
}

interface FileUploadFormProps {
  title: string | React.ReactNode;
  contentText: string | React.ReactNode;
  dragAndDropContentText: string | React.ReactNode;
  onSubmitFiles: SubmitHandler<any>;
  formData: Partial<FileUploadFormInputs>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  uploadedFiles: IUserFileDB[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<IUserFileDB[]>>;
  isLoading: boolean;
  isError: boolean;
  error: any;
  responseData: any;
  maxFiles?: number;
  needBackButton?: boolean;
}

const UploadDocument: React.FC<FileUploadFormProps> = ({
  title,
  contentText,
  dragAndDropContentText,
  onSubmitFiles,
  formData,
  setStep,
  step,
  files,
  setFiles,
  uploadedFiles,
  setUploadedFiles,
  isLoading,
  isError,
  error,
  responseData,
  maxFiles,
  needBackButton,
}) => {
  const [dragOver, setDragOver] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const [uploadProgress, setUploadProgress] = useState<number[]>([]);
  const [uploading, setUploading] = useState(false);

  const [deleteIdConfirmationFileById, { isLoading: isDeleting }] =
    useDeleteIdConfirmationFileByIdMutation();

  useEffect(() => {
    if (responseData) {
      setUploadedFiles(prevState => [...prevState, ...responseData.data]);
      setUploadProgress(prevState => prevState.map(() => 100));
      setUploading(false);
    }

    if (isError) {
      const errorMessage = errorHelper(error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      setUploading(false);
    }
  }, [responseData, isError, error]);

  const handleFiles = async (newFiles: File[]) => {
    const validFiles = newFiles.filter(file =>
      isSupportedIdConfirmationFileHelper(file)
    );
    const invalidFiles = newFiles.filter(
      file => !isSupportedIdConfirmationFileHelper(file)
    );

    if (validFiles.length > 5) {
      setSnackbarMessage('You can upload up to 5 files');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (invalidFiles.length > 0) {
      setSnackbarMessage(
        'Invalid file type. Only images (JPEG, PNG, JPG, WebP)'
      );
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (maxFiles && files.length + validFiles.length > maxFiles) {
      setSnackbarMessage('You can upload up to 5 files');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    setFiles(prevState => [...prevState, ...validFiles]);
    setUploadProgress(prevState => [...prevState, ...validFiles.map(() => 0)]);

    const filesFormData = new FormData();
    validFiles.forEach(file => {
      filesFormData.append('documents', file);
    });

    setUploading(true);

    try {
    } catch (uploadError) {
      const errorMessage = errorHelper(uploadError);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      setFiles([]);
      setUploadProgress([]);
      setUploading(false);
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files);
      await handleFiles(newFiles);
    }
  };

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);

    if (event.dataTransfer.files) {
      const newFiles = Array.from(event.dataTransfer.files);
      await handleFiles(newFiles);
    }
  };

  const handleDelete = async (file: File, uploadedFile?: IUserFileDB) => {
    if (uploadedFile) {
      try {
        await deleteIdConfirmationFileById(uploadedFile.id).unwrap();
        setUploadedFiles(prevFiles =>
          prevFiles.filter(f => f.id !== uploadedFile.id)
        );
        setSnackbarMessage('File deleted successfully.');
        setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      } catch (error) {
        const errorMessage = errorHelper(error);
        setSnackbarMessage(errorMessage);
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      } finally {
        setSnackbarOpen(true);
      }
    }
    setFiles(prevFiles => prevFiles.filter(f => f !== file));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const triggerFileInput = () => {
    const fileInput = document.getElementById(
      ElementIdEnum.ID_CONFIRMATION_FILE_INPUT
    ) as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <StepForm
      title={title}
      onNext={() => onSubmitFiles({ documents: files })}
      nextDisabled={isLoading || isDeleting || files.length === 0}
      backDisabled={isLoading || isDeleting}
      step={step}
      setStep={setStep}
      needBackButton={needBackButton}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        textAlign={'center'}
        gap={'36px'}
      >
        <Box
          style={{ width: '100%' }}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography typography={'body3'}>{contentText}</Typography>
        </Box>
        <Box
          style={{ width: '100%' }}
          display={'flex'}
          flexDirection={'column'}
          gap={'36px'}
        >
          <DragAndDropBox
            handleDragOver={handleDragOver}
            handleDragLeave={handleDragLeave}
            handleDrop={handleDrop}
            handleFileChange={handleFileChange}
            triggerFileInput={triggerFileInput}
            fileInputId={ElementIdEnum.ID_CONFIRMATION_FILE_INPUT}
            text={dragAndDropContentText}
            icon={<UploadIcon />}
          />
          {files.length > 0 && (
            <Box
              style={{ width: '100%' }}
              display={'flex'}
              flexDirection={'column'}
              gap={'36px'}
            >
              <Typography
                display={'flex'}
                variant={'h3'}
                justifyContent={'flex-start'}
              >
                Uploaded documents
              </Typography>
              <UploadDocumentFileList
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                files={files}
                setFiles={setFiles}
                isLoading={isLoading || isDeleting || uploading}
                handleDelete={handleDelete}
                uploadProgress={uploadProgress}
              />
            </Box>
          )}
        </Box>
      </Box>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </StepForm>
  );
};

export default UploadDocument;
