import { Box, useTheme } from '@mui/material';
import ListToolBar from '../../../../../components/ListToolbar/ListToolBar';
import { useGetAllApplicantsQuery } from '../../../../../store/apiSlice/userApi';
import ApplicantsList from './components/ApplicantsList';
import { useSearchParams } from 'react-router-dom';

const sortMenuLabel = 'Sort by:';

const sortMenuItems = [
  { label: 'Country', value: 'country' },
  { label: 'Experience', value: 'experience' },
  { label: 'Sport', value: 'sport' },
];

const ApplicantsListTab = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page') || '1', 10);
  const limit = parseInt(searchParams.get('limit') || '10', 10);
  const search = searchParams.get('query') || '';
  const sort = searchParams.get('sortBy') || 'desc';
  const sortType = searchParams.get('sortType') || '';

  const { data: applicantsData, isLoading } = useGetAllApplicantsQuery(
    {
      page,
      limit,
      search,
      sort,
      sortType,
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <Box bgcolor={theme.palette.background.white}>
      <ListToolBar
        subTitle={(applicantsData?.total ?? 0) + ' applicants'}
        sortMenuLabel={sortMenuLabel}
        sortMenuItems={sortMenuItems}
        queryParam={'sortType'}
        needDefaultSortIcon={true}
        sx={{ p: '36px' }}
      />
      <ApplicantsList applicantsData={applicantsData} isLoading={isLoading} />
    </Box>
  );
};

export default ApplicantsListTab;
