import React from 'react';
import { Box, Typography } from '@mui/material';
import LockIcon from '../../../../components/Icons/LockIcon';

interface INotAllowedPageContentProps {
  title: string;
}
const NotAllowedPageContent = (props: INotAllowedPageContentProps) => {
  const { title } = props;

  return (
    <Box
      width={'100%'}
      height={'auto'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      gap={'1.375rem'}
      sx={{ backgroundColor: 'background.white' }}
      minHeight={'28.875rem'}
    >
      <Box
        width={'6.25rem'}
        height={'6.25rem'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        borderRadius={'100%'}
        sx={{ backgroundColor: 'background.light' }}
      >
        <LockIcon />
      </Box>

      <Typography variant={'h2'} textAlign={'center'}>
        {title}
      </Typography>
    </Box>
  );
};

export default NotAllowedPageContent;
