import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  IAccessAndBlockedResponse,
  IBlockedFriendResponse,
  ICheckHasCommonFriendResponse,
  ICreateFriendshipByIntroductionRequest,
  IFriendResponse,
  ISuggestionsResponse,
} from '../../interfaces/friend.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';

const friendApi = createApi({
  reducerPath: 'friendApi',
  baseQuery: baseQueryWithInterceptor(
    `${config.BASE_FRIEND_MICROSERVICE_API_URL}/friend`
  ),
  tagTypes: ['friend'],

  endpoints: builder => ({
    getAllFriendships: builder.query<IFriendResponse[], void>({
      query: () => '',
      providesTags: ['friend'],
    }),

    getFriendsByUserId: builder.query<
      IFriendResponse,
      { page?: number; limit?: number; search?: string; sort?: string } | void
    >({
      query: (args = { page: 1, limit: 10, search: '', sort: 'desc' }) => {
        const { page = 1, limit = 10, search = '', sort = 'desc' } = args || {};
        return `/my-friends?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}&sort=${sort}`;
      },
      providesTags: ['friend'],
    }),
    getMutualFriends: builder.query<
      IFriendResponse,
      {
        representedFriendId: string;
        filters?: {
          page?: number;
          limit?: number;
          search?: string;
          sort?: string;
        };
      }
    >({
      query: ({
        representedFriendId,
        filters = { page: 1, limit: 10, search: '', sort: 'desc' },
      }) => {
        const { page = 1, limit = 10, search = '', sort = 'desc' } = filters;
        return `/common-friends-to-introduce/representedFriendId/${representedFriendId}/?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}&sort=${sort}`;
      },
      providesTags: ['friend'],
    }),
    checkHasCommonFriend: builder.query<ICheckHasCommonFriendResponse, string>({
      query: representedFriendId => {
        return `/check-has-common-friend/representedFriendId/${representedFriendId}`;
      },
      providesTags: ['friend'],
    }),
    createFriendshipByIntroduction: builder.mutation<
      any,
      ICreateFriendshipByIntroductionRequest
    >({
      query: data => ({
        url: 'create-friendship-by-introduction',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['friend'],
    }),

    deleteFriendship: builder.mutation<void, string>({
      query: friendId => ({
        url: `delete-my-friend/friendId/${friendId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['friend'],
    }),

    getFriendById: builder.query<IFriendResponse, void>({
      query: () => '/friend-profile',
      providesTags: ['friend'],
    }),

    deleteUserFromAllFriendships: builder.mutation<void, string>({
      query: userId => ({
        url: `user-friendships/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['friend'],
    }),

    checkAccessAndBlocked: builder.query<IAccessAndBlockedResponse, string>({
      query: userId => `access/${userId}`,
    }),

    blockMyFriend: builder.mutation<void, string>({
      query: userId => ({
        url: `block/${userId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['friend'],
    }),

    unblockMyFriend: builder.mutation<void, string>({
      query: userId => ({
        url: `unblock/${userId}`,
        method: 'PUT',
      }),
      invalidatesTags: ['friend'],
    }),

    getAllMyBlockedFriends: builder.query<
      IBlockedFriendResponse,
      { page?: number; limit?: number; search?: string; sort?: string } | void
    >({
      query: (args = { page: 1, limit: 10, search: '', sort: 'desc' }) => {
        const { page = 1, limit = 10, search = '', sort = 'desc' } = args || {};
        return `/blocked-friends?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}&sort=${sort}`;
      },
      providesTags: ['friend'],
    }),

    getAllMySuggestions: builder.query<
      ISuggestionsResponse,
      { page?: number; limit?: number; search?: string; sort?: string } | void
    >({
      query: (args = { page: 1, limit: 10, search: '', sort: 'desc' }) => {
        const { page = 1, limit = 10, search = '', sort = 'desc' } = args || {};
        return `/my-suggestions?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}&sort=${sort}`;
      },
      providesTags: ['friend'],
    }),
  }),
});

export const {
  useGetAllFriendshipsQuery,
  useGetFriendsByUserIdQuery,
  useCheckHasCommonFriendQuery,
  useGetMutualFriendsQuery,
  useCreateFriendshipByIntroductionMutation,
  useDeleteFriendshipMutation,
  useGetFriendByIdQuery,
  useDeleteUserFromAllFriendshipsMutation,
  useBlockMyFriendMutation,
  useUnblockMyFriendMutation,
  useGetAllMyBlockedFriendsQuery,
  useGetAllMySuggestionsQuery,
  useCheckAccessAndBlockedQuery,
} = friendApi;

export default friendApi;
