// export { default as ProfileCard } from '../../../../home/PersonalInfoPage/components/leftbar/ProfileCard';

// Note: if component will repeat all functions from /home page, use:
// src/pages/home/PersonalInfoPage/components/leftbar/ProfileCard.tsx(but before move it to the src/ccomponents)

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LocationIcon from '../../../../../components/Icons/LocationIcon';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';
import { useGetUserInformationQuery } from '../../../../../store/apiSlice/userApi';
import { colors } from '../../../../../theme/theme';

const ProfileCard = () => {
  const { userId, isOwnPage } = useCurrentPageUserId();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  const { data: myData, isLoading } = useGetUserInformationQuery(userId || '');

  const onEditProfileClick = useCallback(
    () => navigate('/profile'),
    [navigate]
  );

  return (
    <>
      <Box
        sx={{
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: { xs: '0 16px 16px', lg: 0 },
        }}
      >
        {isLoading ? (
          skeletonProfileCard
        ) : (
          <>
            <Box
              display="flex"
              flexDirection={mobileView ? 'row' : 'column'}
              alignItems="center"
              sx={
                mobileView
                  ? {
                      columnGap: '20px',
                      width: '100%',
                      position: 'absolute',
                      top: '-25px',
                      left: '0',
                      padding: '0 22px',
                    }
                  : {}
              }
            >
              <Avatar
                alt="Profile"
                src={myData?.avatar?.fileUrl || ''}
                sx={{
                  [theme.breakpoints.up('xs')]: { width: 100, height: 100 },
                  [theme.breakpoints.up('lg')]: { width: 140, height: 140 },
                  border: `2px solid ${theme.palette.background.white}`,
                }}
              />
              <Box
                className="profile-name-location-container"
                m={mobileView ? '35px 0 0' : '28px 0'}
              >
                <Typography
                  variant="h3"
                  color={colors.fontDark}
                  textAlign={{ xs: 'left', lg: 'center' }}
                >
                  {`${myData?.firstName || ''} ${myData?.lastName || ''}`}
                </Typography>
                <Typography
                  mt="6px"
                  variant="caption"
                  color="#727F93"
                  display="flex"
                  justifyContent="center"
                >
                  {myData?.country || myData?.city ? (
                    <LocationIcon
                      width={'16px'}
                      height={'16px'}
                      viewBox="0 0 24 24"
                      sx={{
                        width: '16px',
                        height: '16px',
                        viewBox: '0 0 16px 16px',
                        fill: 'none',
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {myData?.country ? `${myData?.country},` : ''}{' '}
                  {myData?.city ? myData?.city : ''}
                </Typography>
              </Box>
            </Box>

            <Box className="profile-actions" width="100%">
              {isOwnPage && (
                <>
                  <Button
                    onClick={onEditProfileClick}
                    variant="outlined"
                    fullWidth
                    sx={{
                      height: '50px',
                      color: 'text.primary',
                      fontSize: '12px',
                      borderRadius: '4px',
                      borderColor: '#434E5F',
                    }}
                  >
                    Edit Profile
                  </Button>
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default ProfileCard;

const skeletonProfileCard = (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      p: { xs: '22px 16px', lg: '0' },
    }}
  >
    <Skeleton
      variant="circular"
      sx={{
        width: '60%',
        height: 'auto',
        aspectRatio: '1',
      }}
    />
    <Skeleton
      variant="rectangular"
      sx={{
        width: '70%',
        height: '1.4rem',
      }}
    />

    <Skeleton
      variant="rectangular"
      sx={{
        width: '60%',
        height: '0.5rem',
      }}
    />
    <Skeleton
      variant="rectangular"
      sx={{
        width: '100%',
        height: '3rem',
      }}
    />
  </Box>
);
