import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CustomButton from '../../MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import ReportFileIcon from '../../Icons/ReportFileIcon';
import {
  useGetMyReportsQuery,
  useLazyDownloadReportQuery,
} from '../../../store/apiSlice/analitics/companyReportApi';
import ListSort from '../../ListToolbar/ListSort';
import { useSearchParams } from 'react-router-dom';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { Add } from '@mui/icons-material';

const sortMenuItems = [
  { label: 'Newest First', value: 'desc' },
  { label: 'Oldest First', value: 'asc' },
];

const filterMenuItems = [
  { label: 'This year', value: 'thisYear' },
  { label: 'Prev year', value: 'prevYear' },
  { label: 'Prev 5 years', value: 'prev5Years' },
  { label: 'This month', value: 'thisMonth' },
  { label: 'Prev month', value: 'prevMonth' },
];

const LIMIT = 10;

interface IReportsProps {
  openReportModal: () => void;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  });
};

const Reports = (props: IReportsProps) => {
  const { openReportModal } = props;
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [searchParams, setSearchParams] = useSearchParams();
  const [reports, setReports] = useState<any[]>([]);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [totalReports, setTotalReports] = useState<number>(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const [triggerDownloadReport] = useLazyDownloadReportQuery();
  const page = parseInt(searchParams.get('page') || '1', 10);
  const sort = searchParams.get('sortBy') || 'desc';
  const filter = searchParams.get('filterBy') || 'thisYear';

  const { data, isLoading, error, refetch } = useGetMyReportsQuery({
    page: page.toString(),
    limit: LIMIT.toString(),
    sort,
    filter,
  });

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (data && data.data) {
      if (page === 1) setReports(data.data);
      else setReports(prev => [...prev, ...data.data]);

      setTotalReports(data.total);

      const hasNext = LIMIT * page < data.total;
      setIsLoadMore(hasNext);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [sort, filter, page, refetch]);

  useEffect(() => {
    setSearchParams(prev => {
      prev.set('page', '1');
      prev.set('sortBy', sort);
      prev.set('filterBy', filter);
      return prev;
    });
  }, [sort, filter]);

  const handleLoadMore = () => {
    setSearchParams(prev => {
      prev.set('page', (page + 1).toString());
      return prev;
    });
  };

  const handleRowClick = async (reportId: string): Promise<void> => {
    try {
      const { data: downloadLink } =
        await triggerDownloadReport(reportId).unwrap();

      const link = document.createElement('a');
      link.href = downloadLink;
      link.download = `${reportId}.pdf`;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setSnackbarMessage('Report download successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Failed to download report. Please try again.');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <Box width="100%" height="fit-content" bgcolor="#fff">
      <Box
        borderBottom="1px solid #E8EBEF"
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding={isSmallScreen ? ' 16px' : '20.5px 36px'}
      >
        <Typography
          sx={{
            fontSize: '20px',
          }}
          variant={'h3'}
        >
          Reports
        </Typography>
        {mobileView ? (
          <IconButton
            disabled={isLoading}
            sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'background.lightDark',
              bgcolor: 'primary.main',
              height: '40px',
              width: '40px',
              minHeight: '40px',
              minWidth: '40px',
              color: 'white',
            }}
            type="submit"
            onClick={() => openReportModal()}
          >
            <Add />
          </IconButton>
        ) : (
          <CustomButton
            variantType={ButtonTypeEnum.PRIMARY}
            type="submit"
            buttonWidth={'220px'}
            onClick={() => openReportModal()}
          >
            Generate Report
          </CustomButton>
        )}
      </Box>

      <Box display={'flex'} flexDirection={'column'}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={isSmallScreen ? 'space-between' : ''}
          gap={isSmallScreen ? '0' : '28px'}
          padding={isSmallScreen ? ' 16px' : '20.5px 36px'}
          borderBottom={isMediumScreen ? '1px solid #E8EBEF' : 'none'}
        >
          <ListSort
            sortMenuItems={filterMenuItems}
            sortMenuLabel={'Period:'}
            queryParam={'filterBy'}
            showAtMobile={true}
            listSortStyle={{
              textDecoration: 'none',
              textUnderlinePosition: 'initial',
            }}
          />
          <ListSort
            sortMenuItems={sortMenuItems}
            sortMenuLabel={'Sort by:'}
            queryParam={'sortBy'}
            showAtMobile={true}
            listSortStyle={{
              textDecoration: 'none',
              textUnderlinePosition: 'initial',
            }}
          />
        </Box>

        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
            padding={isSmallScreen ? ' 16px' : '20.5px 36px'}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography
            color="error"
            padding={isSmallScreen ? ' 16px' : '20.5px 36px'}
          >
            Failed to load reports. Please try again.
          </Typography>
        ) : (
          <>
            {isMediumScreen ? (
              <Box
                display={'flex'}
                flexDirection={'column'}
                padding={isMediumScreen ? '0' : '20.5px 36px'}
              >
                {reports.map((report, index) => (
                  <Box
                    key={report.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '16px',
                      cursor: 'pointer',
                    }}
                    padding={
                      isSmallScreen
                        ? '22px 16px'
                        : isMediumScreen
                          ? '22px 36px'
                          : '0'
                    }
                    borderBottom={'1px solid #E8EBEF'}
                    onClick={() => handleRowClick(report.id)}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '12px',
                        alignItems: 'center',
                        backgroundColor: 'background.light',
                      }}
                      padding={isMediumScreen ? ' 6px' : '16px'}
                    >
                      <ReportFileIcon />
                      <Typography
                        variant={'h4'}
                        sx={{ fontWeight: 400, color: '#434E5F' }}
                      >
                        {truncateText(report.reportName, 20)}
                      </Typography>
                    </Box>

                    <Box display={'flex'} flexDirection={'column'} gap={'12px'}>
                      <Box display={'flex'} flexDirection={'row'} gap={'16px'}>
                        <Typography
                          variant={'caption'}
                          sx={{ color: 'text.primary' }}
                          fontWeight={'bold'}
                          minWidth={'60px'}
                        >
                          Type
                        </Typography>
                        <Typography
                          variant={'h4'}
                          sx={{ fontWeight: 400, color: '#434E5F' }}
                        >
                          {truncateText(report.type, 45)}
                        </Typography>
                      </Box>
                      <Box display={'flex'} flexDirection={'row'} gap={'16px'}>
                        {' '}
                        <Typography
                          variant={'caption'}
                          sx={{ color: 'text.primary' }}
                          fontWeight={'bold'}
                          minWidth={'60px'}
                        >
                          Period
                        </Typography>
                        <Typography
                          variant={'h4'}
                          sx={{ fontWeight: 400, color: '#434E5F' }}
                        >
                          {formatDate(report.periodStartDate)} —{' '}
                          {formatDate(report.periodEndDate)}
                        </Typography>
                      </Box>
                      <Box display={'flex'} flexDirection={'row'} gap={'16px'}>
                        {' '}
                        <Typography
                          variant={'caption'}
                          sx={{ color: 'text.primary' }}
                          fontWeight={'bold'}
                          minWidth={'60px'}
                        >
                          Created
                        </Typography>
                        <Typography
                          variant={'h4'}
                          sx={{ fontWeight: 400, color: '#434E5F' }}
                        >
                          {formatDate(report.createdAt)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={'16px'}
                padding={isSmallScreen ? '0 16px' : '0 28px'}
              >
                <TableContainer
                  component={Paper}
                  sx={{
                    mb: '28px',
                    border: 'none',
                    boxShadow: 'none',
                  }}
                >
                  <Table
                    sx={{
                      borderCollapse: 'collapse',
                      border: 'none',
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            padding: '22px 6px',
                          }}
                        >
                          <Typography
                            variant={'caption'}
                            sx={{
                              color: 'text.secondary',
                            }}
                          >
                            Report Name
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 'bold',
                            padding: '22px 6px',
                          }}
                        >
                          <Typography
                            variant={'caption'}
                            sx={{ color: 'text.secondary' }}
                          >
                            Type
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', padding: '22px 6px' }}
                        >
                          <Typography
                            variant={'caption'}
                            sx={{ color: 'text.secondary' }}
                          >
                            Period
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{ fontWeight: 'bold', padding: '22px 6px' }}
                        >
                          <Typography
                            variant={'caption'}
                            sx={{ color: 'text.secondary' }}
                          >
                            Created
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reports.map((report, index) => (
                        <TableRow
                          key={report.id}
                          sx={{
                            backgroundColor:
                              index % 2 === 1
                                ? 'background.light'
                                : 'transparent',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease',
                            '&:hover': {
                              backgroundColor: 'background.lightDark',
                            },
                            '& td': {
                              border: 'none',
                            },
                          }}
                          onClick={() => handleRowClick(report.id)}
                        >
                          <TableCell
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '6px',
                              alignItems: 'center',
                              border: 'none',
                              padding: '22px 6px',
                            }}
                          >
                            <ReportFileIcon />
                            <Typography
                              variant={'h4'}
                              sx={{ fontWeight: 400, color: '#434E5F' }}
                            >
                              {truncateText(report.reportName, 20)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ border: 'none', padding: '22px 6px' }}
                          >
                            <Typography
                              variant={'h4'}
                              sx={{ fontWeight: 400, color: '#434E5F' }}
                            >
                              {truncateText(report.type, 45)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ border: 'none', padding: '22px 6px' }}
                          >
                            <Typography
                              variant={'h4'}
                              sx={{ fontWeight: 400, color: '#434E5F' }}
                            >
                              {formatDate(report.periodStartDate)} —{' '}
                              {formatDate(report.periodEndDate)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ border: 'none', padding: '22px 6px' }}
                          >
                            <Typography
                              variant={'h4'}
                              sx={{ fontWeight: 400, color: '#434E5F' }}
                            >
                              {formatDate(report.createdAt)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}

            {isLoadMore && (
              <Box
                sx={{
                  width: '100%',
                  color: 'text.primary',
                  padding: isSmallScreen ? ' 16px' : '20.5px 28px',
                }}
              >
                <CustomButton
                  fullWidth
                  sx={{
                    width: '100%',
                    color: 'text.primary',
                  }}
                  variant="outlined"
                  variantType={ButtonTypeEnum.SECONDARY}
                  onClick={handleLoadMore}
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Load more'}
                </CustomButton>
              </Box>
            )}
          </>
        )}
      </Box>

      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default Reports;
