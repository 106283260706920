import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProfileIcon from '../../../../../components/Icons/ProfileIcon';
import SendIcon from '../../../../../components/Icons/SendIcon';
import { ChatStateEnum } from '../../../../../enums/chat-enum';
import {
  useCreateChatMutation,
  useGetAllMyChatsQuery,
} from '../../../../../store/apiSlice/chat/chatApi';
import {
  setChatState,
  setSelectedChat,
} from '../../../../../store/apiSlice/chat/chatSlice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserInfo } from '../../../LiveAgentInProgressPage/components/user-list/UserListItem';
import Loader from '../../../../../components/MUIComponents/Loader';

interface BaseActionsProps {
  userInfo: UserInfo;
}

const BaseActions = ({ userInfo }: BaseActionsProps) => {
  const navigate = useNavigate();
  const [isSendMsgloading, setIsSendMsgLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { data: myChats } = useGetAllMyChatsQuery();
  const [createChat, { isLoading: isCreatingChat }] = useCreateChatMutation();
  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action: () => void) => {
    handleClose();
    action();
  };

  const handleViewProfile = () => {
    navigate(`/${userInfo.id}`);
  };

  const handleMessage = useCallback(
    (toUserId: string | undefined) => {
      if (!toUserId) {
        return () => {};
      }

      return () => {
        setIsSendMsgLoading(true);
        dispatch(setChatState(ChatStateEnum.CHAT));

        if (myChats)
          for (const chat of myChats)
            if (chat.withUserId === toUserId) {
              dispatch(setSelectedChat(chat));
              setIsSendMsgLoading(false);
              return navigate('/chat');
            }

        createChat(toUserId)
          .unwrap()
          .then(chat => {
            dispatch(setSelectedChat(chat));
            setIsSendMsgLoading(false);
            navigate('/chat');
          })
          .catch(console.log);
      };
    },
    [myChats, createChat, dispatch, navigate]
  );

  return (
    <>
      <IconButton
        aria-label="More"
        id="show-more-button"
        aria-controls={anchorEl ? 'show-more' : undefined}
        aria-haspopup="true"
        aria-expanded={!!anchorEl || undefined}
        sx={{
          p: 0,
          width: 'auto',
          height: 'auto',
          minWidth: 'auto',
          minHeight: 'auto',
          '&:hover': { background: 'transparent' },
        }}
        onClick={handleClick}
      >
        <MoreVertIcon sx={{ color: 'text.secondary' }} />
      </IconButton>
      <Menu
        id="show-more"
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        sx={{ pt: 0, pb: 0 }}
        MenuListProps={{
          'aria-labelledby': 'show-more-button',
          disablePadding: true,
        }}
      >
        <MenuItem
          onClick={() => handleMenuItemClick(handleMessage(userInfo.id))}
          disabled={isCreatingChat}
          sx={{
            display: 'flex',
            gap: '6px',
            fontSize: '14px',
            p: '15px',
          }}
        >
          <Box
            position="relative"
            width={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              fontSize: '14px',
            }}
          >
            <SendIcon stroke="#142237" /> Send message
            {isSendMsgloading && <MiniLoader />}
          </Box>
        </MenuItem>
        <Divider sx={{ margin: 0 }} />
        <MenuItem
          onClick={() => handleMenuItemClick(() => handleViewProfile())}
          sx={{
            display: 'flex',
            gap: '6px',
            fontSize: '14px',
            p: '15px',
          }}
        >
          <ProfileIcon /> View Profile
        </MenuItem>
      </Menu>
    </>
  );
};

export default BaseActions;

const MiniLoader = () => (
  <Box
    position="absolute"
    display="flex"
    justifyContent="center"
    alignItems="center"
    width={1}
  >
    <CircularProgress size={20} />
  </Box>
);
