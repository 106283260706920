import { Box, Card, CardHeader, Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useGetMyFriendsPostsQuery } from '../../../../store/apiSlice/post/postApi';
import NewPublicationTrigger from '../../shared-components/posts/NewPublicationTrigger';
import PostsToolbar from '../../shared-components/posts/PostsToolbar';
import ProfilePost from '../../shared-components/posts/ProfilePost';
import { SORT_OPTIONS } from '../../../../enums/sort-options-enum';
import { Filter } from '../../shared-components/tabs/PostsTab';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

const LIMIT = 103;

const MyFriendsPosts = () => {
  const { theme } = useThemeContext();
  const [sort, setSort] = useState<SORT_OPTIONS>(SORT_OPTIONS.DESC);
  const [filter, setFilter] = useState<Filter | null>(null);

  const queryParams = {
    tags: '',
    page: 1,
    limit: LIMIT,
    search: '',
    sort,
    ...filter,
  };

  const {
    data: postsData,
    // eslint-disable-next-line
    isLoading,
    // eslint-disable-next-line
    refetch: refetchPosts,
  } = useGetMyFriendsPostsQuery(queryParams);

  const [postsCount, setPostsCount] = useState<number | null>(null);

  useEffect(() => {
    if (postsData) setPostsCount(postsData.total);
  }, [postsData]);

  return (
    <Grid container direction="row">
      <Grid
        item
        flex={2}
        minWidth={0}
        rowGap={{ xs: '16px', xl: '22px' }}
        display="flex"
        flexDirection="column"
      >
        <NewPublicationTrigger refetchPosts={refetchPosts} />

        {isLoading ? (
          skeletonPostList
        ) : (
          <>
            <PostsToolbar
              sort={sort}
              setSort={setSort}
              postsCount={postsCount}
                setFilter={({ from, to }) => setFilter({ from, to })}
            />
            {postsData?.data &&
              postsData?.data.length > 0 &&
              postsData?.data.map(post => (
                <Box
                  key={post.id}
                  className="profile-posts"
                  display="flex"
                  alignItems="center"
                  sx={{
                    backgroundColor: theme.palette.background.white,
                  }}
                >
                  <ProfilePost post={post} refetchPosts={refetchPosts} />
                </Box>
              ))}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default MyFriendsPosts;

const skeletonPost = (
  <Card
    sx={{
      width: '100%',
      padding: '0.5rem 1.3rem',
      backgroundColor: '#fff',
    }}
  >
    <CardHeader
      avatar={
        <Skeleton
          animation="wave"
          variant="circular"
          sx={{
            width: '2.5rem',
            height: '2.5rem',
          }}
        />
      }
      action={null}
      title={
        <Skeleton
          animation="wave"
          sx={{
            width: '80%',
            height: '1.125rem',
            marginBottom: '0.375rem',
          }}
        />
      }
      subheader={
        <Skeleton
          animation="wave"
          sx={{
            width: '40%',
            height: '0.75rem',
          }}
        />
      }
      sx={{ padding: '1rem 0' }}
    />
    <Skeleton
      animation="wave"
      variant="rectangular"
      sx={{ height: '21.875rem' }}
    />
    <Skeleton
      animation="wave"
      sx={{
        height: '2.5rem',
        margin: '0.625rem 0',
      }}
    />
  </Card>
);

const skeletonPostList = (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      padding: {
        xs: '0 0.5rem',
        lg: '0',
      },
    }}
  >
    <Box
      sx={{
        width: '100%',
        padding: '0.5rem 1.3rem',
        backgroundColor: '#fff',
      }}
    >
      <Skeleton
        sx={{
          width: '100%',
          minHeight: '2.5rem',
        }}
      />
    </Box>

    {[...Array(2)].map((_, index) =>
      React.cloneElement(skeletonPost, { key: index })
    )}
  </Box>
);
