import { Box, useMediaQuery } from '@mui/material';
import ProfileSidebarMenu from './PrifileSidebarMenu';
import ProfileSidebarProgress from './ProfileSidebarProgress';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

const HEADER_HEIGHT = 71;

const ProfileSidebar = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Box position="relative">
      <Box
        display="flex"
        flexDirection="column"
        gap="22px"
        sx={{
          position: 'sticky',
          [theme.breakpoints.up('lg')]: {
            top: HEADER_HEIGHT + 28,
            maxHeight: window.innerHeight - HEADER_HEIGHT - 28,
          },
          [theme.breakpoints.up('xl')]: {
            top: HEADER_HEIGHT + 48,
            maxHeight: window.innerHeight - HEADER_HEIGHT - 48,
          },
          overflowY: 'auto',
        }}
      >
        {!mobileView && <ProfileSidebarMenu />}
        <ProfileSidebarProgress />
      </Box>
    </Box>
  );
};

export default ProfileSidebar;
