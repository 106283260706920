import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import BaseModal from '../../../../../components/MUIComponents/BaseModal';
import Rating from '../../../../../components/MUIComponents/Rating';
import SnackbarCustom from '../../../../../components/MUIComponents/SnackbarCustom';
import TextField from '../../../../../components/MUIComponents/TextField';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { useCreateReviewMutation } from '../../../../../store/apiSlice/reviewApi';
import { errorHelper } from '../../../../../utils/helper/error-helper';
import { leaveReviewSchema } from '../../../../../validation/review-validation';

interface LeaveReviewInputs {
  rating: number;
  text: string;
}

interface LeaveReviewModalProps {
  agentId: string;
  isOpen?: boolean;
  onClose: () => void;
  refetchReviews: () => void;
  refetchMyAgents: () => void;
}

const LeaveReviewModal = ({
  agentId,
  onClose,
  refetchReviews,
  refetchMyAgents,
  isOpen = false,
}: LeaveReviewModalProps) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const [createReview, { isLoading }] = useCreateReviewMutation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LeaveReviewInputs>({
    resolver: yupResolver(leaveReviewSchema),
    defaultValues: {
      rating: 0,
      text: '',
    },
    mode: 'onSubmit',
  });

  const onSubmit = async (data: LeaveReviewInputs) => {
    try {
      await createReview({
        agentId,
        review: data.text,
        rating: data.rating,
      });
      setSnackbarMessage('Review successfully sent');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
      refetchReviews();
      refetchMyAgents();
      setTimeout(() => {
        onClose();
        setSnackbarOpen(false);
      }, 1000);
    } catch (error) {
      const errorMessage = errorHelper(error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <BaseModal
      header={{ text: 'Leave Review' }}
      isOpen={isOpen}
      toggle={onClose}
      footer={{
        okText: 'submit review',
        declineText: 'cancel',
        styles: { pt: '20px' },
        isOkButtonDisabled: isLoading,
      }}
      onAccept={handleSubmit(onSubmit)}
    >
      <Grid
        container
        rowGap="28px"
        className="career-developement-page__leave_review"
      >
        <Grid item container flexDirection="column" rowGap="12px">
          <Typography variant="caption">Ranking:</Typography>
          <Rating
            error={!!errors.rating}
            helperText={errors.rating ? errors.rating.message : ''}
            onChange={(_, value) => {
              setValue('rating', value ?? 0);
            }} // Manually setting the value in form state
            sx={{ fontSize: 40 }}
          />
        </Grid>
        <Grid item container flexDirection="column" rowGap="12px">
          <Typography variant="caption">Review:</Typography>
          <TextField
            placeholder="Write review here"
            height={160}
            register={register('text')}
            error={!!errors.text}
            helperText={errors.text ? errors.text.message : ''}
          />
        </Grid>
      </Grid>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </BaseModal>
  );
};

export default LeaveReviewModal;
