import { SvgIcon, SvgIconProps } from '@mui/material';

const LockIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 41"
      sx={{ width: 41, height: 41, fill: 'none' }}
      {...props}
    >
      <g id="Component 7" stroke="#727F93" strokeLinecap="round">
        <path
          id="Vector"
          strokeWidth="2"
          d="M32.165 18.833H8.832V35.5h23.333z"
        ></path>
        <path
          id="Vector_2"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M18.832 27.167a1.667 1.667 0 1 0 3.333 0 1.667 1.667 0 0 0-3.333 0"
        ></path>
        <path
          id="Vector_3"
          strokeLinejoin="round"
          strokeWidth="1.5"
          d="M13.832 18.833v-6.666a6.667 6.667 0 0 1 13.333 0v6.666"
        ></path>
      </g>
    </SvgIcon>
  );
};

export default LockIcon;
