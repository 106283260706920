import SidebarLayout from '../../layouts/SidebarLayout';
import { Box, useMediaQuery } from '@mui/material';
import SectionLayout from '../../layouts/SectionLayout';
import JobPosts from './components/JobPosts';
import { useGetAllJobpostsQuery } from '../../store/apiSlice/jobpost/jobpostApi';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useThemeContext } from '../../theme/ThemeContextProvider';
import { IJobpost } from '../../interfaces/jobpost.interface';
import JobPostsSidebar from './components/JobPostsSidebar/JobPostsSidebar';

const sortMenuItems = [
  { label: 'Recently Added', value: 'desc' },
  { label: 'Oldest', value: 'asc' },
];

const LIMIT = 10;

const JobPostsPage = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [jobPostsArray, setJobPostsArray] = useState<{ jobpost: IJobpost }[]>(
    []
  );
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [totalPosts, setTotalPosts] = useState<number>(0);

  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get('query') ?? '';
  const page = parseInt(searchParams.get('page') || '1', 10);
  const sort = searchParams.get('sortBy') ?? 'desc';
  const industry = searchParams.get('industry') ?? '';
  const position = searchParams.get('position') ?? '';
  const location = searchParams.get('location') ?? '';

  const queryParams = {
    page,
    search: query,
    limit: LIMIT,
    sort,
    industries: industry,
    position,
    location,
  };

  const {
    data: jobPostsData,
    error,
    isLoading,
  } = useGetAllJobpostsQuery(queryParams, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (jobPostsData && jobPostsData?.data) {
      if (page === 1) setJobPostsArray(jobPostsData.data);
      else setJobPostsArray(prev => [...prev, ...jobPostsData.data]);

      setTotalPosts(jobPostsData.total);

      const hasNext =
        jobPostsData.limit * (jobPostsData.page - 1) + jobPostsData.limit <
        jobPostsData.total;

      setIsLoadMore(hasNext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobPostsData]);

  useEffect(() => {
    setSearchParams(prev => {
      prev.set('page', '1');
      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, sort]);

  return (
    <SidebarLayout
      sidebar={<JobPostsSidebar />}
      flexDirection={matches ? 'row' : 'column'}
    >
      <Box display="flex" flexDirection="column" flexGrow="1">
        <SectionLayout
          height={'100%'}
          sx={{
            bgcolor: 'background.white',
            p: { xs: '36px 16px', md: '24px', lg: '16px 36px 48px 36px' },
          }}
        >
          <JobPosts
            listToolBarSubtitle={
              totalPosts === 0
                ? 'No Job Posts Found'
                : `${totalPosts} job post${totalPosts === 1 ? '' : 's'}`
            }
            sortMenuItems={sortMenuItems}
            jobPosts={jobPostsArray}
            jobPostTitleClickPath="job-post-apply"
            isLoadMore={isLoadMore}
            isLoading={isLoading}
          />
        </SectionLayout>
      </Box>
    </SidebarLayout>
  );
};

export default JobPostsPage;
