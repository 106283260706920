import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import { config } from '../../config/config';
import { CookieEnum } from '../../enums/cookie-enum';
import {
  CreateIndividualStageReq,
  CreateIndividualStageRes,
  GetIndividualStageForAgentByUserIdReq,
  GetIndividualStageForUserByAgentIdReq,
  GetIndividualStageStatusesByUserIdRes,
  IndividualStage,
  UpdateIndividualStageReq,
  UpdateIndividualStageRes,
} from '../../interfaces/individual-stage.interface';
import { IPaginationResponse } from '../../interfaces/common.interface';

const baseQuery = fetchBaseQuery({
  baseUrl: `${config.BASE_USER_MICROSERVICE_API_URL}/individual-stage`,
  prepareHeaders: headers => {
    const token = Cookies.get(CookieEnum.ACCESS_TOKEN);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithInterceptor = async (
  args: any,
  api: any,
  extraOptions: any
) => {
  const response = await baseQuery(args, api, extraOptions);

  if ((response as any)?.error?.status === 401) {
    Cookies.remove(CookieEnum.ACCESS_TOKEN);
    window.location.reload();
  }

  return response;
};

const individualStageApi = createApi({
  reducerPath: 'individualStageApi',
  baseQuery: baseQueryWithInterceptor,
  tagTypes: ['individualStage'],

  endpoints: builder => ({
    getIndividualStageForUserByAgentId: builder.query<
      IPaginationResponse<IndividualStage>,
      GetIndividualStageForUserByAgentIdReq
    >({
      query: args => {
        const {
          id,
          page = 1,
          limit = 10,
          search = '',
        }: GetIndividualStageForUserByAgentIdReq = args || {};

        return `/user/individual-stage-propositions/agent/${id}?page=${page}&limit=${limit}&search=${search}`;
      },
      providesTags: ['individualStage'],
    }),
    getIndividualStageForAgentByUserId: builder.query<
      IPaginationResponse<IndividualStage>,
      GetIndividualStageForAgentByUserIdReq
    >({
      query: args => {
        const {
          id,
          page = 1,
          limit = 10,
          search = '',
        }: GetIndividualStageForAgentByUserIdReq = args || {};

        return `/agent/individual-stage-propositions/user/${id}?page=${page}&limit=${limit}&search=${search}`;
      },
      providesTags: ['individualStage'],
    }),
    getIndividualStageStatusesByUserId: builder.query<
      GetIndividualStageStatusesByUserIdRes,
      string
    >({
      query: userid => `/agent/individual-stage-statuses/user/${userid}`,
      providesTags: ['individualStage'],
    }),
    createIndividualStage: builder.mutation<
      CreateIndividualStageRes,
      CreateIndividualStageReq
    >({
      query: data => ({
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['individualStage'],
    }),
    updateIndividualStage: builder.mutation<
      UpdateIndividualStageRes,
      UpdateIndividualStageReq
    >({
      query: ({ id, ...restData }) => ({
        url: '/' + id,
        method: 'PUT',
        body: restData,
      }),
      invalidatesTags: ['individualStage'],
    }),
  }),
});

export const {
  useGetIndividualStageForUserByAgentIdQuery,
  useGetIndividualStageForAgentByUserIdQuery,
  useGetIndividualStageStatusesByUserIdQuery,
  useCreateIndividualStageMutation,
  useUpdateIndividualStageMutation,
} = individualStageApi;

export default individualStageApi;
