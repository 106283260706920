import React from 'react';
import { Box, Slider, TextField, Typography } from '@mui/material';
import FilterLayout from '../../../../../layouts/FilterLayout';

interface ICandidateExperienceFilterProps {
  from: number;
  to: number;
  setFrom: (value: number) => void;
  setTo: (value: number) => void;
}

const CandidateExperienceFilter = ({
  from,
  to,
  setFrom,
  setTo,
}: ICandidateExperienceFilterProps) => {
  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value, 10);
    if (value < 0) value = 0;
    setFrom(value);
  };

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value, 10);
    if (value < 0) value = 0;
    if (value > 50) value = 50;
    setTo(value);
  };

  const handleRangeChange = (event: Event, newValue: number | number[]) => {
    const [newFrom, newTo] = newValue as number[];
    setFrom(newFrom);
    setTo(newTo);
  };

  return (
    <FilterLayout name={'Experience'}>
      <Box
        display="flex"
        flexDirection={'column'}
        gap={'22px'}
        sx={{ padding: '0 1rem 1.375rem' }}
      >
        <Slider
          value={[from, to]}
          onChange={handleRangeChange}
          min={0}
          max={50}
          valueLabelDisplay="auto"
          sx={{ mb: 2 }}
        />
        <Box display="flex" flexDirection="column" gap={'12px'}>
          <Typography
            variant="caption"
            sx={{
              padding: '0 2px',
              color: 'text.primary',
            }}
          >
            Time range:
          </Typography>
          <Box display="flex" flexDirection="row" gap={'16px'}>
            <TextField
              placeholder={'From'}
              type="number"
              value={from || ''}
              onChange={handleFromChange}
              variant="outlined"
              fullWidth
              inputProps={{ min: 0 }}
            />
            <TextField
              placeholder={'To'}
              type="number"
              value={to || ''}
              onChange={handleToChange}
              variant="outlined"
              fullWidth
              inputProps={{ min: 0, max: 50 }}
            />
          </Box>{' '}
        </Box>
      </Box>
    </FilterLayout>
  );
};

export default CandidateExperienceFilter;
