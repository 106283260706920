import React, { useEffect, useState } from 'react';
import { Box, FormControl, TextField, Typography } from '@mui/material';
import ArrowDownIcon from '../../../../../components/Icons/ArrowDownIcon';
import Autocomplete from '@mui/material/Autocomplete';
import {
  getCitiesOfCountry,
  getCountries,
} from '../../../../../utils/coutry-city-utils';
import { ICountry } from 'country-state-city';
import FilterLayout from '../../../../../layouts/FilterLayout';

interface ICandidateLocationCollapseProps {
  setCountryFilterState: (value: string) => void;
  setCityFilterState: (value: string) => void;
  countryFilterState: string;
  cityFilterState: string;
}

const CandidateLocationFilter = (props: ICandidateLocationCollapseProps) => {
  const {
    setCountryFilterState,
    setCityFilterState,
    countryFilterState,
    cityFilterState,
  } = props;

  const [selectedCountryCode, setSelectedCountryCode] = useState<
    string | undefined
  >();

  const findCountryByName = (
    name: string | null | undefined
  ): ICountry | null => {
    const countries = getCountries();
    return countries.find(country => country.name === name) || null;
  };

  useEffect(() => {
    const country = findCountryByName(countryFilterState);
    setSelectedCountryCode(country?.isoCode || undefined);
  }, [countryFilterState]);

  return (
    <FilterLayout name={'Location'}>
      <Box
        display="flex"
        flexDirection={'column'}
        gap={'22px'}
        sx={{ padding: '0 1rem 1.375rem' }}
      >
        <FormControl
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
          variant="outlined"
          fullWidth
        >
          <Typography
            variant="caption"
            sx={{
              padding: '0 2px',
              mb: '12px',
              color: 'text.primary',
            }}
          >
            Countries:
          </Typography>
          <Autocomplete
            options={getCountries()}
            getOptionLabel={option => option.name || ''}
            isOptionEqualToValue={(option, value) =>
              option.name === value?.name
            }
            value={findCountryByName(countryFilterState) || null}
            onChange={(event, newValue) => {
              setSelectedCountryCode(newValue?.isoCode);
              setCountryFilterState(newValue ? newValue.name : '');
              setCityFilterState(''); // Очищаємо поле міста при зміні країни
            }}
            renderInput={params => (
              <TextField {...params} placeholder="Select a country" />
            )}
            sx={{
              '.MuiInputBase-root': {
                height: '50px',
              },
              '.MuiSvgIcon-root': {
                bgcolor: 'transparent !important',
              },
            }}
            popupIcon={<ArrowDownIcon />}
          />
        </FormControl>

        <FormControl
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
          variant="outlined"
          fullWidth
        >
          <Typography
            variant="caption"
            sx={{
              padding: '0 2px',
              mb: '12px',
              color: selectedCountryCode ? 'text.primary' : 'text.disabled',
            }}
          >
            City:
          </Typography>
          <Autocomplete
            options={
              selectedCountryCode
                ? getCitiesOfCountry(selectedCountryCode) || []
                : []
            }
            getOptionLabel={option => option?.name || ''}
            isOptionEqualToValue={(option, value) =>
              option.name === value?.name
            }
            value={cityFilterState ? { name: cityFilterState, id: '' } : null}
            onChange={(event, newValue) => {
              setCityFilterState(newValue ? newValue.name : '');
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            )}
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Select a city"
                disabled={!selectedCountryCode}
              />
            )}
            disabled={!selectedCountryCode}
            sx={{
              '.MuiInputBase-root': {
                height: '50px',
              },
              '.MuiSvgIcon-root': {
                bgcolor: 'transparent !important',
              },
            }}
            popupIcon={<ArrowDownIcon />}
          />
        </FormControl>
      </Box>
    </FilterLayout>
  );
};

export default CandidateLocationFilter;
