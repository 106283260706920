import React, { useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { ButtonTypeEnum } from '../../enums/button-type-enum';
import {
  CareerAdviceSupportEnum,
  CareerArrearsEnum,
  CurrentLevelEnum,
  PrimaryCareersGoalEnum,
  TypeOfSupportEnum,
} from '../../enums/questionnaires-enum';
import { SnackbarSeverityEnum } from '../../enums/snackbar-severity-enum';
import { errorHelper } from '../../utils/helper/error-helper';
import { useNavigate } from 'react-router-dom';
import SnackbarCustom from '../../components/MUIComponents/SnackbarCustom';
import CustomButton from '../../components/MUIComponents/CustomButton';
import StepHeader from '../../components/Auth/VerifyAccount/StepHeader';
import FreeAgentPersonalCareerForm from '../../components/FreeAgentPersonalCareer/FreeAgentPersonalCareerForm';
import { useCreateOrUpdateQuizMutation } from '../../store/apiSlice/quizApi';

type AnswerKeys =
  | 'primaryCareerGoal'
  | 'currentNetworkingLevel'
  | 'typeOfSupport'
  | 'improvementAreaRequire'
  | 'adviceSupport';

const CareerQuestionnairePage = () => {
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);
  const navigate = useNavigate();

  const [selectedAnswers, setSelectedAnswers] = useState<
    Record<AnswerKeys, any>
  >({
    primaryCareerGoal: null,
    currentNetworkingLevel: null,
    typeOfSupport: null,
    improvementAreaRequire: null,
    adviceSupport: null,
  });

  const [createOrUpdateQuiz, { isLoading }] = useCreateOrUpdateQuizMutation();

  const questions = [
    {
      key: 'primaryCareerGoal' as AnswerKeys,
      question:
        'What is your primary career goal in the next \n' + '2-3 years?',
      answers: [
        PrimaryCareersGoalEnum.SECURE_POSITION_IN_PROFESSIONAL_TEAM,
        PrimaryCareersGoalEnum.TRANSITION_TO_COACHING,
        PrimaryCareersGoalEnum.ENHANCE_SKILLS_TRAINING_EDUCATION,
        PrimaryCareersGoalEnum.EXPLORE_SPORTS_MANAGEMENT_ADMINISTRATION,
        PrimaryCareersGoalEnum.OTHER,
      ],
    },
    {
      key: 'currentNetworkingLevel' as AnswerKeys,
      question:
        'How would you rate your current level of networking within the sports industry?',
      answers: [
        CurrentLevelEnum.EXCELLENT,
        CurrentLevelEnum.GOOD,
        CurrentLevelEnum.POOR,
        CurrentLevelEnum.FAIR,
      ],
    },
    {
      key: 'typeOfSupport' as AnswerKeys,
      question:
        'What type of support do you feel you need\n' +
        'the most to advance your career?',
      answers: [
        TypeOfSupportEnum.MENTORSHIP,
        TypeOfSupportEnum.ACCESS_TO_EXCLUSIVE,
        TypeOfSupportEnum.PROFESSIONAL_TRAINER,
        TypeOfSupportEnum.ASSISTANCE_WITH,
      ],
    },
    {
      key: 'improvementAreaRequire' as AnswerKeys,
      question:
        'Which area do you believe requires the most improvement for your career development?',
      answers: [
        CareerArrearsEnum.TECHNICAL_SKILLS,
        CareerArrearsEnum.COMMUNICATION_SKILLS,
        CareerArrearsEnum.KNOWLEDGE_OF_SPORTS,
        CareerArrearsEnum.PERSONAL_BRANDING,
      ],
    },
    {
      key: 'adviceSupport' as AnswerKeys,
      question:
        'How do you prefer to receive career advice \n' + 'and support?',
      answers: [
        CareerAdviceSupportEnum.ONE_ON_ONE,
        CareerAdviceSupportEnum.COURSES,
        CareerAdviceSupportEnum.GROUP_WORKSHOPS,
        CareerAdviceSupportEnum.RESOURCES,
      ],
    },
  ];

  const handleNextButtonClick = async () => {
    const currentKey = questions[currentQuestion].key;

    if (!selectedAnswers[currentKey]) {
      setSnackbarMessage('Please select an option before proceeding.');
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
      return;
    }

    if (currentQuestion === questions.length - 1) {
      try {
        await createOrUpdateQuiz(selectedAnswers).unwrap();
        setSnackbarMessage('Career questionnaire saved successfully!');
        setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
        setSnackbarOpen(true);

        setSelectedAnswers({
          primaryCareerGoal: null,
          currentNetworkingLevel: null,
          typeOfSupport: null,
          improvementAreaRequire: null,
          adviceSupport: null,
        });
        setCurrentQuestion(0);
        navigate('/career-development', {
          replace: true,
          state: { isRedirectedFromCompletedQuiz: true },
        });
      } catch (error) {
        console.error('Error:', error);
        setSnackbarMessage(errorHelper(error));
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        setSnackbarOpen(true);
      }
      return;
    }

    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrevButtonClick = () => {
    if (currentQuestion === 0) {
      navigate('/', { replace: true });
      return;
    }

    setCurrentQuestion(currentQuestion - 1);
  };

  const handleAnswerChange = (
    answer:
      | PrimaryCareersGoalEnum
      | CurrentLevelEnum
      | TypeOfSupportEnum
      | CareerArrearsEnum
      | CareerAdviceSupportEnum
  ) => {
    const currentKey = questions[currentQuestion].key;
    setSelectedAnswers(prevSelected => ({
      ...prevSelected,
      [currentKey]: answer,
    }));
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Box
        display={'flex'}
        maxWidth={'875px'}
        width={'100%'}
        height={'756px'}
        margin={'0 auto'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        bgcolor={'background.white'}
        padding={'36px 36px 48px 36px'}
      >
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          gap={'2.25rem'}
        >
          <StepHeader
            step={`Find Career Live Agent`.toUpperCase()}
            title={questions[currentQuestion].question}
          />
        </Box>

        <FreeAgentPersonalCareerForm
          answers={questions[currentQuestion].answers}
          selectedAnswer={selectedAnswers[questions[currentQuestion].key]}
          onAnswerChange={handleAnswerChange}
        />

        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'column'}
          gap={'36px'}
        >
          <Divider
            sx={{ bgcolor: 'background.white', height: '1px', width: '100%' }}
          />
          <Box
            width={'100%'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gap={'16px'}
          >
            <CustomButton
              buttonWidth={'100%'}
              variantType={ButtonTypeEnum.SECONDARY}
              variant="outlined"
              fullWidth
              sx={{ p: '12px 16px', maxWidth: '220px' }}
              onClick={handlePrevButtonClick}
            >
              {currentQuestion === 0 ? 'Cancel' : 'Go Back'}
            </CustomButton>

            <Typography variant="body3">
              QUESTION 0{currentQuestion + 1} / 05{' '}
            </Typography>

            <CustomButton
              sx={{ p: '12px 16px', maxWidth: '220px' }}
              buttonWidth="100%"
              variant="contained"
              variantType={ButtonTypeEnum.PRIMARY}
              onClick={handleNextButtonClick}
              fullWidth
              disabled={isLoading}
            >
              {currentQuestion === questions.length - 1
                ? 'Submit'
                : 'Next Question'}
            </CustomButton>
          </Box>
        </Box>

        <SnackbarCustom
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Box>
    </>
  );
};

export default CareerQuestionnairePage;
